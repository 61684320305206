import URLMap from './url-map'
import timedFetch from './fetch-with-timeout'

type resultType = {
  data?: any;
  error?: any;
}

// export async function dashboard({queryKey}: {queryKey:string[]}) {
    
//     //let tempSupplierId="cc9cfc30-5c01-11ec-bf63-0242ac130002"
//     let SupplierId=queryKey[1]
//     let dashboardURL = URLMap('dashboardAPI')
//     dashboardURL=dashboardURL.replace("{supplierId}",SupplierId)
//     let result = await fetch(dashboardURL).then(res=>res.json())
//     return result
//   }

  export async function dashboard({queryKey}: {queryKey:string[]}) {
    try {
        //let tempSupplierId="cc9cfc30-5c01-11ec-bf63-0242ac130002"
        let SupplierId=queryKey[1];
        let dashboardURL = URLMap('dashboardAPI');
        dashboardURL=dashboardURL.replace("{supplierId}",SupplierId);
        let response = await fetch(dashboardURL);
        if (!response.ok) {
            throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }
        let result = await response.json();

        return result;
    } catch (error) {
        console.error('Error fetching dashboard data:', error.message);
        throw error; 
       }
}  

export async function searchRetailers4Supplier(opts:any) {
  const {supplierId, searchToken} = opts
  const pageSize = opts.pageSize || 10
  const pageIndex = opts.pageIndex || 1 
  //debugger

  let url = URLMap('supplierDealersSearch')
  url  = url.replace('{supplierId}', supplierId)
  url = `${url}?name=${searchToken}&itemsPerPage=${pageSize}&pageNum=${pageIndex}`
  let result = await fetch(url).then(res => res.json())
  return result
}

//export async function product({queryKey}: {queryKey:string[]}) {
export async function product(opts: any) {
  let SupplierId=opts.supplierId || undefined
  
  const pageIndex= opts.pageIndex || 1
  const pageSize = opts.pageSize || 25

  let productURL=URLMap('productsAPI')
  productURL=productURL.replace("{supplierId}",SupplierId)
  productURL=`${productURL}?itemsPerPage=${pageSize}&pageNum=${pageIndex}`
  let result= await fetch(productURL).then(res=>res.json())
  return result
}  

//export async function dealers({queryKey}: {queryKey:string[]}) {
export async function dealers(opts:any) {
  //debugger
  const SupplierId=opts.supplierId
  const pageIndex= opts.pageIndex || 1
  const pageSize = opts.pageSize || 25
  //let tempSupplierId="cc9cfc30-5c01-11ec-bf63-0242ac130002"
  let productURL=URLMap('dealersAPI')
  productURL=productURL.replace("{supplierId}",SupplierId)
  let result= await fetch(productURL).then(res=>res.json())
  return result
}  

export async function addDealer(payload:object,supplierId:string){
  
  let SupplierId=supplierId
  let addDealerURL=URLMap("addDealerAPI")
  addDealerURL=addDealerURL.replace("{supplierId}",SupplierId)
  let result : resultType = {}
  try {
    let response = await fetch(addDealerURL, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(payload)
    }).then(res => res.json())
    if (response?.error) throw response.error
    result.data = response
  } catch(e) {
      result.error = e
  }
  return result;
}


export async function updateRetailer(payload:object,dealerId:string){
 // debugger  
  let DealerId=dealerId
  let UpdateRetailer=URLMap("updateRetailerApi")
  UpdateRetailer=UpdateRetailer.replace("{storeId}",DealerId)
  let result : resultType = {}
  try {
    let response = await fetch(UpdateRetailer, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(payload)
    }).then(res => res.json())
    if (response?.error) throw response.error
    result.data = response
  } catch(e) {
      result.error = e
  }
  
  return result;
}


export async function deleteSupplierProduct(prodId:string,supplierId:string){
  let SupplierId=supplierId

  let deleteSupplierProductURL=URLMap('deleteSupplierProductAPI')
  deleteSupplierProductURL=deleteSupplierProductURL.replace("{supplierId}",SupplierId)
  deleteSupplierProductURL=deleteSupplierProductURL.replace("{productId}",prodId)
  let result= await fetch(deleteSupplierProductURL,{
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({})
  })
  return result
}

export async function getDealerDetails(dealerId:any) {
  
  let getDealerDetailsURL=URLMap('getDealerDetailsAPI')
  getDealerDetailsURL=getDealerDetailsURL.replace("{DealerId}",dealerId.queryKey[1])
  let result = await fetch(getDealerDetailsURL).then(res=>res.json())
  return result
}

export async function getProductDetails(productId:any) {
  let getProductDetailsURL=URLMap("getProductDetailsAPI")
  getProductDetailsURL=getProductDetailsURL.replace("{productId}",productId.queryKey[1])
  let result = await fetch(getProductDetailsURL).then(res=>res.json())
  return result
}

export async function getDealerProducts({queryKey}: {queryKey:string[]}){

  let dealerId=queryKey[1]
  let SupplierId=queryKey[2]
  //let tempSupplierId="cc9cfc30-5c01-11ec-bf63-0242ac130002"

  let getDealerProductsURL=URLMap("getDealerProductsAPI")
  getDealerProductsURL=getDealerProductsURL.replace('{dealerId}',dealerId)
  getDealerProductsURL=getDealerProductsURL.replace('{supplierId}',SupplierId)
  let result = await fetch(getDealerProductsURL).then(res=>res.json())
  return result
}

export async function getDealerProductsV2(opts:any) {
  let dealerId=opts.dealerId
  let SupplierId=opts.supplierId || undefined
  let pageIndex = opts.pageIndex || 1
  let pageSize = opts.pageSize || 25
  //let tempSupplierId="cc9cfc30-5c01-11ec-bf63-0242ac130002"

  let getDealerProductsURL=URLMap("getDealerProductsAPI")
  getDealerProductsURL=getDealerProductsURL.replace('{dealerId}',dealerId)
  getDealerProductsURL=getDealerProductsURL.replace('{supplierId}',SupplierId)
  getDealerProductsURL=`${getDealerProductsURL}&itemsPerPage=${pageSize}&pageNum=${pageIndex}`
  let result = await fetch(getDealerProductsURL).then(res=>res.json())
  return result
}


export async function getCategories() {
  let getCategoriesURL=URLMap('categoriesAPI')
  let result =await fetch(getCategoriesURL).then(res=>res.json())
  return result.data.categories 
}

export async function getSubcategories(catId:any){
  let getSubcategoriesURL=URLMap('subcategoriesAPI')
  getSubcategoriesURL=getSubcategoriesURL.replace('{catId}',catId.queryKey[1])
  let result = await fetch(getSubcategoriesURL).then(res=>res.json())
  return result.data
}

export async function updateProductDetails(productId:string,payload:object,supplierId:string){
  //let tempSupplierId="cc9cfc30-5c01-11ec-bf63-0242ac130002"
  let supplierId1=supplierId
  
  let updateProductDetailsURL=URLMap('updateProductDetailsAPI')
  updateProductDetailsURL=updateProductDetailsURL.replace('{supplierId}',supplierId1)
  updateProductDetailsURL=updateProductDetailsURL.replace('{productId}',productId)
  let result= await fetch(updateProductDetailsURL,{
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(payload)
  }).then(res => res!.json())
  return result
}

export async function collateralDataUpload(productId:string,storeId:string,metadesc:object,metafiles:any){  
  if(!storeId){
  const form = new FormData();
  let length = metafiles.length
  form.append('productDetails',JSON.stringify({collateralDocs:metadesc}))
  for(var i=0;i<length; i++){
    let file = metafiles[i];
    form.append(`collateralDoc${i}`,metafiles[i].file )
  } 
  let updateProductDetailsURL=URLMap('collateralDataAPIADD')
  updateProductDetailsURL=updateProductDetailsURL.replace('{productId}',productId)
    let result= await fetch(updateProductDetailsURL,{
      method: 'POST',
      body: form
    }).then(res => res!.json())
    return result;
  }
  else{
  const form = new FormData();
  let length = metafiles.length
  form.append('productDetails',JSON.stringify({collateralDocs:metadesc}))
  for(var i=0;i<length; i++){
    let file = metafiles[i];
    form.append(`collateralDoc${i}`,metafiles[i].file )
  } 
  let updateProductDetailsURL=URLMap('collateralDataAPI')
  updateProductDetailsURL=updateProductDetailsURL.replace('{storeId}',storeId)
  updateProductDetailsURL=updateProductDetailsURL.replace('{productId}',productId)
  let result= await fetch(updateProductDetailsURL,{
    method: 'POST',
    body: form
  }).then(res => res!.json())
  return result
  }
}

export async function deleteDealer(dealerId:string,supplierId:string){

  let SupplierId = supplierId
  //let tempSupplierId="cc9cfc30-5c01-11ec-bf63-0242ac130002"
  
  let deleteDealerURL=URLMap('deleteDealerAPI')
  deleteDealerURL=deleteDealerURL.replace('{supplierId}',SupplierId)
  deleteDealerURL=deleteDealerURL.replace('{dealerId}',dealerId)
  let result= await fetch(deleteDealerURL,{
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({})
  })
  return result
}

export async function addProductManually(payload:object,supplierId:string){
  let SupplierId = supplierId
  //let tempSupplierId="cc9cfc30-5c01-11ec-bf63-0242ac130002"
  
  let addProductManuallyURL=URLMap('addProductAPI')
  addProductManuallyURL=addProductManuallyURL.replace('{supplierId}',SupplierId)
  let result= await fetch(addProductManuallyURL,{
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(payload)
  }).then(res => res!.json())
  return result
  
}
export async function signUp(payload:object){
  
  
  let signUpURL=URLMap('signUp')
  let result= await fetch(signUpURL,{
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(payload)
  })
  console.log("signup request result",result)
  return result
  
}

export async function getCatAndsubCatProd(cat_subCat_pageSize_pageIndex:any){
  let catAndsubCatProdUrl=URLMap("catAndsubCatProd")
  catAndsubCatProdUrl=catAndsubCatProdUrl.replace("{catId}",cat_subCat_pageSize_pageIndex.queryKey[1])
  catAndsubCatProdUrl=catAndsubCatProdUrl.replace("{subCatId}",cat_subCat_pageSize_pageIndex.queryKey[2])
  catAndsubCatProdUrl=`${catAndsubCatProdUrl}?itemsPerPage=${cat_subCat_pageSize_pageIndex.queryKey[3]}&pageNum=${cat_subCat_pageSize_pageIndex.queryKey[4]}`
  let result = await fetch(catAndsubCatProdUrl).then(res=>res.json())
  return result
} 

export async function addProducFromDB(payload:any,supplierId:string){

  let SupplierId=supplierId
  //let tempSupplierId="cc9cfc30-5c01-11ec-bf63-0242ac130002"

  let addProducFromDBUrl=URLMap("addProducFromDB")

  addProducFromDBUrl=addProducFromDBUrl.replace("{supplierId}",SupplierId)
  let result = await fetch(addProducFromDBUrl,{
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(payload)
  })
  return result
} 


export async function getProductListForAdditionToDealer({queryKey}: {queryKey:string[]}){

  
  //let tempSupplierId="cc9cfc30-5c01-11ec-bf63-0242ac130002"
  const dealerId=queryKey[1]
  let supplierId = queryKey[4]

  const pageIndex= queryKey[2] || 1
  const pageSize = queryKey[3] || 10
  let getProductListForAdditionToDealerURl=URLMap('getProductListForAdditionToDealer')
  getProductListForAdditionToDealerURl=getProductListForAdditionToDealerURl.replace("{supplierId}",supplierId)
  getProductListForAdditionToDealerURl=getProductListForAdditionToDealerURl.replace("{dealerId}",dealerId)
  getProductListForAdditionToDealerURl=`${getProductListForAdditionToDealerURl}?itemsPerPage=${pageSize}&pageNum=${pageIndex}`
  const result = await fetch(getProductListForAdditionToDealerURl).then(res=>res.json())
  return result

}

export async function addSelectedProductToDealer(dealerId:string,payload:object,supplierId:string){

  let SupplierId=supplierId

  let addSelectedProductToDealerURL=URLMap("addSelectedProductToDealer")
  addSelectedProductToDealerURL=addSelectedProductToDealerURL.replace("{supplierId}",SupplierId)
  addSelectedProductToDealerURL=addSelectedProductToDealerURL.replace("{dealerId}",dealerId)
  let result = await fetch(addSelectedProductToDealerURL,{
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(payload)
  })
  return result
}

export async function uploadTrack({queryKey}: {queryKey:string[]}) {
  let url = URLMap('fileUploadStatus')
  //debugger
  url = url.replace('{userId}', queryKey[1])
  url = url.replace('{reportId}', queryKey[2])
  let result = await timedFetch(url, { method: 'GET'}).then(res => res!.json())
  return result
}

export async function sendInvitation(dealerId:string,supplierId:string) {
  let SupplierId = supplierId
  
  let url = URLMap('sendInvite')
  url = url.replace('{supplierId}', SupplierId)
  url = url.replace('{dealerId}', dealerId)
  let result= await fetch(url,{
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({})
  })
  return result
}
export async function associateSupplier(dealerId:string,supplierId:string) {
  //debugger
  let SupplierId = supplierId
  
  let url = URLMap('associate')
  url = url.replace('{supplierId}', SupplierId)
  url = url.replace('{dealerId}', dealerId)
  let result= await fetch(url,{
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({})
  })
  return result
}

export async function associateRetailer(dealerId:string,supplierId:string) {
  let SupplierId = supplierId
  
  let url = URLMap('sendInvite')
  url = url.replace('{supplierId}', SupplierId)
  url = url.replace('{dealerId}', dealerId)
  let result= await fetch(url,{
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({})
  })
  return result
}

