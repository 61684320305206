import React from 'react'
import { useNavigate } from "react-router-dom";



type propsType={
    title:string,
    content:string,
    buttonText:string
    link:string
    DealerId:string
    name:string
}

type style={
    cardBox:React.CSSProperties
    cardTitle:React.CSSProperties
    cardContent:React.CSSProperties
    button:React.CSSProperties
}

export default function CARD({buttonText,content,title,link,DealerId,name,bgColor,orderStatus,filteredData,filterShow}:propsType) {
    //console.log("card",filteredData)
    const isMobile = window.innerWidth <= 769; 
    let backgroundStyle;
    switch (bgColor) {
        case "red":
          backgroundStyle = "#f5052b";
          break;
        case "green":
          backgroundStyle = "#43b272";
          break;
        case "blue":
          backgroundStyle = "#2eaca8";
          break;
        case "yellow":
          backgroundStyle = "#f5a02b";
          break;
        case "lightBlue":
          backgroundStyle = "#48b3f2";
        break;  
        default:
          backgroundStyle = "initial";
      }  
    const classes:style={
        cardBox:{
            //background: "#F7F7F7",
            background:backgroundStyle,
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
            borderRadius: "5px",
            width:isMobile?"100%":"180px",
            height: "160px",
            marginTop:23,
        },
        cardTitle:{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "22px",
            lineHeight: "29px",
            letterSpacing: "0.06em",
            color: "#FFFFFF",textAlign:"center",paddingTop:23
           },
        cardContent:{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "16px",
            lineHeight: "21px",
            letterSpacing: "0.06em",
            color: "#FFFFFF",textAlign:"center",marginTop:24,marginBottom:0
        },
        button:{
            width: isMobile?"155px":"140px",
            height: "30px",
            background:backgroundStyle,
            borderRadius: "5px",fontFamily: "Roboto",
            fontStyle: "normal",
            border:'none',
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "14px",
            textAlign: "center",
            letterSpacing: "0.06em",color: "#FFFFFF",marginTop:24
        }
    }
    let navigate = useNavigate();
    return (
        <div style={{width:isMobile?'100%':180}}>
            <div style={classes.cardBox} 
              onClick={() =>navigate(`${link}`,{state: {retailerId:DealerId, name:name,orderStatus:orderStatus,filteredData:filteredData,filterShow:filterShow}})}>
              <div style={{padding:0,height:100}}>
                  <h1 style={classes.cardTitle}>{title?title:"0"}</h1>
                  <h1 style={classes.cardContent}>{content}</h1>
              </div>
              <div style={{textAlign: "center",padding:0,paddingBottom:17}}>
                  {/* <button onClick={() => 
                    navigate(`${link}`,{state: {retailerId:DealerId, name:name}})}
                    className='button' style={classes.button}>{buttonText}</button> */}
              </div>
            </div>
        </div>
    )
}
