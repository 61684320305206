 const mailContent=(mobile,email,enterpriseName,fullName)=>{

return(
`
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <h4>Hi,</h4>
    <h2>New registration request for Nearshop Business.</h2>
    <h3>Business details:</h3>
    <h4>Business Name -${enterpriseName}</h4>
    <h4>Owner name -${fullName}</h4>
    <h4>Mobile number -${mobile}</h4>
    <h4>Email Address -${email}</h4>
    <div>
    <h4>Best,Nearshop Team</h4>
    <img src="" alt="">
    </div>
    <p>Confidentiality Disclaimer: “The information contained in this electronic message (email) and any attachments to this email are intended for the exclusive use of the addressee(s) and access to this email by anyone else is unauthorized. The email may contain proprietary, confidential or privileged information or information relating to Integra Group. If you are not the intended recipient, please notify the sender by telephone, fax, or return email and delete this communication and any attachments thereto, immediately from your computer. Any dissemination, distribution, or copying of this communication and the attachments thereto (in whole or part), in any manner, is strictly prohibited and actionable at law. The recipient acknowledges that emails are susceptible to alteration and their integrity cannot be guaranteed and that Company does not guarantee that any e-mail is virus-free and accept no liability for any damage caused by any virus transmitted by this email.”</p>
</body>
</html>
`
)
}

export default mailContent

