import { Modal, Content, Button } from "react-bulma-components"
import {useState} from 'react';

type callbackFn = () => void;

type DialogProps = {
  title?: string;
  message: string;
  yesBtn?: string;
  noBtn?: string;
  onConfirm?: callbackFn;
  onReject?: callbackFn;
  visible: boolean;
  setVisible: callbackFn;
}

const Dialog = (props: DialogProps) => {
  const isMobile = window.innerWidth <= 769;
  return  (
    <Modal show={props.visible}
      onClose={props.setVisible}
      >
      <Modal.Card>
      <Modal.Card.Header >
      <Modal.Card.Title className={isMobile?'is-size-2':'is-size-3'}> {props.title || 'Dialog'} </Modal.Card.Title >
      </Modal.Card.Header>
      <Modal.Card.Body>
        <Content>
          <p className={isMobile?'is-size-2':'is-size-4'}>
            {props.message}
          </p>
        </Content>
      </Modal.Card.Body>
      <Modal.Card.Footer >
        <div className="buttons is-right">
          {props.yesBtn? <Button className={isMobile?'is-large is-pulled-right':'is-medium is-pulled-right'} color="success" onClick={props.onConfirm}>{props.yesBtn}</Button> : null }
          {props.noBtn? <Button className={isMobile?'is-large':'is-medium'} color="danger" onClick={props.onReject}>{props.noBtn}</Button> : null }
          </div>
      </Modal.Card.Footer>

      </Modal.Card>

    </Modal>
  )
}

export default Dialog

