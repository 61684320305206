import { Modal, Content, Button,Dropdown } from "react-bulma-components"
import {useState} from 'react';

type callbackFn = () => void;

type DialogProps = {
  title?: string;
  message: string;
  yesBtn?: string;
  noBtn?: string;
  onConfirm?: callbackFn;
  onReject?: callbackFn;
  visible: boolean;
  setSelectedOption?:string;
  selectedOption?:string;
  setVisible: callbackFn;
  isLoading:boolean;
}

const Dialog = (props: DialogProps) => {
  

  const handleDropdownChange = (value: string) => {
    props.setSelectedOption(value);
    // You can perform additional actions based on the selected option if needed
  };

  return  (
    <Modal show={props.visible}
      onClose={props.setVisible}
      >
      <Modal.Card>
      <Modal.Card.Header >
      <Modal.Card.Title className='is-size-3'> {props.title || 'Dialog'} </Modal.Card.Title >
      </Modal.Card.Header>
      <Modal.Card.Body>
        <Content style={{fontSize:13}}>
            {props.message}
            {props.isLoading ? <p>Loading...</p> : null}
        </Content>
          <Dropdown
            label="Select an option"
            onChange={(value) => handleDropdownChange(value)}
            value="Select"
            color="primary"
            className="is-pulled-center"
            style={{marginTop:'2rem',width:"180px"}}
          >
            <Dropdown.Item value="One or more items in the Order not available" style={{fontSize:'8px'}}>One or more items in the Order not available</Dropdown.Item>
            <Dropdown.Item value="Merchant rejected the order" style={{fontSize:'8px'}}>Merchant rejected the order</Dropdown.Item>
            <Dropdown.Item value="Delivery pincode not serviceable" style={{fontSize:'8px'}}>Delivery pincode not serviceable</Dropdown.Item>
            <Dropdown.Item value="Pickup pincode not serviceable" style={{fontSize:'8px'}}>Pickup pincode not serviceable</Dropdown.Item>
          </Dropdown>
      </Modal.Card.Body>
      <Modal.Card.Footer >
        <div className="buttons is-right">
          {props.yesBtn? <Button className='is-medium is-pulled-right' disabled={!props.selectedOption} style={{width:70,height:30,fontSize:14}} color="success" onClick={props.onConfirm}>{props.yesBtn}</Button> : null }
          {props.noBtn? <Button className='is-medium' color="danger" style={{width:70,height:30,fontSize:14}} onClick={props.onReject}>{props.noBtn}</Button> : null }
          </div>
      </Modal.Card.Footer>

      </Modal.Card>

    </Modal>
    
  )
}

export default Dialog

