import {useTable, useBlockLayout} from 'react-table'
import {useEffect, useMemo, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import {Table, Button } from 'react-bulma-components'
import { useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import Dialog from '../dialogs/dialog'
import { useQuery } from 'react-query'
const jp = require('jsonpath')

const PAGE_SIZE=25;

export default function DataTable(props) {

  const {
    columnsData,  // columns definition for the table.
    fetcher,  // A query fn to fetch the item list
    dataPath,   // A location in the result json that contains relevant data.
    hooks,
    onEdit,   // a callback fn that will be called (generally for saving the modified item) when user edits an item 
    onDelete  // a callback fn to delete the selected item
  } = props;


  // State manager for delete confirmation
  //const [showConfirm, setShowConfirm] = useState({productId:null, show:false})
  const [listInfo, setListInfo] = useState({
    data: [], 
    error: null,
    shouldFetch: false,
    showConfirm: false,
    onDeleteConfirm: onDelete,
    onEdit: onEdit,
    selectedItem: null,
    totalCount: 0,
    currentCount: 0,
    pageIndex:1
  })

  const [confirmDialog, setConfirmDialog] = useState({
    show:false,
    selectedItem: null
  })

  const deleteProductNo = () => {
    setConfirmDialog({...confirmDialog, show:false})
  }

  const deleteProductYes = (productId) => {
    onDelete(productId)
  }

  //const navigate = useNavigate()

  //TODO: Column definitions can come from props, as they
  // may vary from case to case.
  const columns = useMemo(() =>  columnsData, columnsData)

  const fetchNext = async () => {
    //TODO: Add error handling...
    console.log('Executing fetchnext...');
    let results = await fetcher.fn({...fetcher.args, pageIndex:listInfo.pageIndex+1})
    //TODO: read from the caller.
    let incoming = jp.query(results, fetcher.dataPath)[0]
    setListInfo({
      ...listInfo,
      pageIndex: listInfo.pageIndex + 1,
      currentCount: listInfo.currentCount + incoming.length,
      data: listInfo.data.concat(incoming),
      // totalCount: results?.totalProducts
      totalCount: results?.totalProductsCount || results?.totalProducts
    });
  };


  const data = useMemo(() => { return listInfo.data; }, [listInfo.data])
  //const data = useMemo(() => { }, [data])


  const fetchData = async () => {
    console.log("Data table fetch data: executing...")
    //TODO: Add error handling...
    let results = await fetcher.fn(fetcher.args)
    let incoming = jp.query(results, fetcher.dataPath)[0]
    //debugger

    setListInfo({...listInfo, 
            data:incoming,
            currentCount:incoming.length,
            // totalCount: results?.totalProducts
            totalCount: results?.totalProductsCount || results?.totalProducts

    })
    return incoming
  }

  const tableInstance = useTable({columns, data:data}, hooks)

  const  {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = tableInstance;


  useEffect(() => {
    console.log('Use effect...');
    fetchData()
    return () => {
      console.log("Data table unmount.")
    }
  }, []);

  console.log("List Info, before markup:", listInfo)
  return (
    <>
    <InfiniteScroll
      dataLength={rows.length}
      next={fetchNext}
      hasMore={listInfo.currentCount < listInfo.totalCount}
      loader={<h4>Loading...</h4>}
      endMessage="End of data."
      height={1488}
    >
      <Table className='is-fullwidth' {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup,) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}> {column.render("Header")} </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
          <tr {...row.getRowProps()}>
              {row.cells.map(cell =>{
                return (
                  <td {...cell.getCellProps()}> {cell.render('Cell') } </td>
                )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
    {
      confirmDialog.show
            ? <Dialog message={`Delete the item (${confirmDialog.selectedItem.name})`}
                  title="Delete Product"
                  yesBtn='Yes'
                  noBtn='No'
                  onConfirm={deleteProductYes}
                  onReject={deleteProductNo}   
                  visible={true}
                  setVisible={()=>setListInfo({...confirmDialog, show:false})} />
            : null
    }
    </InfiniteScroll>
      </>
  )
}
