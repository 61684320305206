import React, { useEffect, useState,useMemo } from 'react'
import {useQuery} from 'react-query'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {Button} from 'react-bulma-components'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'
import ADDPRODANDDEALER from '../../addprodNdealer/addProdAndDealer'
import PRODUCTDETAILFORM from '../../forms/product-detail-form'
import SEARCHBOX from '../../searchbox/searchbox'
import HEADER from '../header' 
//import PRODUCTLIST from './product-list'
import DataTable from '../../utils/data-table'
import { product,deleteSupplierProduct, getDealerProductsV2 } from '../../../lib/nearshop-api'
import { useSelector , RootStateOrAny} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import Dialog from '../../dialogs/dialog'
import { supplierProductsSearch } from '../../../lib/search-api'
import PRODUCTADDEDCARD from './productAddedCard';
import {dashboard} from '../../../lib/nearshop-api'
import {useTable, useBlockLayout} from 'react-table'

type style={ searchTxt:React.CSSProperties }
const ASSIGN_CONFIRM_TEXT = `Do you want to delete this Product?`

function PRODUCTS() {
  
    const classes:style={
        searchTxt:{
         fontFamily: "Roboto",
         fontStyle: "normal",
         fontWeight: "300",
         fontSize: "10px",
         lineHeight: "1.6rem",
         letterSpacing: "0.06em"
        }
    }
    const navigate = useNavigate();
    const dropDownItems = [
        {
            text: "Add from Product Database",
            link:"/Products/addProduct/Categories"
        },
        {
            text: "Search product barcode",
            link:"/Products/addProduct/scanBarcode"
        },
        {
            text: "Add manually",
            link:"/Products/addProduct/manually"
      
        },
        {
            text:"Bulk upload of products",
            link:"/Products/addProduct/bulkUpload"
        }
    ]
    const {searchtoken}=useParams()
    const location=useLocation()
    const supplierId= useSelector((state:RootStateOrAny)=>state.reducer.supplierId)
    const retailerInfo = location.state

    /*
     * This page will be used for 3 different contexts.
        1. For showing products global
        2. For showing products added to a retailer
        3. For showing search results
        In each of these cases, the function and args to fetch data will be different
        So, we memorize these 
    */
    const funcMap = React.useMemo(() => {
    //const funcMap = () => {
      if (searchtoken) {
        return( {
          key: `/api/products/search/${searchtoken}`, 
          fn:supplierProductsSearch,
          args: {supplierId: supplierId, searchToken: searchtoken},
          dataPath:'products',
          countPath: 'totalProducts'
        })
      } else if (retailerInfo?.retailerId) {
        return ({
          key: `/api/products/retailers/${retailerInfo}`, 
          args: {supplierId: supplierId, dealerId:retailerInfo?.retailerId}, 
          fn:getDealerProductsV2, 
          dataPath:'products',
          countPath: 'totalProducts'
        })
      } else {
        return  ({
          key: '/api/products', 
          args: {supplierId: supplierId}, 
          fn:product, 
          dataPath:'supplier.products',
          countPath: 'totalProducts'
        })
      }
    }, [searchtoken, retailerInfo])

    const deleteProductNo = () => {
      setDeleteProduct("")
    }
  
    const deleteProductYes = () => {
      deleteSupplierProduct(productIdtemp,supplierId)
      setDeleteProduct("")
      window.location.reload()
    }

const [DeleteProduct, setDeleteProduct] = useState("")
const [productIdtemp, setproductIdtemp] = useState("")

    const  handleDeleteButton=(productId:string)=>{
          setproductIdtemp(productId)
          setDeleteProduct(ASSIGN_CONFIRM_TEXT)
    }
   

    const editProduct = (productId:string) => {
      
     navigate('/products/edit', {state:{productId:productId,dealerId:retailerInfo?.retailerId,dealerName:retailerInfo?.name} })
    }

    
  
   const [listInfo, setListInfo] = useState({
    data: [], 
    error: null,
    shouldFetch: false,
    showConfirm: false,
    selectedItem: null,
    totalCount: 0,
    currentCount: 0,
    pageIndex:1
  })

    const jp = require('jsonpath')

    const fetchData = async () => {
      try {
          console.log("Data table fetch data: executing...");

          let results = await funcMap.fn({ ...funcMap.args, pageIndex: listInfo.pageIndex });

          if (!results) {
              console.error("Error: No results returned.");
              return;
          }

          let incoming = jp.query(results, funcMap.dataPath)[0];

          setListInfo((prevListInfo:any) => ({
              ...prevListInfo,
              data: [...prevListInfo.data, ...incoming], // Concatenate previous data with new data
              currentCount: incoming.length,
              totalCount: results?.totalProductsCount || results?.totalProducts,
          }));

          return incoming;
      } catch (error) {
          console.error("Error fetching data:", error);
      }
  };

  const handlePageChange = (newPageIndex:any) => {
    setListInfo((prevListInfo:any) => ({
        ...prevListInfo,
        pageIndex: newPageIndex,
    }));
  };

  useEffect(() => {
    console.log('Use effect...');
    if(listInfo.data.length<=listInfo.totalCount)
      {
        fetchData();
      }
    return () => {
        console.log("Data table unmount.");
    };
  }, [listInfo.pageIndex]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.scrollY;
      const totalHeight = document.documentElement.scrollHeight;
      const offset = 200;
      if (!isLoading && scrollPosition >= totalHeight - offset) {
        setIsLoading(true);
        handlePageChange(listInfo.pageIndex + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isLoading, listInfo.pageIndex]);

  useEffect(() => {
    setIsLoading(false);
  }, [listInfo.data]);


  // console.log("List Info, before markup:", listInfo)
  const isMobile = window.innerWidth <= 769;
  return (
        <div  className="Products"style={{paddingLeft:"15px",paddingRight:'15px',width:'98%'}}>
            <HEADER breadCrumb={retailerInfo?.retailerId ? '>' + retailerInfo.name :''} title='Products'/>
            <div className='container'>
            <div className='columns' style={{display:"flex"}}>
            <div className={isMobile?'column is-6-mobile':'column is-9'}>
            <SEARCHBOX placeholder={"Search Products"} />
            </div>
            <div className={isMobile?'column is-2-mobile':'column is-3'}>
            <ADDPRODANDDEALER dealerId={retailerInfo?.retailerId || null} dropDownItems={dropDownItems} buttonTxt='Add Products'/>
            </div>
            </div>
            {searchtoken!==undefined?
            <h1 style={classes.searchTxt}>{`Results matching '${searchtoken}'`}</h1>:null
            }
           <div className="card is-fullwidth"  style={{ marginBottom: '10px',backgroundColor:'white',display:'flex'}}>
             <div className='column is-1-mobile'>
                  <button className='button is-light'>
                    <span className="icon">
                      <Link to='/'>
                      <FontAwesomeIcon icon={faArrowLeft} style={{fontSize:"15px",color:'black'}} />
                      </Link>
                    </span>
                  </button>
            </div>
            <div className='column is-6-mobile'>
              <h1 className="title is-3">Products</h1>
            </div>
            </div>
        
          <div className="columns is-multiline">
          {listInfo?.data?.map((product) => (
            <div className="column is-12" key={product.id} onClick={() => editProduct(product.id)}>
              <PRODUCTADDEDCARD
                product={product}
                onEdit={editProduct}
                onDelete={handleDeleteButton}
              />
            </div>
          ))}
          </div>
            
            </div> 
            {DeleteProduct.length>0
            ? <Dialog message={DeleteProduct} 
            title="Delete Product"
                  yesBtn='Yes'
                  noBtn='No'
                  onConfirm={deleteProductYes}
                  onReject={deleteProductNo}   
                  visible={true}
                  setVisible={()=>setDeleteProduct("")}
              />
            : null}
        </div>
    )
}
export default PRODUCTS

