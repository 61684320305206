import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'

type propType = {
    placeholder: string

}

type style = {
    searchBox: React.CSSProperties
    searchBtn: React.CSSProperties
    input: React.CSSProperties

}
function SEARCHBOX({ placeholder }: propType) {
    const isMobile = window.innerWidth <= 769;
    const classes: style = {
        searchBox: {
            border: "1px solid #E5E5E5",
            boxSizing: "border-box",
            borderRadius: 5,
            marginBottom: 30,
            zIndex: 1,
            display: "flex",
            height: 43,
            maxWidth:isMobile? 915 : 1100
        },
        searchBtn: {
            width: isMobile?50:90,
            height: 43,
            background: "#DD242E",
            borderRadius: 5,
            zIndex: 2

        },
        input: {
            width: 915,
            height: 43,
            border: "1px solid #E5E5E5",
            boxSizing: "border-box",
            borderRadius: 5,
            fontSize: "14px"
        }
    }
    const [searchInput,setSearchInput]=React.useState("")
    const navigate=useNavigate()
    const handleClick = () => {

        if(searchInput.length>0 && !null){
        if(placeholder==="Search Products"){
        navigate
        (`/Products/search/${searchInput}`)
        }
        if(placeholder==="Search Retailers"){
        navigate(`/Retailers/search/${searchInput}`)
        }
        if(placeholder==="Enter 13 digit product barcode number here"){
            //debugger
            if(searchInput !== null){
            navigate('/Products/addProduct/scanBarcode',{state:searchInput})
            }
        }
        setSearchInput("")
        
        }
    }
    const handleChange=(e:any)=>{
        setSearchInput(e.target.value)
    }
    return (
        <div style={classes.searchBox}>

            <input style={classes.input} className="input" value={searchInput} onChange={handleChange} placeholder={placeholder} />

            <button onClick={handleClick} className='button' style={classes.searchBtn}>
                <img style={{ maxWidth: 19 }} src="./icons/searchicon.svg"></img>
            </button>

        </div>
    )
}

export default SEARCHBOX
