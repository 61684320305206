import React,{useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Dialog from '../../dialogs/dialog'

const ASSIGN_CONFIRM_TEXT = `Do you want to delete this Product?`

type propType={
 editButton:boolean
 deleteIcon:boolean
 selectAll:boolean
 checkBox:boolean

 productId:string
 handleProductId:any
 deleteProduct:any
 product:{
     name:string
     imageUrl:string
     price:string
     onSalePrice:string   
 },
 addSelectedItem:any
 removeUnselectedItem:any
 setSelectAll:any
}

type style ={
    editButton:React.CSSProperties
    box:React.CSSProperties
    prdName:React.CSSProperties
    price:React.CSSProperties
}

export default function PRODUCTCARD(props:propType) {

   const {selectAll,productId,product,editButton,deleteIcon,checkBox,deleteProduct,addSelectedItem,removeUnselectedItem,setSelectAll}=props
    const classes:style={
        editButton:{
            width: "80px",
height: "31px",
border: "1px solid #DD242E",
boxSizing: "border-box",
borderRadius: "5px",marginTop:32,fontSize: "1.5rem"
        },
        box:{
            width: "1150px",height:124,
border: "1px solid #E7E7E7",display:"flex",flexDirection:"row"
        },
        prdName:{
            width: "390px",
height: "32px",marginLeft:"74px",marginTop:39,

fontFamily: "Roboto",
fontStyle: "normal",
fontWeight: "300",
fontSize: "1.4rem",
lineHeight: "1.6rem",
letterSpacing: "0.06em",
        },
        price:{
            fontFamily: "Roboto",
fontStyle: "normal",
fontWeight: "300",
fontSize: "1.4rem",
lineHeight: "1.6rem",
letterSpacing: "0.06em",
marginTop:39,width:"207px"
        }
    }
    const navigate=useNavigate()
    const [isChecked,setIsChecked]=React.useState(false)

    React.useEffect(()=>{
        setIsChecked(selectAll)
        

    },[selectAll])

    
    const deleteProductNo = () => {
        setDeleteProduct("")
      }
    
      const deleteProductYes = () => {
        deleteProduct(productId)
      }

      const [DeleteProduct, setDeleteProduct] = useState("")

    const handlecheckbox=(e:any)=>{
        setIsChecked( e.target.checked)
        
       if(e.target.checked===false){
           console.log("hereeeeeeeeeeeeeeeeeeeeeeeeee")
    
        }
     
        if(e.target.checked===true){

            addSelectedItem(
                
                productId,
                
            )
            // addSelectedItem(
            //     productId,
            // )
        }

        
        else{
            
            removeUnselectedItem(productId)
        }
    }

    
    const  handleDeleteButton=()=>{
        setDeleteProduct(ASSIGN_CONFIRM_TEXT)
        //deleteProduct(productId)
    }
    return (
        <div style={classes.box}>
            {checkBox===true?
            <div style={{marginTop:44,marginLeft:"2.1rem"}}>
            <input type="checkbox" checked={isChecked} onChange={handlecheckbox} />
            
            </div>:null}
            <img style={{marginLeft:checkBox?"10px":"47px",marginTop:29,objectFit:"contain",height:60,width:60}}  src={product.imageUrl} />
            <h1 style={classes.prdName}>{product.name}</h1>
            <h1 style={classes.price}>{`₹${product.price}`}</h1>
            <h1 style={{...classes.price,width:"205px"}}>{`₹${product.onSalePrice}`}</h1>
            {editButton===true?
            <button onClick={()=>navigate("/Products/edit",{state:productId})} style={classes.editButton} className='button'>Edit</button>:null}
            {deleteIcon===true?
            <img onClick={handleDeleteButton} style={{marginLeft:"45.5px",marginTop:"-30px"}} height={20} width={22} src={'./icons/deleteicon.svg'} />:null}
            {DeleteProduct.length>0
            ? <Dialog message={DeleteProduct} 
            title="Delete Product"
                  yesBtn='Yes'
                  noBtn='No'
                  onConfirm={deleteProductYes}
                  onReject={deleteProductNo}   
                  visible={true}
                  setVisible={()=>setDeleteProduct("")}
              />
            : null}
        </div>
    )
}
