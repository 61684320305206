import React, {useState} from 'react'
import INPUT from './forminput/input'
import TEXTAREA from './forminput/textarea'
import {Formik, Form} from 'formik' 
import * as Yup from 'yup'
import { addDealer,updateRetailer } from '../../lib/nearshop-api'
import Dialog from '../dialogs/dialog'
import { toast } from 'bulma-toast'
import { useSelector ,RootStateOrAny, useDispatch} from 'react-redux';
import { Navigate,useNavigate } from 'react-router-dom'

import {isEmpty} from '../../../src/utils/regex'
import AddRetailerCases from '../../utils/add-retailer-cases'



// ****************************************************************************************************************
//  Adding a retailer to system can result in a response that can lead to the following variations:
//  ================================================================================================================
//  | verified  | Linked | Meaning                              | Response to user            | action
//  ================================================================================================================
//  |  true     | true   | retailer is active in the system, and| Msg without Y/N. Indicate   | None
//  |           |        | is linked with the current supplier. | that a retailer with phone  |
//  |           |        |                                      | already exists.             |
//  |--------------------|--------------------------------------|----------------------------------------------------
//  |  true     | false  | retailer is active, but not linked   | show msg and the details of |
//  |           |        | to supplier.                         | the retailer.  Ask confirm- |Send Invite on confirm.
//  |           |        |                                      | ation to send invite.       |
//  |--------------------|---------------------------------------------------------------------------------
//  |  false    | true   | This won't happen.                   | Backend program bug.
//  |--------------------|----------------------------------------------------------------------------------
//  |  false    | false  | This is a new retailer.              | Success Message without any confirmation.
// ****************************************************************************************************************

const sendInvite = (retailerInfo:object) => {
  alert('sendInvite')
}

//const ASSIGN_CONFIRM_TEXT = `This dealer already exists in the system.  Do you want to link this dealer instead?`


type style={
    input:React.CSSProperties
    inputName:React.CSSProperties
    button:React.CSSProperties
}

export default function AddDealerForm({handleSubmit,storeName,
    address,
    storePinCode,
      storeState,
      storeContactName,
      storeContactNumber,
      contactEmail,
      storeDistrict,
    storeWebsite,
    dealerId,
    disabled}:any) {
    const isMobile = window.innerWidth <= 769;  
    const classes:style = {
         
        input: {
            width:isMobile?'':"35.9rem",minWidth:"35.9rem",fontSize: "14px"
        },
        inputName: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "16px",
            letterSpacing: "0.06em",
            marginTop:isMobile?"0px":"-10px",
            marginLeft:"3rem"
        },
        button: {
            width: "135px",
            height: "30px",
            background: "#4D9D27",
            borderRadius: "5px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "14px",
            textAlign: "center",
            letterSpacing: "0.06em",
            color: "#FFFFFF",
            border:"none"
        },
    }

  const assignDealer = () => {
      alert("Adds.")
  }
  

  
  // Various errors (while adding retailer), and their 
  // corresponding messages to user and subsequent actions.
  const errorCodeMap = {
    linked: {
      msg: 'A retailer with this phone number is already linked to your network.'
    },
    'exists' : {
      msg:`This dealer already exists in the system.  Do you want to link this dealer instead?`,
      yesAction: assignDealer,
      noAction: ''
      },
    'notAccepted': {
      msg: `The retailer had already been invited, but not yet accepted your invite. 
                    Do you want send invite again?`,
      yesAction: sendInvite,
      noAction: ''
    },
    'others': {
      msg:'',
      noAction: ''
    }
  }
  const setVisible=()=>setSubmitResponse({})
 


  const [formStatus, setFormStatus] = useState({

  })
    const [submitResponse, setSubmitResponse] = useState({})
    const supplierId= useSelector((state:RootStateOrAny)=>state.reducer.supplierId)
    const navigate=useNavigate()
    
    const validate=Yup.object({
        name:Yup.string()
        .required('required'),
        address:Yup.string()
        .required('required'),
        city:Yup.string()
        .required('required'),
        state:Yup.string()
        .required('required'),
        contactPersonName:Yup.string()
        .required('required'),
        mobile:Yup.number()
        .required('required'),
        email:Yup.string()
        .email("Enter valid Email")
        .required('required'),
        pincode:Yup.number()
        .required('required'),
        
      })
    
    return (
        <div>
           <Formik 
            enableReinitialize
      initialValues={{
        name: "" || storeName,
        address: "" || address,
        pincode: "" || storePinCode,
        state: "" || storeState,
        contactPersonName:"" || storeContactName,
        mobile: "" || storeContactNumber,
        email: "" || contactEmail,
        city:  "" || storeDistrict,
        website: "" || storeWebsite,
      }}
      
      onSubmit={async values => {
        

        if(dealerId){

          let {data, error} = await updateRetailer({            
            webUrl:values.website            
        },dealerId)

        if (data){
          toast({
              message: 'Updated Successfully',
              type: 'is-primary',
              position:"bottom-center",
              extraClasses:"tostmsg",
              duration: 3000,
            })
            
            //window.history.go(-1);
            navigate(`/Retailers`);
      }
      

        
          
          

        }

        else{
          let {data, error} = await addDealer({            
            name: values.name,
            contactPersonName: values.contactPersonName,
            address: values.address,
            mobile: values.mobile,
            city: values.city,
            email: values.email,
            state: values.state,
            webUrl:values.website,
            pincode: [
              values.pincode
            ],
        },supplierId)

        
       

        //prajwal
        if (data){
            console.log("========================Retailer Added===================")
            toast({
                message: 'Retailer is Added',
                type: 'is-primary',
                position:"bottom-center",
                extraClasses:"tostmsg",
                duration: 3000,
              })
              
              //window.history.go(-1);
              navigate(`/`);
        }
          if (error?.statusCode === 409) {
            // parse the message to know retailer's status.
            // if verified, show msg that retailer already added and active.
            // if verified===false, confirm, if invite should be resent.
            //setSubmitError(error?.statusText)
            let msg = JSON.parse(error.message)
              setSubmitResponse(msg)
          } else if (error) {
          //TODO: show error message
          }
        }

      }}


      validationSchema={validate}>
          {formik=>(

              <Form id='add-dealer-form'>
        <div className='columns' style={{marginTop:20}}>
            <div className='column'>
            <h1 style={{...classes.inputName,marginBottom:isMobile?10:14}}>Name</h1>
            <div style={{paddingBottom:isMobile?5: 26}}>
            <INPUT style={{...classes.input,height: "55px"}} 
                className={`input`}  
                type="text" 
                placeholder="Enter Name"
                name="name"
                disabled={disabled}
                />
            </div>
            <h1 style={{...classes.inputName,marginBottom:isMobile?10:24}}>Address</h1>
            <div style={{paddingBottom:isMobile?12: 26}}>
            <TEXTAREA style={{...classes.input,height: "123px"}} 
                className={`textarea has-fixed-size`}
                placeholder="Enter Address"
                name="address"
                disabled={disabled}/>
                
            </div>
            <h1 style={{...classes.inputName,marginBottom:isMobile?10:24}}>City</h1>
            <div style={{paddingBottom:isMobile?5: 26}}>
            <INPUT style={{...classes.input,height: "42px"}} 
                className={`input`}
                type="text" 
                name='city'
                placeholder="Enter City"
                disabled={disabled}/>
            </div>
            <h1 style={{...classes.inputName,marginBottom:isMobile?10:24}}>State</h1>
            <div style={{paddingBottom:isMobile?5: 26}}>
            <INPUT style={{...classes.input,height: "42px"}} 
                className={`input `}
                type="text" 
                name='state'
                placeholder="Enter State"
                disabled={disabled}/> 
            </div>   
            </div>
            <div className='column'>
            <h1 style={{...classes.inputName,marginBottom:isMobile?10:14}}>Contact Person Name</h1>
            <div style={{paddingBottom:isMobile?5: 20}}>
            <INPUT style={{...classes.input,height: "42px"}} 
                className={`input`} 
                type="text" 
                placeholder="Enter Contact Person Name"
                name="contactPersonName"
                disabled={disabled}/>
            </div>
            <h1 style={{...classes.inputName,marginBottom:isMobile?10:14,marginTop:12}}>Mobile Number</h1>
            <div style={{paddingBottom:isMobile?5: 15}}>
            <INPUT style={{...classes.input,height: "42px"}} 
                className={`input`}
                type="tel" 
                placeholder="Enter Mobile Number"
                name="mobile"
                disabled={disabled}/>
            </div>    
            <h1 style={{...classes.inputName,marginBottom:isMobile?10:14,marginTop:10}}>Email Id</h1>
            <div style={{paddingBottom:isMobile?5: 14}}>
            <INPUT style={{...classes.input,height: "42px"}} 
                className={`input`} 
                type="email" 
                placeholder="Enter Email"
                name="email"
                disabled={disabled}/>
            </div>
            <h1 style={{...classes.inputName,marginBottom:isMobile?10:14,marginTop:10}}>Pin Code</h1>
            <div style={{paddingBottom:isMobile?5: 20}}>
            <INPUT style={{...classes.input,height: "42px"}} 
                className={`input`} 
                type="text" 
                placeholder="Enter Pincode"
                name="pincode"
                disabled={disabled}/>
                 <h1 style={{...classes.inputName,marginBottom:isMobile?10:14,marginTop:10}}>Website Url</h1>
            <div style={{paddingBottom:isMobile?5: 20}}>
            <INPUT style={{...classes.input,height: "42px",minWidth:"0",width:"51rem",marginLeft:"3rem"}} 
                className={`input`} 
                type="text" 
                placeholder="Enter Website URL"
                name="website"/>

            </div>
                
            </div>
            </div>
        </div>
        </Form>
        )}
        
        </Formik>
          {!isEmpty(submitResponse)
            ? <AddRetailerCases submitResponse={submitResponse} setVisible={setVisible}/>:null}
        </div>
    )
}



