import { Modal, Content, Button,Dropdown } from "react-bulma-components"
import {useState} from 'react';

type callbackFn = () => void;

type DialogProps = {
  title?: string;
  message: string;
  yesBtn?: string;
  visible: boolean;
  setVisible: callbackFn;
}

const SuccessDialog = (props: DialogProps) => {
  

 

  return  (
    <Modal 
      show={props.visible}
      onClose={props.setVisible}
      >
      <Modal.Card>
      <Modal.Card.Header >
      <Modal.Card.Title className='is-size-3'> {props.title || 'Dialog'} </Modal.Card.Title >
      </Modal.Card.Header>
      <Modal.Card.Body>
        <Content style={{fontSize:13}}>
            {props.message}
        </Content>
      </Modal.Card.Body>
      <Modal.Card.Footer >
        <div className="buttons is-right">
          {props.yesBtn? <Button className='is-medium is-pulled-right'  style={{width:70,height:30,fontSize:14}} color="success" onClick={props.onConfirm}>{props.yesBtn}</Button> : null }
          </div>
      </Modal.Card.Footer>

      </Modal.Card>

    </Modal>
    
  )
}

export default SuccessDialog

