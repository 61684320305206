import React from 'react'
import INPUT from './forminput/input'
import { Formik, Form, FieldArray, Field } from 'formik'
import * as Yup from 'yup'
import TEXTAREA from './forminput/textarea'
import { useNavigate } from 'react-router-dom'
import { addProductManually,collateralDataUpload } from '../../lib/nearshop-api'
import { useSelector } from 'react-redux';
import { toast } from 'bulma-toast'


function AddProductForm() {
    const isMobile = window.innerWidth <= 769;
    const classes = {
        heading: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "16px",
            letterSpacing: "0.06em",
            textDecorationLine: "underline", marginTop: 32,
        },
        dialogTxt:{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: 24,
            textAlign: "center",
            letterSpacing: "0.06em",
            color: "#000000",
        },
        input: {
            width:isMobile?"100%": "35.9rem", fontSize: 14, minWidth: "35.9rem", fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 300, marginLeft:isMobile?"0":"3rem",

        },
        inputName: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "16px",
            letterSpacing: "0.06em",
            marginLeft: "3rem",
            color: "#000000"
        },
        button: {
            width: "135px",
            height: "30px",
            background: "#4D9D27",
            borderRadius: "5px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "14px",
            textAlign: "center",
            letterSpacing: "0.06em",
            color: "#FFFFFF",
            border: "none",
            marginTop: 51
        },

        buttonMeta: {
            width: "135px",
            height: "30px",
            background: "#4D9D27",
            borderRadius: "5px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "14px",
            textAlign: "center",
            letterSpacing: "0.06em",
            color: "#FFFFFF",
            border: "none",
            marginTop:isMobile?'5px':"60px",
            marginLeft:isMobile?"17spx":"25px",
            cursor:'pointer'

        },
        editImage: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "15px",
            lineHeight: "18px",
            letterSpacing: "0.06em",
            color: "#DD242E"
        },
        imageBox: {
            border: "1px solid #E7E7E7",
            boxSizing: "border-box", width:isMobile?"100%":"37.1rem",
            height: "37.1rem",
            background: "rgba(196, 196, 196, 0.4)"
        },
        imageBoxCollateral: {
            border: "1px solid #E7E7E7",
            boxSizing: "border-box", width:isMobile?"100%":"35.1rem",
            height: "5.1rem",
            marginBottom:'30px',
            marginLeft:isMobile?'4px':'44px',
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            gap:'40px'
            
        },
        img: {
            height: "13.9rem", width: "13.9rem", objectFit: "contain",
            marginLeft:isMobile?"20rem":"11.6rem", marginTop: "11.6rem"
        },

        imageBoxMeta: {
            border: "1px solid #E7E7E7",
            boxSizing: "border-box", width: "148px",
            height: "145px",
            background: "rgba(196, 196, 196, 0.4)",
            margin: "auto"
        },
        imgMeta: {
            height: "140px", width: "148px", objectFit: "contain",
        },
        editImageMeta: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "15px",
            lineHeight: "18px",
            letterSpacing: "0.06em",
            color: "#DD242E",
            marginLeft: "140px",
            cursor:'pointer'

        },
        addSelectedBtn:{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "16px",
            textAlign: "center",
            background: "#409D3E",
            borderRadius: 5,
            justifyContent:'center',
            alignContent:'center',
            margin:'auto',
            color: "#FFFFFF",
            width: 130,
            height: 28,
            //marginTop:22
        },
        textStyle: {
            fontFamily: "Roboto",
            width: "190px",
            height: "18px",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "15px",
            letterSpacing: "0.06em",
            marginLeft: "5px",
            marginTop: "13px",
            color: "#000000"
        },
        buttonStyle: {
            backgroundColor: "white",

            width: "35.9rem",
            height: "42px",
            marginLeft:isMobile?"0":"3rem",


            border: "1px solid #C7C7C7",
            boxSizing: "border-box"
        },
        dialogHeaderTxt: {
            width: "314px",
            height: "21px",

            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "18px",
            lineHeight: "16px",
            letterSpacing: "0.06em",
            color: "#000000",
            marginLeft:isMobile?'11px': "32px",
            marginTop: "31px"
        },
        dialogHeader: {
            margin: "auto",
            position: "absolute",

            width: isMobile?'350px':"900px",
            height:isMobile?'600':"500px",
            top: "100px",
            background: "#FFFFFF",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            boxSizing: "border-box",

        },
        inputMeta: {
            width: "15.9rem", fontSize: 14, minWidth: "15.9rem", fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 300,
            height: "42px", caretColor: "transparent", marginLeft: "-5px", marginRight: "10px",
            border: "1px solid #C7C7C7", boxSizing: "border-box"
        },
    }
    const validate = Yup.object({
        productName: Yup.string()
            .required('required'),
        // description: Yup.string()
        //     .required('required'),
        mrp: Yup.number()
            .required('required'),
        // sellingPrice: Yup.number()
        //     .required('required'),
        // productBrand: Yup.string()
        //     .required('required'),
        // countryOfOrigin: Yup.string()
        //     .required('required'),
        // barcode: Yup.number()
        //     .required('required'),
        
    })


    const [fileBase64String, setFileBase64String] = React.useState("")
    const [uploadedImage, setUploadedImage] = React.useState("./icons/addimageicon.svg")
    const [uploadedMetaImage, setMetaUploadedImage] = React.useState("./icons/addimageicon.svg")
    const [metafileBase64String, setMetaFileBase64String] = React.useState("")    
    const [show, setShow] = React.useState(false)
    const navigate = useNavigate()
    const addMetaData = () => {
        setShow(!show)
    }
    const AddMeta = () => {
        setMetaFileBase64String("./icons/addimageicon.svg")
        setMetaUploadedImage("./icons/addimageicon.svg")
    }
    const CancelMetaForm = () => {
        setMetaFileBase64String("./icons/addimageicon.svg")
        setMetaUploadedImage("./icons/addimageicon.svg")        
        setShow(!show)

    }
        const encodeFileBase64 = (file) => {
        
        var reader = new FileReader()
        if (file) {
            reader.readAsDataURL(file[0])
            reader.onload = () => {
                var base64 = reader.result
                setFileBase64String(base64)
            }
        }
    }

    const handleOnchange = (e) => {
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            //if(selectedFile&&fileType.includes(selectedFile.type)){
            let reader = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.onloadend = (e) => {
                setMetaFileBase64String(e.target.result);
                //setPdfFileError('');
            }

        }
    }

    const encodeMetaFileBase64 = (file) => {
        
        var reader = new FileReader()
        if (file) {
            reader.readAsDataURL(file[0])
            reader.onload = () => {
                var base64 = reader.result
                setMetaFileBase64String(base64)
            }
        }
    }


    if (uploadedImage !== "./icons/addimageicon.svg") {
        encodeFileBase64(uploadedImage)
    }
    if (uploadedMetaImage !== "./icons/addimageicon.svg") {
        encodeMetaFileBase64(uploadedMetaImage)       
   }

    const supplierId = useSelector((state => state.reducer.supplierId))
    const [showDialog,setShowDialog]=React.useState(false)
    const [Diagmessage,setDialogMessage]=React.useState("")
    const [DiagStatusCode,setDialogStatusCode]=React.useState("")
    const [DiagIcon,setDialogIcon]=React.useState("")
    const [isSuccess,setIsSuccess]=React.useState()




    return (

        <div>
            {isMobile && <style>
              {`
                .file{
                    margin-left:0px !important
                }
              `}
            </style>
           }
            <Formik
                initialValues={{
                    productName: '',
                    description: '',
                    mrp: '',
                    sellingPrice: '',
                    productBrand: '',
                    countryOfOrigin: '',
                    barcode: '',
                    resume: "",
                    metaDescription: [{
                        name: "",
                        description: ""
                    }],
                    fileArray: [{
                        file: null
                    }],

                }}
                onSubmit={values => {

                    let metafiles1 = values.fileArray
                          const metaFilesFiltered =  metafiles1.filter(e =>  e != null)
                          const metadescArray =  values.metaDescription.filter(e =>  e)
                          let metaDescription = JSON.stringify(metadescArray)


                    let payload = {
                        sellingPrice: values.sellingPrice,
                        price: values.mrp,
                        name: values.productName,
                        description: values.description,
                        image: fileBase64String.replace("data:image/jpeg;base64,", ""),
                        originCountry: values.countryOfOrigin,
                        scannedBarCode: values.barcode,
                        brand: values.productBrand,
                        //metaInfo: values.metaDescription,
                        
                    }

                    const getResult = async () => {
                        
                        const result = await addProductManually(payload, supplierId);
                    
                        if (result.data) {
                            
                            if(metaFilesFiltered[0].file != null){
                                let storeID =null
                                collateralDataUpload(result.data.productId,storeID,metadescArray,metaFilesFiltered)
    
                            }
                            setDialogMessage("Product Successfully Added")
                            setDialogStatusCode('')
                            setDialogIcon('./icons/successicon.svg')
                            setIsSuccess(true)
                            setShowDialog(true)


                            //  navigate("/Products")
                        }
                        if(result.error){
                            
                            setDialogStatusCode(`Status Code=${result.error.statusCode} `)
                            setDialogMessage(`Please check the form inputs, Please make sure to avoid special characters["&^]`)
                           
                            setDialogIcon("./icons/error.png")
                            setIsSuccess(false)
                            
                            setShowDialog(true)
                            

                        }
                    }
                    getResult()
                }}
                validationSchema={validate}>
                {formProps => (

                    <Form>
                        <div className='columns'>
                            <div className='column'>
                                <h1 style={{ ...classes.inputName, marginBottom: "1.4rem", marginTop: "4.1rem" }}>Product Name</h1>
                                <div style={{ paddingBottom: 26 }}>
                                    <INPUT style={{ ...classes.input, height: "5.5rem" }} name='productName' className="input" type="text" />
                                </div>
                                <h1 style={{ ...classes.inputName, marginBottom: "2.4rem" }}>Description</h1>
                                <div style={{ paddingBottom: 26 }}>
                                    <TEXTAREA style={{ ...classes.input, height: "12.3rem" }} name='description' className="textarea has-fixed-size" />
                                </div>
                                <h1 style={{ ...classes.inputName, marginBottom: "2.4rem" }}>M.R.P.</h1>
                                <div style={{ paddingBottom: 26 }}>
                                    <INPUT style={{ ...classes.input, height: "4.2rem" }} name='mrp' className="input" type="text" />
                                </div>
                                <h1 style={{ ...classes.inputName, marginBottom: "2.4rem" }}>Selling Price</h1>
                                <div style={{ paddingBottom: 26 }}>
                                    <INPUT style={{ ...classes.input, height: "4.2rem" }} name='sellingPrice' className="input" type="text" />
                                </div>
                            </div>
                            <div className='column'>
                                <h1 style={{ ...classes.inputName, marginBottom: "1.4rem", marginTop: "4.0rem" }}>Product Brand</h1>
                                <div style={{ paddingBottom: 26 }}>
                                    <INPUT style={{ ...classes.input, height: "5.5rem" }} name='productBrand' className="input" type="text" />
                                </div>
                                <h1 style={{ ...classes.inputName, marginBottom: "1.9rem", marginTop: "0.6rem" }}>Country of Origin</h1>
                                <div style={{ paddingBottom: 26 }}>
                                    <INPUT style={{ ...classes.input, height: "4.2rem" }} name='countryOfOrigin' className="input" type="text" />
                                </div>
                                <h1 style={{ ...classes.inputName, marginBottom: "2.2rem", marginTop: "7.0rem" }}>Barcode</h1>
                                <INPUT style={{ ...classes.input, height: "4.2rem" }} name='barcode' className="input" />
                            </div>
                            <div style={{ marginTop: "4.0rem" }} className='column'>
                                <div className="file">
                                    <label className="file-label">
                                        <div>
                                            <input className="file-input" type="file" name="resume" accept="image/*" onChange={(event) => setUploadedImage(event.target.files)} />
                                        </div>
                                        <a style={classes.editImage}>Edit/Change Image</a>
                                    </label>
                                </div>
                                <div style={classes.imageBox}>
                                    {fileBase64String === "" ?
                                        <img style={classes.img} src={uploadedImage} />
                                        :
                                        <img style={{ height: "37.1rem", width: "37.1rem", objectFit: "contain" }} src={fileBase64String} />
                                    }
                                </div>
                                <button style={{ ...classes.button, marginRight: 28 }} type='submit'>Add products</button>
                                <button onClick={() => navigate('/Products')}
                                    style={{ ...classes.button, background: "#DD242E" }}>
                                    Cancel
                                </button>

                            </div>


                        </div>
                        <h1 style={{fontFamily:'roboto',fontSize:18,marginLeft:isMobile?'4px':'30px', marginBottom: "1.4rem", marginTop: "4.1rem" }}>Add products collaterals or details</h1>
                        <div  style={classes.imageBoxCollateral}>
                                <h1 style={{fontFamily:'roboto', fontSize:16}}>Please click add button</h1>
                                {/* <FontAwesomeIcon icon="fa-solid fa-plus" /> */}
                                <img onClick={addMetaData} style={{height: "3.0rem", width: "3.0rem",cursor:'pointer' }} src={"./icons/addicon.svg"} />
                                

                            </div>
                            <br></br>
                        {/* <button onClick={addMetaData} type='button' style={classes.buttonStyle}>
                            <div style={{ display: 'flex', flexDirection: "row" }}>
                                <h4 style={classes.textStyle}>Please click add button</h4>
                                <img style={{ margin: "1.4rem 0rem 1.9rem 4.9rem", height: "2.5rem", width: "4.5rem" }} src={"./icons/addicon.svg"} />
                            </div>
                        </button> */}
                        <div style={{ ...classes.inputName, marginBottom: "1.4rem", marginTop: "4.1rem" }}>

                            <FieldArray style={classes.inputMeta} name='metaDescription' >
                                {
                                    fieldArrayProps => {
                                        console.log('fieldArrayProps', fieldArrayProps)
                                        const { push, remove, form } = fieldArrayProps
                                        const { values } = form
                                        const { metaDescription } = values
                                        const { metaWebsite } = values
                                        return (
                                            <div  >
                                                {
                                                    metaDescription.map((metaDescription, index) => (
                                                        <div key={index}>
                                                            {metaDescription.description && (
                                                                <div className='column'>

                                                                    <Field style={classes.inputMeta} name={`metaDescription[${index}].description`} readOnly={true} />
                                                                    <a> </a>
                                                                    <Field style={classes.inputMeta} name={`metaDescription[${index}].name`} readOnly={true} />
                                                                    <a>  </a>
                                                                    <img style={{ height: "40px", width: "48px", objectFit: "contain", marginRight: "10px", marginBottom: "-20px" }} src={"./icons/pdf-icon1.svg"} />
                                                                    


                                                                    
                                                                    {/* <a onClick={() => remove(index)} style={{ textDecoration: "underline", color: "black", fontSize: "10px" }}>Delete</a> */}
                                                                    <a onClick={() => remove(index) && formProps.setFieldValue(`fileArray[${index}]`, null ) } style={{ textDecoration: "underline", color: "black", fontSize: "10px" }}>Delete</a>
                                                                    
                                                                </div>
                                                            )}





                                                            {/* MODAL */}

                                                            <div className={`modal ${show ? "is-active" : ""}`}>
                                                                <div className="modal-background"></div>
                                                                <div style={classes.dialogHeader} className="modal-content has-background-white py-5 px-5">
                                                                    <h3 style={classes.dialogHeaderTxt} className='title mb-6'> Add products collaterals or details</h3>
                                                                    <div>

                                                                        <div style={{ display: 'flex', flexDirection: "row" }}>
                                                                            <div className='column'>
                                                                                <h1 style={{ ...classes.inputName, marginBottom: "2.4rem" }}></h1>
                                                                                <div style={{ paddingBottom: 26 }}>


                                                                                    <TEXTAREA style={{ ...classes.input, height: "12.3rem" }} name={`metaDescription[${index}].description`} className="textarea has-fixed-size" type="text" placeholder="Product detail/information" />
                                                                                </div>
                                                                                <h1 style={{ ...classes.inputName, marginBottom: "2.4rem" }}></h1>
                                                                                <div style={{ paddingBottom: 26 }}>
                                                                                    <Field style={{ ...classes.input, height: "4.2rem" }} name={`metaDescription[${index}].name`} className="input" type="text" placeholder="Website URL" />
                                                                                </div>
                                                                                {/* Added for mobile view */}
                                                                                {isMobile && <div className='column'>                                                   
                                                                                
                                                                                <div style={{ marginTop: "2.0rem" }} className='column'>
                                                                                    <div className="file">
                                                                                        <label className="file-label">
                                                                                            <div>
                                                                                                <input
                                                                                                    className="file-input"
                                                                                                    type="file" name={`metaDescription[${index}].file`} accept="image/jpeg,application/pdf,image/png"
                                                                                                    onChange={(event) => formProps.setFieldValue(`fileArray[${index}].file`, event.target.files[0]) && setMetaUploadedImage(event.target.files)}
                                                                                                />
                                                                                            </div>
                                                                                            <a style={classes.editImageMeta}>Upload:</a>

                                                                                        </label>

                                                                                    </div>

                                                                                    <div style={classes.imageBoxMeta}>                                                                                        
                                                                                        {metafileBase64String === "" ?
                                                                                            <img style={classes.imgMeta} src={uploadedMetaImage} />
                                                                                            :
                                                                                            <img style={{ height: "140px", width: "148px", objectFit: "contain" }} src={metafileBase64String} alt={"PDF"}
                                                                                                onError={({ currentTarget }) => {
                                                                                                    currentTarget.onerror = null;
                                                                                                    currentTarget.src = "./icons/pdf-icon.svg";
                                                                                                }} />
                                                                                        }
                                                                                    </div>
                                                                                    <h1 style={{ color: "#000000", fontSize: "12px", fontFamily: "Roboto", marginLeft: "135px", marginTop: "10px" }} >Png/Jpeg/Pdf supported</h1>
                                                                                </div>
                                                                                </div>
                                                                                 }
                                                                                {/* Added for mobile view */}
                                                                                {/* <button type='button' style={{ ...classes.buttonMeta, marginRight: "10px" }}
                                                                                    onClick={() => { push(''); setShow(!show); AddMeta() }}>Add </button> */}
                                                                                    <button type='button' style={{ ...classes.buttonMeta, marginRight: "10px" }}
                                                                                        onClick={() => {  uploadedMetaImage === "./icons/addimageicon.svg"?  toast({
                                                                                            message: "File/Image not Selected!",
                                                                                            type: 'is-dark',
                                                                                            position:"bottom-center",
                                                                                            extraClasses:"tostmsg",
                                                                                          })  : push('') ;  uploadedMetaImage === "./icons/addimageicon.svg"? setShow(true) : AddMeta() ; uploadedMetaImage === "./icons/addimageicon.svg"? setShow(true) : setShow(false)  }}>Add </button>
                                                                                

                                                                                <button type='button' onClick={CancelMetaForm} style={{ ...classes.buttonMeta, background: "#DD242E" }}>Cancel</button>

                                                                            </div>
                                                                            {!isMobile && <div className='column'>                                                   
                                                                                
                                                                                <div style={{ marginTop: "2.0rem" }} className='column'>
                                                                                    <div className="file">
                                                                                        <label className="file-label">
                                                                                            <div>
                                                                                                <input
                                                                                                    className="file-input"
                                                                                                    type="file" name={`metaDescription[${index}].file`} accept="image/jpeg,application/pdf,image/png"
                                                                                                    onChange={(event) => formProps.setFieldValue(`fileArray[${index}].file`, event.target.files[0]) && setMetaUploadedImage(event.target.files)}
                                                                                                />
                                                                                            </div>
                                                                                            <a style={classes.editImageMeta}>Upload:</a>

                                                                                        </label>

                                                                                    </div>

                                                                                    <div style={classes.imageBoxMeta}>                                                                                        
                                                                                        {metafileBase64String === "" ?
                                                                                            <img style={classes.imgMeta} src={uploadedMetaImage} />
                                                                                            :
                                                                                            <img style={{ height: "140px", width: "148px", objectFit: "contain" }} src={metafileBase64String} alt={"PDF"}
                                                                                                onError={({ currentTarget }) => {
                                                                                                    currentTarget.onerror = null;
                                                                                                    currentTarget.src = "./icons/pdf-icon.svg";
                                                                                                }} />
                                                                                        }
                                                                                    </div>
                                                                                    <h1 style={{ color: "#000000", fontSize: "12px", fontFamily: "Roboto", marginLeft:isMobile?'80px':"135px", marginTop: "10px" }} >Png/Jpeg/Pdf supported</h1>
                                                                                </div>
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}


                                            </div>

                                        )

                                    }
                                }

                            </FieldArray>
                        </div>

                    </Form>
                )}
            </Formik>
            <div  className={`modal ${showDialog?"is-active":""}`}>
  <div className="modal-background"></div>
  <div  style={{height:isSuccess === true?250 : 300,width:600, border:'1px solid #000000',borderRadius:'30px'}}className="modal-card">
    <section className="modal-card-body">
        <div style={{display:'flex' , flexDirection:'column', justifyContent:'center', alignItems:'center',margin:"10px 100px "}}>
        <img style={{height:50,width:50,
      //margin:"40px auto 49px auto",
      }} src={DiagIcon}/>
            <h1 style={{fontSize:18,color:'#ff0000'}}>{DiagStatusCode}</h1>           

        </div>
      
      <h1 style={classes.dialogTxt}>{Diagmessage}</h1>
      
      <div style={{display:"flex",margin:"30px 100px "}}>
      <button className='button' style={{...classes.addSelectedBtn}}
       onClick={()=> isSuccess === false? setShowDialog(false) : 
        window.location.reload()}
        >{isSuccess === true? 'Add More Product' : 'ok' }</button>

        
        {isSuccess === true && (
            <button className='button' style={{fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "16px",
            textAlign: "center",
            background: "#FFA500",
            borderRadius: 5,
            justifyContent:'center',
            alignContent:'center',
            margin:'auto',
            color: "#FFFFFF",
            width: 130,
            height: 28,}}
            onClick={()=> navigate("/Products")}
             >Go to All Products</button>

        )}
        



      {/* <button className='button' 
              //style={{...classes.addSelectedBtn,background: "#DD242E",marginLeft:32}} 
              onClick={()=>setShowDialog(false)}>
              Add more products
      </button> */}
      </div>
    </section>
  </div>
  </div>



        </div>
    )
}

export default AddProductForm
