export const emailRegex = new RegExp(/\S+@\S+\.\S+/);

export const mobileRegex = new RegExp(/^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/);

export function isEmpty(obj) {
  return obj // 👈 null and undefined check
      && Object.keys(obj).length === 0
      && Object.getPrototypeOf(obj) === Object.prototype
}

