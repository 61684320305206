// List of API urls used thorughout this application.

import environment from '../env.js'
import { associateDealer } from "./nearshop-api";

//const DEFAULT_SERVER="https://api.staging.nearshop.in/igw/api"
//const BO_SERVER="https://api.staging.nearshop.in/igw/api"
console.log ("env:", environment)
var SERVER_BASE_URL = environment.SERVER_BASE_URL;
var BO_SERVER = environment.BK_OFFICE_SERVER_URL;

//const fakeURL = 'http://203.10.30.1'
 
const urlMap = {
  dashboardAPI:`${SERVER_BASE_URL}/v2/supplier/{supplierId}/dashboard`, 
  productsAPI:`${SERVER_BASE_URL}/v2/supplier/{supplierId}/products`, 
  dealersAPI:`${SERVER_BASE_URL}/v2/supplier/{supplierId}/dealers`, 
  dealerDetailsAPI:`${SERVER_BASE_URL}/v2/supplier/{supplierId}/dealer/{dealerId}/details`,
  addDealerAPI:`${SERVER_BASE_URL}/v2/supplier/{supplierId}/adddealer`,
  addProductAPI:`${SERVER_BASE_URL}/v2/supplier/{supplierId}/addproduct`,
  deleteSupplierProductAPI:`${SERVER_BASE_URL}/v2/supplier/{supplierId}/delproduct/{productId}`,
  getDealerDetailsAPI:`${SERVER_BASE_URL}/v1/store/getStore?access_token=rucxjuj04a&storeId={DealerId}`,
  updateRetailerApi:`${SERVER_BASE_URL}/v2/store/{storeId}/updatewebsitedetails`,
  getProductDetailsAPI:`${SERVER_BASE_URL}/v2/product/{productId}`,
  getDealerProductsAPI:`${SERVER_BASE_URL}/v2/store/{dealerId}/supplier/{supplierId}/products?`,
  categoriesAPI:`${SERVER_BASE_URL}/v2/dkproduct/categories`,
  subcategoriesAPI:`${SERVER_BASE_URL}/v2/dkproduct/category/{catId}/subcategories`,
  updateProductDetailsAPI:`${SERVER_BASE_URL}/v2/supplier/{supplierId}/updateproduct/{productId}`,
  deleteDealerAPI:`${SERVER_BASE_URL}/v2/supplier/{supplierId}/deldealer/{dealerId}`,
  supplierProductsSearch:`${SERVER_BASE_URL}/v2/supplier/{supplierId}/searchproducts`,
  supplierDealersSearch:`${SERVER_BASE_URL}/v2/supplier/{supplierId}/searchdealers`,
  signUp:`${SERVER_BASE_URL}/v1/email/?access_token=rucxjuj04a`,
  catAndsubCatProd:`${SERVER_BASE_URL}/v2/dkproduct/category/{catId}/subcategory/{subCatId}/products`,
  addProducFromDB:`${SERVER_BASE_URL}/v2/dkproduct/supplier/{supplierId}/addproducts`,
  searchBarcode:`${SERVER_BASE_URL}/v2/dkproduct?barcode={barcode}`,
  loginPageURL: `${BO_SERVER}/login`,
  getProductListForAdditionToDealer:`${SERVER_BASE_URL}/v2/supplier/{supplierId}/dealer/{dealerId}/productsnotadded`,
  addSelectedProductToDealer:`${SERVER_BASE_URL}/v2/supplier/{supplierId}/dealer/{dealerId}/addproducts`,
  fileUploadAPI: `${SERVER_BASE_URL}/v2/files`,
  fileUploadStatus: `${SERVER_BASE_URL}/v2/user/{userId}/bulkreport/{reportId}/status`,
  sendInvite:`${SERVER_BASE_URL}/v2/supplier/{supplierId}/dealer/{dealerId}/sendinvite`,
  associate:`${SERVER_BASE_URL}/v2/supplier/{supplierId}/dealer/{dealerId}/associate`,
  collateralDataAPI:`${SERVER_BASE_URL}/v1/product/updateProduct?access_token=rucxjuj04a&storeId={storeId}&productId={productId}`,
  collateralDataAPIADD:`${SERVER_BASE_URL}/v1/product/updateProduct?access_token=rucxjuj04a&productId={productId}`
}

export default function URLMap (key) {
  SERVER_BASE_URL=environment.SERVER_BASE_URL
  BO_SERVER=environment.BK_OFFICE_SERVER_URL

    return urlMap[key];
  }

