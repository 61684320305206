import React from 'react';
import FOOTER from '../footer';

const RetailerCard = ({ retailer, onEdit, onDelete }) => {
  console.log("Retailer",retailer)
  const isMobile = window.innerWidth <= 769;
  return (
    <div className="card is-fullwidth" style={{ marginBottom: '10px',backgroundColor:'#dcdcdc'}}>
      <div className="card-content">
        <div className="columns" style={{display:'flex'}}>
          <div className={isMobile?"column is-3-mobile":"column is-3"} >
            {/* <figure className="image is-32x32"> */}
              <img
                src={retailer.images[0]}
                alt={retailer.name}
                style={{width:'50px',height:'50px',boxShadow:'5%'}}
              />
            {/* </figure> */}
          </div>
          
          <div className={isMobile?"column is-9-mobile":"column is-8"}>
            <h1 >
              {retailer.name}
            </h1>
            <h1  style={{paddingTop:'3%'}}>Address: {decodeURIComponent(retailer.district)}</h1>
           {/* <div className='columns' style={{display:'flex',marginTop:'3%'}}> 
            <div className='column' >
            <h1  >Products: - </h1>
            </div>
            <div className='column'>
            <h1 >Merchant : - </h1>  
            </div>
           </div>   */}
          </div>
        </div>
        {/* <div className="content">
         
          <button className="button is-primary" onClick={() => onEdit(retailer.id)}>
            Edit Retailer
          </button>
          <button className="button is-danger" onClick={() => onDelete(retailer.id)}>
            Delete Retailer
          </button>
        </div> */}
      </div>
      <FOOTER/>
    </div>
  );
};

export default RetailerCard;
