import React from 'react';
import './App.css';
import MENU from './components/menu/menu';
import DASHBOARDSCREEN from './screens/dashboard-screen';
import SIGNUPSCREEN from './screens/signup-screen';
import { QueryClientProvider,QueryClient} from 'react-query'
import { PersistGate } from 'redux-persist/integration/react'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


function App() { 
  const queryClient = new QueryClient()
  return (
    
    <QueryClientProvider client={queryClient}>
    <div >
    <DASHBOARDSCREEN/>
    </div>
    </QueryClientProvider>
  );
}

export default App;
