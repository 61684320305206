import React from 'react';
import HEADER from '../../header' 
import PRODUCTDETAILFORM from '../../../forms/product-detail-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import FOOTER from '../../footer';


function EditProduct(props) {
    const location=useLocation()
   
        
  return (
  <div>  
      <div style={{padding:"0px 4rem"}}>
          <HEADER  title='Products' breadCrumb='>Edit'/>
          <div className='container'>
              <PRODUCTDETAILFORM productId={location.state.productId} dealerId={location.state.dealerId} dealerName={location.state.dealerName}/>
          </div>
      </div>
      <div>
           <FOOTER/> 
      </div>
  </div>
  )
}

export default EditProduct
