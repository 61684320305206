import React from 'react'
import ADDDEALERFORM from '../../forms/adddealer-form'
import HEADER from '../header'
import { toast } from 'bulma-toast'
//import { addDealer } from '../../../lib/nearshop-api'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'

type style={
    button:React.CSSProperties
}

//function ADDDEALER() {
function AddRetailer() {
const classes:style={
    button: {
        width: "135px",
        height: "30px",
        background: "#4D9D27",
        borderRadius: "5px",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "14px",
        textAlign: "center",
        letterSpacing: "0.06em",
        color: "#FFFFFF",
        border:"none",
        cursor:'pointer'
    }
}
function goBack(){
    window.history.go(-1);
}



    return (
        <div style={{padding:"0 4rem"}}>
            
            <HEADER breadCrumb='> Add Retailer' title={"Retailers"} />
            <div className='container'>
            <button className='button ' style={{marginRight:'2%',marginTop:'2%'}}>
                                <span className="icon">
                                    <Link to='/Retailers'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{fontSize:"15px",color:'black'}}/>
                                    </Link>
                                </span>
            </button>
            <ADDDEALERFORM />
            <script src="bulma-toast.min.js"></script>
            <div   style={{paddingBottom:26}}>            
            <button form='add-dealer-form' style={classes.button}  type={'submit'}>Add Retailer</button>
        <button style={{...classes.button,background: "#DD242E",marginLeft:28}} onClick={goBack} >
            Go Back</button>

            </div>
        </div>
        </div>
    )
}

export default AddRetailer
