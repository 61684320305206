import React from 'react';
import Header from '../components/dashboardComponent/header'

function PageNotFound() {
  return (
  <div  style={{padding:"0 4.4rem"}}>
      <Header/>
      <div className='container'>
      <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
      <img style={{width:"280px",height:"280px",objectFit:"contain"}} src={'./icons/not-found.png'}/>
      </div>
      </div>
  </div>
  )
}

export default PageNotFound;
