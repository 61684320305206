import React from 'react'
import {useSelector} from 'react-redux'
import ADDPRODANDDEALER from '../../addprodNdealer/addProdAndDealer'
//import DEALERINFOFORM from '../../forms/dealer-info-form'
import SEARCHBOX from '../../searchbox/searchbox'
import HEADER from '../header'
//import DEALERSLIST from './dealers-list'
import { Link, useParams } from 'react-router-dom'
import { dealers, searchRetailers4Supplier } from '../../../lib/nearshop-api';
import DataTable from '../../utils/data-table'
import DEALERCARD from './retailercard'
import {Button} from 'react-bulma-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from "react-router-dom";
import {dashboard} from '../../../lib/nearshop-api';
import { useQuery} from 'react-query'

type style={
    searchTxt:React.CSSProperties
}

function DEALERS( ) {
   const classes:style={
       searchTxt:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "300",
        fontSize: "10px",
        lineHeight: "1.6rem",
        letterSpacing: "0.06em"
       },
     }

   const navigate = useNavigate()

    const dropDownItems = [
        {
            text: "Add manually",
            link:"/Retailers/AddRetailer"
        },
        {
            text:"Bulk upload of Retailers",
            link:"/Retailers/AddRetailer/bulkUpload"
        }
    ]
    
    const editRetailer = (retailerId:string) => {
      console.log('edit retailer', retailerId)
      navigate("/Retailers/manage",{state:retailerId})
     }

    const deleteRetailer = (retailerId:string) => {
      console.log('delete retailer', retailerId)
    }

    const supplierId = useSelector((state => state.reducer.supplierId))
    const {searchtoken}=useParams()
    
  const {isLoading,data,error}=useQuery(['DashboardContent',supplierId],dashboard) 
  var imgSrc = data?.supplier?.images?.[0]
  if (imgSrc) imgSrc = imgSrc.replace(/^/,'https://storage.googleapis.com/nearshop_live/')
    
    
  var imgSrc = data?.supplier?.images?.[0]
  if (imgSrc) imgSrc = imgSrc.replace(/^/,'https://storage.googleapis.com/nearshop_live/')
  if (imgSrc) imgSrc = imgSrc.replace('/nearshop_live/', '/nslive/')
  console.log("Data",data?.supplier.dealers)
  const isMobile = window.innerWidth <= 769;
   return (
     <div style={{paddingLeft:"15px",paddingRight:'15px',width:'98%' }}>
        <HEADER breadCrumb='' title={"Retailers"} />
          <div className='container'>
            <div className='columns' style={{display:'flex'}}>
              <div className={isMobile?'column is-6-mobile':'column is-9'}>
                <SEARCHBOX placeholder='Search Retailers' />
              </div>
              <div className={isMobile?'column is-2-mobile':'column is-3'}>
                <ADDPRODANDDEALER dealerId={null} dropDownItems={dropDownItems} buttonTxt='Add Retailer' />
              </div>
            </div>
            {searchtoken !== undefined ?
              <h1 style={classes.searchTxt}>{`Results matching '${searchtoken}'`}</h1> : null
            }
            <div className="card is-fullwidth" style={{ marginBottom: '10px',backgroundColor:'white',display:'flex'}}>
             <div className='column is-1-mobile'>
                  <button className='button is-light'>
                    <span className="icon">
                      <Link to='/'>
                      <FontAwesomeIcon icon={faArrowLeft} style={{fontSize:"15px",color:'black'}} />
                      </Link>
                    </span>
                  </button>
            </div>
            <div className='column is-6-mobile'>
              <h1 className="title is-3">Retailers</h1>
            </div>
            </div>
            <div className="columns" style={{display:'inline'}}>
              {data?.supplier.dealers.map((retailer:any) => (
                <div className="column is-12" key={retailer.id} onClick={()=>editRetailer(retailer.id)}>
                  <DEALERCARD
                    retailer={retailer}
                    onEdit={editRetailer}
                    onDelete={deleteRetailer}
                  />
                </div>
              ))}
            </div>
          </div>
    </div>
    )
}

export default DEALERS




