import reducer from "./reducer";
import { combineReducers , createStore} from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const rootReducer =combineReducers({
    reducer
})
const persistConfig = {
    key: 'root',
    storage,
  }
   
 

  const persistedReducer = persistReducer(persistConfig, rootReducer)

const store= createStore(persistedReducer)
store._persistor = persistStore(store)
    
export default store