import React from 'react'
import { PieChart } from 'react-minimal-pie-chart';
import { useNavigate } from "react-router-dom";


type propType={
    totalDealers:number
    activeDealers:number
}


type style={
  outerBox:React.CSSProperties
  titleBox:React.CSSProperties
  title:React.CSSProperties
  donutTxt:React.CSSProperties
  btn:React.CSSProperties
}

export default function ACTIVEDEALERS({totalDealers,activeDealers}:propType) {
const isMobile = window.innerWidth <= 768;    
const classes:style={
        outerBox:{
            width: "100%",
            height: "352px",
            padding:'5px',
            background: "#4391ff",
            border: "1px solid #DFDFDF",
            boxSizing: "border-box",
            borderRadius: "10px", marginTop:isMobile?10:119,marginLeft:isMobile?0:0
        },
        titleBox:{
            width:isMobile?"100%":"100%",
            height: isMobile?"30px":"51px",
            // marginTop:isMobile?"5px":"0px",
            // margin:isMobile?"2px":"0px",
            background: "#4391ff",
            border: "1px solid #DFDFDF",
            boxSizing: "border-box",
            borderRadius: "10px"
        },
        title:{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "1.8rem",
            lineHeight: "21px",
            letterSpacing: "0.06em",
            color: "#FFFFFF",marginTop:isMobile?5:17,marginLeft:16
        },
        donutTxt:{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "2.0rem",
            lineHeight: "23px",
            textAlign: "center",
            letterSpacing: "0.06em",
            position:"relative",top:110,left:'auto',
            color: "#FFFFFF",
        },
        btn:{
            width: "112.22px",
            height: "24.94px",
            background: "#4391ff",
            borderRadius: "0.5rem",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "14px",
            textAlign: "center",
            letterSpacing: "0.06em",
            color: "#FFFFFF",
        }
    }
    let navigate = useNavigate();
    return (
        <div style={classes.outerBox}>
            <div style={classes.titleBox}>
             <h2 style={classes.title}>Active Retailers</h2>
            </div>
            <h1 style={classes.donutTxt}>{`${activeDealers} out of ${totalDealers}`}</h1>
            <div style={{width:"32.5rem",height:245,margin:'auto' }}>
            <PieChart
            radius={40}
            lineWidth={30}
            startAngle={270}
            labelPosition={50}
            style={{position:"relative"}}
            center={[50,40]}
  data={[
    {  value: activeDealers, color: '#57B137',style:{strokeWidth:10} },
    {  value: totalDealers-activeDealers, color: '#ffffff',style:{strokeWidth:19} },
    
  ]}
>
</PieChart>
</div>
<div style={{display:"flex",placeContent: "center"}}>
<button onClick={() => navigate(`/Retailers`)} style={classes.btn} className='button'>Manage Retailers</button>
</div>
        </div>
    )
}
