import React, { useEffect, useState,useMemo } from 'react'
import {useQuery} from 'react-query'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {Button} from 'react-bulma-components'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'
import ADDPRODANDDEALER from '../../addprodNdealer/addProdAndDealer'
import PRODUCTDETAILFORM from '../../forms/product-detail-form'
import SEARCHBOX from '../../searchbox/searchbox'
import HEADER from '../header' 
//import PRODUCTLIST from './product-list'
import DataTable from '../../utils/data-table'
import { product,deleteSupplierProduct, getDealerProductsV2 } from '../../../lib/nearshop-api'
import { useSelector , RootStateOrAny} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import Dialog from '../../dialogs/dialog'
import { supplierProductsSearch } from '../../../lib/search-api'
//import PRODUCTADDEDCARD from './productAddedCard';
import {dashboard} from '../../../lib/nearshop-api'
import {useTable, useBlockLayout} from 'react-table'
import ORDERLISTCARD from './orderListCard';
import environment from '../../../env.js'
import FOOTER from '../footer';

type style={ searchTxt:React.CSSProperties }
const ASSIGN_CONFIRM_TEXT = `Do you want to delete this Product?`
var SERVER_BASE_URL = environment.SERVER_BASE_URL;
function ORDERLISTPAGE() {
    const classes:style={
        searchTxt:{
         fontFamily: "Roboto",
         fontStyle: "normal",
         fontWeight: "300",
         fontSize: "10px",
         lineHeight: "1.6rem",
         letterSpacing: "0.06em"
        }
    }
    const navigate = useNavigate();
    const dropDownItems = [
        {
            text: "Add from Product Database",
            link:"/Products/addProduct/Categories"
        },
        {
            text: "Search product barcode",
            link:"/Products/addProduct/scanBarcode"
        },
        {
            text: "Add manually",
            link:"/Products/addProduct/manually"
      
        },
        {
            text:"Bulk upload of products",
            link:"/Products/addProduct/bulkUpload"
        }
    ]
  const supplierId= useSelector((state:RootStateOrAny)=>state.reducer.supplierId)
  const location=useLocation()
  let state:any=location.state
  console.log("state",state)
  const [orderInfo , setOrderInfo] = React.useState(state)
  const [filteredData , setFilteredData] = React.useState(state?.filteredData)
  const [filterShow, setFilterShow] = React.useState(state?.filterShow);
  const [listData,setListData] = React.useState([]) 
  //const supplierId = "cc9cfc30-5c01-11ec-bf63-0242ac130002"
  useEffect(() => {
    if(filteredData)
    {
    sessionStorage.setItem("SeperatedFilteredData",JSON.stringify(filteredData))
    }
   }, [filteredData]); 
  useEffect(() => {
       fetchData()
  },[]) 
  useEffect(() => {
    //sessionStorage.setItem("SeperatedFilteredData",filteredData)
    
    const stringData = sessionStorage.getItem("SeperatedFilteredData")
    setFilterShow(sessionStorage.getItem("ShowFilter"))  
    console.log("StringData",stringData)
    if (stringData) {
      try {
        const parsedData = JSON.parse(stringData);
        if (Array.isArray(parsedData)) {
          setFilteredData(parsedData);
        }
        else{
          setFilteredData(stringData)
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
    
  }, []); 
 

  //console.log("OrderInfo",orderInfo)
    const [isLoading,setIsLoading] = React.useState(false) 

    const fetchData = async () => {
       try {
        let pageStatus = orderInfo?.orderStatus?orderInfo.orderStatus:sessionStorage.getItem("Status") ;
        setIsLoading(true);
    
        if (pageStatus === "Confirmed") {
          sessionStorage.setItem("Status", "Confirmed");
          const orderType = 'Confirmed';
          let results = await fetch(`${SERVER_BASE_URL}/v2/supplier/${supplierId}/getOrders/${orderType}`);
          const data = await results.json();
    
          // Sort the data based on createdAt in descending order
          const sortedData: any = data.allOrders.sort((a: any, b: any) => {
            const dateA = new Date(a.createdAt.formatted);
            const dateB = new Date(b.createdAt.formatted);
            return dateB.getTime() - dateA.getTime();
          });
          setListData(sortedData);
        }
        
        else if (pageStatus === "Pending") 
        {
          sessionStorage.setItem("Status", "Pending");
          const orderTypes = ['Out_For_Delivery', 'Order_Picked_Up', 'Confirmed'];
          let [outForDeliveryRes, pickedUpRes, confirmedRes] = await Promise.all(
            orderTypes.map(async (orderType) => {
              const response = await fetch(`${SERVER_BASE_URL}/v2/supplier/${supplierId}/getOrders/${orderType}`);
              return response.json();
            })
          );
    
          // Combine orders from different types and sort based on createdAt
          const pendingList = [...outForDeliveryRes.allOrders, ...pickedUpRes.allOrders, ...confirmedRes.allOrders];
          const sortedPendingList: any = pendingList.sort((a: any, b: any) => {
            const dateA = new Date(a.createdAt.formatted);
            const dateB = new Date(b.createdAt.formatted);
            return dateB.getTime() - dateA.getTime();
          });
          setListData(sortedPendingList);
        } 
        
        else if (pageStatus === "Cancelled") 
        {
          sessionStorage.setItem("Status", "Cancelled");
          const orderTypes = ['Customer_Cancelled', 'Merchant_Cancelled'];
          const [merchantCancelledRes, customerCancelledRes] = await Promise.all(
            orderTypes.map(async (orderType) => {
              const response = await fetch(`${SERVER_BASE_URL}/v2/supplier/${supplierId}/getOrders/${orderType}`);
              return response.json();
            })
          );
          //console.log("merchantCancelledRes",merchantCancelledRes,customerCancelledRes)
          // Combine orders from different types and sort based on createdAt
          const cancelledList = [...merchantCancelledRes.allOrders, ...customerCancelledRes.allOrders];
          //console.log("CancelledList",cancelledList)
          const sortedCancelledList: any = cancelledList.sort((a: any, b: any) => {
            const dateA = new Date(a.createdAt.formatted);
            const dateB = new Date(b.createdAt.formatted);
            return dateB.getTime() - dateA.getTime();
          }); 
          setListData(sortedCancelledList);
        } 
        
        else if (pageStatus === "Returned") 
        {
          sessionStorage.setItem("Status", "Returned");
          const orderType = 'Returned';
          let results = await fetch(`${SERVER_BASE_URL}/v2/supplier/${supplierId}/getOrders/${orderType}`);
          const data = await results.json();
    
          // Sort the data based on createdAt in descending order
         
          const sortedData: any = data.allOrders.sort((a: any, b: any) => {
            const dateA = new Date(a.createdAt.formatted);
            const dateB = new Date(b.createdAt.formatted);
            return dateB.getTime() - dateA.getTime();
          }); 
          setListData(sortedData);
        }
        else if (pageStatus === "Delivered") 
        {
          sessionStorage.setItem("Status", "Delivered");
          const orderType = 'Delivered';
          let results = await fetch(`${SERVER_BASE_URL}/v2/supplier/${supplierId}/getOrders/${orderType}`);
          const data = await results.json();
    
          // Sort the data based on createdAt in descending order
         
          const sortedData: any = data.allOrders.sort((a: any, b: any) => {
            const dateA = new Date(a.createdAt.formatted);
            const dateB = new Date(b.createdAt.formatted);
            return dateB.getTime() - dateA.getTime();
          }); 
          setListData(sortedData);
        }
    
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    };
    

   console.log("listData",listData?.length)
  // console.log("Boolean",listData?.length !== 0 , (!filteredData  || filteredData?.length == 0) )
  console.log("filteredList",filteredData?.length)
  // console.log("orderList",filterShow)
  const isMobile = window.innerWidth <= 769;
  return (
    <div>
        <div  className="Products"style={{padding:"0px 4rem",width:'98%'}}>
            <HEADER title='Orders'/>
            <div className='container'>
             <div className="card is-fullwidth"  style={{ marginBottom: '10px',backgroundColor:'white',display:'flex'}}>
             <div className='column is-1-mobile'>
                  <button className='button is-light' onClick={()=>(sessionStorage.setItem("Status"," "),sessionStorage.setItem('SeperatedFilteredData',''))}>
                    <span className="icon">
                      <Link to='/OrdersCard'>
                      <FontAwesomeIcon icon={faArrowLeft} style={{fontSize:"15px",color:'black'}} />
                      </Link>
                    </span>
                  </button>
            </div>
            <div className='column is-6-mobile'>
              <h1 style={{fontSize:'2rem',fontWeight:'500'}}>Orders {orderInfo?.orderStatus}</h1>
            </div>
            </div>
          {listData?.length !== 0 && (!filteredData  || filteredData?.length == 0) && !filterShow &&
          (<div className="columns is-multiline">
          {listData?.map((order) => (
            <div className="column is-6" key={order.id} >
             <ORDERLISTCARD
                order={order}
              />
              
            </div>
          ))}
          
          </div>)
           }
          {filteredData?.length !== 0 && filterShow == "1" &&
          (<div className="columns is-multiline">
          {filteredData?.map((order) => (
            <div className="column is-6" key={order.id}>
             <ORDERLISTCARD
                order={order}
              />
            </div>
          ))}
          </div>)
          }
          {listData?.length == 0 &&  (!filteredData  || filteredData?.length == 0) && !filterShow  &&
          (
            <div>
            { isLoading ? (
              <h1 className='is-title-2' style={{padding:'35%',fontSize:'4rem',fontWeight:'400'}}>Loading.....</h1>
              ):
          
            (<h1 className='is-title-2' style={{padding:'35%',fontSize:'4rem',fontWeight:'400'}}>No Data</h1>)
            }
            </div>
            )
          } 
          {filteredData?.length == 0 && filterShow == '1' && 
          (
            <div>
            {        
            (<h1 className='is-title-2' style={{padding:'35%',fontSize:'4rem',fontWeight:'400'}}>No Data</h1>)
            }
            </div>
            )
          }
           
          </div>
        </div>  
        <div>
          <FOOTER/>
        </div>
    </div>    
          
)}
export default ORDERLISTPAGE

