import React, {useState, useEffect} from 'react'
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { searchBarcode } from '../../../../../lib/search-api';
import SEARCHBOX from '../../../../searchbox/searchbox';
import HEADER from '../../../header'
import AddProductCard from '../addproductcard';
import Dialog from '../scanbarcode/dialog'
import {addProducFromDB} from '../../../../../lib/nearshop-api';
import { useSelector } from 'react-redux';
import { toast } from 'bulma-toast'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'

function Searchbarcode() {
  const isMobile = window.innerWidth <= 769;
    const classes ={
        btn:{
            background: "#DD242E",  
            borderRadius: "5px",
            width: "249px",
            height: "43px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontSize: "15px",
            lineHeight: "18px",
            letterSpacing: "0.06em",
            color: "#FFFFFF"
        },
        ProductBox:{
            background: "#FFFFFF",
            border: "1px solid #E5E5E5",
            boxSizing: "border-box",
            borderRadius: "5px",
            height:"80vh",
            width:isMobile?325: 1170,
            overflowY: "scroll"
        },
        scanTxt:{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "16px",
            letterSpacing: "0.06em",
            textDecorationLine: "underline",
            marginTop:24,
            marginLeft:15
        },
        selectAllBtn:{
            width: 89,height: 31,
            border: "1px solid #DD242E",
            boxSizing: "border-box",
            borderRadius: 5,
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: 10,
            lineHeight: "12px",
            letterSpacing: "0.06em",
            color: "#000000",
            marginTop:15,
            marginRight:42
        },
        buttomButtonBox:{
            background: "#FFFFFF",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            width:isMobile?325: 1170,
            height: 71,
            marginBottom:44,
        },
        addSelectedBtn:{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "16px",
            textAlign: "center",
            background: "#409D3E",
            borderRadius: 5,
            color: "#FFFFFF",
            width: 130,
            height: 28,
            marginTop:22
        },
        dialogTxt:{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: 24,
            textAlign: "center",
            letterSpacing: "0.06em",
            color: "#000000",
        }
    }


    let navigateBack = useNavigate()
    const navigate=useNavigate()
    const location =useLocation()
    const [submitError, setSubmitError] = useState("")
    const [showDialog,setShowDialog]=useState(false)
    const [selectAll,setSelectAll]=useState(false) 
    const [isErrorMrp,setIsErrorMrp] =useState(false)

    const {isLoading,data,error}=useQuery(["search",location.state],searchBarcode)
    const [selectedProducts,setSelectedProducts]=useState([])
    const [AllProducts,setAllProducts]=useState([])
    const [products,setproducts]=useState([])
    const [count,setcount]=React.useState(0)
    const supplierId= useSelector((state=>state.reducer.supplierId))
    const ASSIGN_CONFIRM_TEXT = `Product Unavailable`
  let [scannedProduct,setScannedProduct]=React.useState([])
  let [allProducts,setallProducts]=useState([])
  const [isChecked,setIsChecked]=React.useState(false)


  useEffect(()=>{
    if(isLoading===false)
    { 
          if(data?.error?.statusCode===404){              
      setSubmitError(ASSIGN_CONFIRM_TEXT)              
      console.log("not found")
    }
        else{
        let newScannedProduct=scannedProduct.concat(data)
        setScannedProduct(newScannedProduct)
        }
        
    }
},[isLoading,data])

    const transformToArray=(obj)=>{
        let keys=Object.keys(obj)
       let newArray=keys.map((id)=>{
          return obj[id]
        })
        
        return newArray
    }

    const routeChange = () =>{ 
        let path = `/products`; 
        navigateBack(path,{replace:true});
      }
      
      const handleChange=(e)=>{
        const {name,checked}=e.target
        
      }

      const handleScanner = () => {
        alert("This functionality requied scanner device to be attached and configured.")
      }

      const abortAddProduct = () => {
        setSubmitError("")
        navigate("/Products/addProduct/scanBarcode",{replace:false})
      }
      const addSelectedItem=(selectedProduct)=>{ 

        
        const newProducts=selectedProducts.concat(selectedProduct)
        setSelectedProducts(newProducts)
        let products = selectedProducts
          //setSelectedProducts({...selectedProducts,[selectedProduct]:selectedProduct})
          setcount(count + 1)
          console.log("innner ",selectedProducts)
      }


      const removeUnselectedItem =(unselectedProductId)=>{
        setcount(count - 1)
        let newObject= JSON.parse(JSON.stringify(selectedProducts))
        //    let keys=Object.keys(newObject)
        //    let matchingKey=keys.filter(k=>k===unselectedProductId)
            delete newObject[unselectedProductId]
           
            setSelectedProducts(newObject)
        //setSelectedProducts("")
      }

      const updateSelectedProductmrp=(product)=>{
      
        let storedproduct = selectedProducts
        let product1 = product[0]
        let productid = product.id

        for( var i=0;i<selectedProducts.length;i++){
          if(storedproduct[i].id === product.id){

            let index = selectedProducts.indexOf(product.id)

          storedproduct[i].price = product.price
          setSelectedProducts(storedproduct)

          }
        }

       

      }

      const updateSelectedProductsp=(product)=>{
        
        let storedproduct = selectedProducts
        let product1 = product[0]
        let productid = product.id

        for( var i=0;i<selectedProducts.length;i++){
          if(storedproduct[i].id === product.id){

            let index = selectedProducts.indexOf(product.id)

          storedproduct[i].sellingPrice = product.sellingPrice
          setSelectedProducts(storedproduct)

          }
        }

      }

      function getUnique(array, key) {
        if (typeof key !== 'function') {
          const property = key;
          key = function(item) { return item[property]; };
        }
        return Array.from(array.reduce(function(map, item) {
          const k = key(item);
          if (!map.has(k)) map.set(k, item);
          return map;
        }, new Map()).values());
      }


      const addAllProducts=(e)=>{
        setSelectAll(!selectAll)
        setIsChecked( e.target.checked)    
        if(selectAll===false){
          if(selectedProducts.length>0){            
            for(var i=0;i<selectedProducts.length;i++){  
              for(var j=0;j<scannedProduct.length;j++){
                let id1 = selectedProducts[i].id
                let id2 = scannedProduct[j].id
              if(id1 !== id2 ){
                let product = scannedProduct[i]
                const productToAdd = ({
                  price:product.price? product.price : "" ,
              id:product.id,
              sellingPrice:product.onSalePrice === null? "" : product.onSalePrice
                })
                selectedProducts.push(productToAdd)
                const selectedProductsFiltered = getUnique(selectedProducts, 'id')
        setSelectedProducts(selectedProductsFiltered)
              }}}}            
          else{
            let tempProductlist = scannedProduct.map((item)=>({
              price:item.price || "" ,
              id:item.id,
              sellingPrice:item.onSalePrice || ""
            }))
            setSelectedProducts(tempProductlist)
            setcount(tempProductlist.length)
          }}
          if(selectAll===true){
            setSelectedProducts("")
            setcount(0)
          }}

      const handleSubmit=()=>{
        
           
        //const payload=transformToArray(selectedProducts)
        const payload = selectedProducts
        if(payload){
          let isError = ''
          
          
    
          for(var i=0 ; i<payload.length ; i++){
            if (payload[i].price === ''){
              //setIsErrorMrp(true)              
              isError = 'true'              
            } 
            else{
              isError = 'false'
              //setIsErrorMrp(false)    
            }   
          }
           
        console.log(payload)
        if(!payload.length){
          toast({
            message: "Product Not Selected!",
            type: 'is-black',
            position:"bottom-center",
            extraClasses:"tostmsg"
          })
        }
        else if(isError === 'true'){
          toast({
            message: "Mrp Is Mandatory!",
            type: 'is-black',
            position:"bottom-center",
            extraClasses:"tostmsg"
          })
          
        }
        else{
        addProducFromDB({data:payload},supplierId)
        setShowDialog(true)
        }
      }
        //window.location.reload()
    }
  return (
    <div style={{padding:"0px 4rem"}}>
      
  <HEADER breadCrumb='> Add products from barcode' title='Products  '/>
  <button className='button ' style={{marginRight:'2%',marginTop:'2%'}}>
                                <span className="icon">
                                    <Link to='/Products'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{fontSize:"15px",color:'black'}}/>
                                    </Link>
                                </span>
      </button>
  <div className='container'>
  <div className='columns '>
  <div className='column' >  
  <SEARCHBOX  placeholder={"Enter 13 digit product barcode number here"}/>
  </div>  
  <div className='column'>  
  {/* <button style={classes.btn} className='button' onClick={handleScanner}>Scan product barcode</button> */}
  </div> 
  </div>
  <div style={classes.ProductBox}>
  <div style={{display:"flex"}}>
  <h1 style={classes.scanTxt}>Search/Scan result:</h1>
  <div style={{display:"flex",flexDirection:"row-reverse",flexGrow:1}}>
    {scannedProduct.length>0 && (
       <button className='button' type='checkbox' style={classes.selectAllBtn} onClick={addAllProducts} >{selectAll?"UnSelectALL":"Select all"}</button>
    )
    }
  </div>
  </div>
  {scannedProduct.length===0?null:
      scannedProduct.map((product,index)=>{
          return   <AddProductCard key={index} addSelectedItem={addSelectedItem} product={product}  selectAll={selectAll} handleChange={handleChange} 
          removeUnselectedItem={removeUnselectedItem} setSelectAll={setSelectAll} updateSelectedProductmrp={updateSelectedProductmrp}
           updateSelectedProductsp={updateSelectedProductsp} />

      })
  }
  {submitError.length>0
            ? <Dialog message={submitError} 
                 title="Info"
                  noBtn='Cancel'
                  onReject={abortAddProduct}   
                  visible={true}
                  setVisible={()=>setSubmitError("")}
              />
            : null}
  </div>

  <div style={classes.buttomButtonBox}>
  <div style={{display:"flex",flexDirection:"row-reverse"}}>
  <button className='button' style={{...classes.addSelectedBtn,background: "#DD242E",marginRight:isMobile?27:32}}onClick={routeChange}>Cancel</button>
  <button className='button' style={{...classes.addSelectedBtn,marginRight:isMobile?10:46}} type='submit' 
  onClick={handleSubmit}
  >
      Add selected
  </button>
  </div>
  </div>
  <div  className={`modal ${showDialog?"is-active":""}`}>
  <div className="modal-background"></div>
  <div  style={{height:365,width:600}}className="modal-card">
    <section className="modal-card-body">
      <img style={{height:63,width:"100%",margin:"40px auto 49px auto",}} src="./icons/successicon.svg"/>
      <h1 style={classes.dialogTxt}>Products added sucessfully</h1>
      <div style={{display:"flex",margin:"40px 140px "}}>
      <button className='button' style={{...classes.addSelectedBtn}} onClick={()=>navigate('/Products')} >Go to product page</button>
      <button className='button' 
              style={{...classes.addSelectedBtn,background: "#DD242E",marginLeft:32}} 
              onClick={()=>setShowDialog(false)}>
              Add more products
      </button>
      </div>
    </section>
  </div>
  </div>
  </div>  
  </div>
  
  )
}

export default Searchbarcode