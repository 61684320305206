import React from 'react';
import {Formik, Form, Field} from 'formik' 
import * as Yup from 'yup'
import INPUT from '../../../forms/forminput/input';
import { toast } from 'bulma-toast';

type style={
    cardBox:React.CSSProperties
    prdName:React.CSSProperties
    addBtn:React.CSSProperties
}

type propType ={
    addButton:Boolean
    handleChange:any
    selectAll:boolean
    product:any
    addSelectedItem:any
    removeUnselectedItem:any
    setSelectAll:any
    updateSelectedProductmrp:any
    updateSelectedProductsp:any
}

function AddProductCard({updateSelectedProductmrp,updateSelectedProductsp,selectAll,addButton,handleChange,product,addSelectedItem,removeUnselectedItem,setSelectAll}:propType) {
const classes:style={
    cardBox:{
        width: 1150,
        height:111,
        borderBottom: "1px solid #DADADA",
        display:"flex"
    },
    prdName:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "0.06em",
        color: "#000000",
        alignSelf: "center",
        width:300
    },
    addBtn:{
        width: 89,height: 31,
        border: "1px solid #DD242E",
        boxSizing: "border-box",
        borderRadius: 5,
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: 10,
        lineHeight: "12px",
        letterSpacing: "0.06em",
        color: "#000000",
        marginTop:40,
        marginLeft:121
    }
}

const [isChecked,setIsChecked]=React.useState(selectAll)
const [mrp,setMrp]=React.useState(product.price)
const [sellingPrice,setSellingPrice]=React.useState(product.onSalePrice)

React.useEffect(()=>{
    setIsChecked(selectAll)

    // if(selectAll === true){
    //     debugger
    //     addSelectedItem({
    //         price:mrp,
    //         id:product.id,
    //         sellingPrice:sellingPrice
    //     })
        

    // }
    // else if(selectAll === false){        
    //     removeUnselectedItem(product.id)
    // }
},[selectAll])

// if(isChecked === true){
//     debugger
//     addSelectedItem({
//         price:mrp,
//         id:product.id,
//         sellingPrice:sellingPrice
//     })
    

// }

  const handlecheckbox=(e:any)=>{

    setIsChecked( e.target.checked)
    
   if(e.target.checked===false){

    }
 
    if(e.target.checked===true){
        addSelectedItem({
            price:mrp === null? "" : mrp,
            id:product.id,
            sellingPrice:sellingPrice === null? "" : sellingPrice
        })
    }
    else{
        removeUnselectedItem(product.id)
    }
  }

  const handleMrpChange=(e:any)=>{
    if(isChecked){
        // updateSelectedProduct({[product.id]:{
        //     price:e.target.value,
        //     id:product.id,
        //     sellingPrice:sellingPrice
        // }})

        updateSelectedProductmrp({
            price:e.target.value,
            id:product.id,
            sellingPrice:sellingPrice
        })
    } 
    setMrp(e.target.value)
  }

  const handleSPChange=(e:any)=>{
    let price1 =product.onSalePrice
    let price2 =product.sellingPrice
    

    
    
   // else{
        //setSellingPrice(e.target.value)
        if(isChecked){

            if(parseInt(e.target.value)>parseInt(mrp)){
            
                toast({
                    message:'Selling price cannot be greater than Mrp',
                    type: 'is-danger',
                    position:"bottom-center",
                    extraClasses:"tostmsg"
                })
                setSellingPrice("")
            }
            else{            
        
            updateSelectedProductsp({
                price:mrp,
                id:product.id,
                sellingPrice:e.target.value
        })
        setSellingPrice(e.target.value)
    }
        }
        else{
            if(parseInt(e.target.value)>parseInt(mrp)){
            
                toast({
                    message:'Selling price cannot be greater than Mrp',
                    type: 'is-danger',
                    position:"bottom-center",
                    extraClasses:"tostmsg"
                })
                setSellingPrice("")
            }
            else{
                setSellingPrice(e.target.value)

            }
            
        }
    //}
}
  return (
    
    <div style={classes.cardBox}>
    
    <input style={{marginTop:44,marginLeft:18,verticalAlign: "top"}} type="checkbox"   checked={isChecked} onChange={handlecheckbox}/>
    <img style={{height:60,width:60,marginTop:25,marginLeft:11,marginRight:74}} src={product.imageUrl}/>
    <h1 style={classes.prdName}>{product.name}</h1>
    <h1 style={{...classes.prdName,width:184}}>{`Qty:${product.weight}${product.weightUnit}`}</h1>
    <div style={{display:"flex",flexDirection:"column",marginRight:91}}>
        <h1 style={{...classes.prdName,marginTop:30,width:89,textAlign: "center"}}>M.R.P</h1>
        <input disabled={isChecked === false}  name="mrp"  value={mrp} style={{height:29,width:89,marginTop:14}} onChange={handleMrpChange}/>
    </div>
    <div style={{display:"flex",flexDirection:"column"}}>
        <h1 style={{...classes.prdName,marginTop:30,width:89}}>Selling price</h1>
        <input  name="sellingPrice" style={{height:29,width:89,marginTop:14}} value={sellingPrice} disabled={isChecked === false || mrp===""} onChange={handleSPChange}/>
    </div>
    {/* {addButton?
    <button style={classes.addBtn} className='button'>Add</button>:null} */}
    </div>
  )
}


export default AddProductCard;