import React from 'react'
import { useDispatch } from 'react-redux'


type style = {
    header: React.CSSProperties
    seperator: React.CSSProperties
    button: React.CSSProperties

}

type propsType = {
    title: string
    breadCrumb: string
}
export default function DASHBOARDHEADER({ title, breadCrumb }: propsType) {
    const classes: style = {
        header: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "800",
            fontSize: "3rem",
            lineHeight: "16px",
            letterSpacing: "0.06em",

        },
        seperator: {
            border: "0.5px solid #E7E7E7",
            width: "100%"
        },
        button: {
            width: "50px", height: "25px", background: "#DD242E", color: "#FFFFFF", border: "none", borderRadius: "0.5rem",
            cursor: "pointer",fontSize:"12px"
        },
    }
    const [show,setShow]=React.useState(false)
    const toggleDropDown=()=>{
        setShow(!show)
    }

    const dispatch = useDispatch()
    return (
        <div style={{ paddingTop: 30, background: "white" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div onClick={() => { dispatch({ type: "showMenu" }) }} className='hamberger-menu'>
                    <img style={{ paddingRight: 12 }} src={'./icons/menu.svg'} />
                </div>
                <div style={{ display: "flex" }}>
                    <h1 style={classes.header}>{title}</h1>
                    <h1 className="breadCrumb" style={classes.header}>{breadCrumb}</h1>
                </div>
                <div style={{ display: "flex", flexDirection: "row-reverse", flexGrow: 1 }}>
                    <div className={`dropdown  is-right ${show?"is-active":""}`} >
                        <div className="dropdown-trigger">
                            <img  onClick={toggleDropDown}  height={25} width={23} style={{ marginLeft: 30, marginRight: 26 }} src={"/icons/accounticon.svg"} />
                        </div>
                        <div className="dropdown-menu" id="dropdown-menu" role="menu">
                            <div className="dropdown-content">
                                <h1 style={{...classes.header,textAlign:"center",cursor:"default",fontSize:12}}>Distributor</h1>
                                <div style={{display:"flex",flexDirection:"row-reverse",marginTop:"10px"}}>
                                <button
                                    onClick={() => dispatch({ type: "setLoginStatus", isLoggedin: false })}
                                    className='button'
                                    style={classes.button}>
                                Log Out
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <img height={20} width={21} style={{ marginTop: "3px" }} src={"./icons/notificationicon.svg"} /> */}
                </div>
            </div>
            <hr style={classes.seperator} />
        </div>
    )
}
