import React, { useEffect, useState } from 'react'
import {useQuery} from 'react-query'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Button} from 'react-bulma-components'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import ADDPRODANDDEALER from '../../addprodNdealer/addProdAndDealer'
import PRODUCTDETAILFORM from '../../forms/product-detail-form'
import SEARCHBOX from '../../searchbox/searchbox'
import HEADER from '../header' 
//import PRODUCTLIST from './product-list'
import DataTable from '../../utils/data-table'
import { product,deleteSupplierProduct, getDealerProductsV2 } from '../../../lib/nearshop-api'
import { useSelector , RootStateOrAny} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import Dialog from '../../dialogs/dialog'
import { supplierProductsSearch } from '../../../lib/search-api'


type style={ searchTxt:React.CSSProperties }
const ASSIGN_CONFIRM_TEXT = `Do you want to delete this Product?`

function PRODUCTS() {
  
    const classes:style={
        searchTxt:{
         fontFamily: "Roboto",
         fontStyle: "normal",
         fontWeight: "300",
         fontSize: "10px",
         lineHeight: "1.6rem",
         letterSpacing: "0.06em"
        }
    }
    const navigate = useNavigate();
    //const [productsInfo, setProductsInfo] = useState({
      //data: [],
      //shouldFetch: false,
      //error:null,
      //pageIndex:1
    //})
  const [productsListState, setProductsListState] = useState({
    
  })

    const dropDownItems = [
        {
            text: "Add from Product Database",
            link:"/Products/addProduct/Categories"
        },
        {
            text: "Search product barcode",
            link:"/Products/addProduct/scanBarcode"
        },
        {
            text: "Add manually",
            link:"/Products/addProduct/manually"
      
        },
        {
            text:"Bulk upload of products",
            link:"/Products/addProduct/bulkUpload"
        }
    ]
    const {searchtoken}=useParams()
    const location=useLocation()
    const supplierId= useSelector((state:RootStateOrAny)=>state.reducer.supplierId)
    const retailerInfo = location.state

    /*
     * This page will be used for 3 different contexts.
        1. For showing products global
        2. For showing products added to a retailer
        3. For showing search results
        In each of these cases, the function and args to fetch data will be different
        So, we memorize these 
    */
    const funcMap = React.useMemo(() => {
    //const funcMap = () => {
      if (searchtoken) {
        return( {
          key: `/api/products/search/${searchtoken}`, 
          fn:supplierProductsSearch,
          args: {supplierId: supplierId, searchToken: searchtoken},
          dataPath:'products',
          countPath: 'totalProducts'
        })
      } else if (retailerInfo?.retailerId) {
        return ({
          key: `/api/products/retailers/${retailerInfo}`, 
          args: {supplierId: supplierId, dealerId:retailerInfo?.retailerId}, 
          fn:getDealerProductsV2, 
          dataPath:'products',
          countPath: 'totalProducts'
        })
      } else {
        return  ({
          key: '/api/products', 
          args: {supplierId: supplierId}, 
          fn:product, 
          dataPath:'supplier.products',
          countPath: 'totalProducts'
        })
      }
    }, [searchtoken, retailerInfo])

    useEffect(() => {
      console.log("UseEffect: Did I fire???")
    }, [])


    const deleteProductNo = () => {
      setDeleteProduct("")
    }
  
    const deleteProductYes = () => {
      deleteSupplierProduct(productIdtemp,supplierId)
      setDeleteProduct("")
      window.location.reload()
    }

const [DeleteProduct, setDeleteProduct] = useState("")
const [productIdtemp, setproductIdtemp] = useState("")

const  handleDeleteButton=(productId:string)=>{
  setproductIdtemp(productId)
      setDeleteProduct(ASSIGN_CONFIRM_TEXT)
}
   

    const editProduct = (productId:string) => {
      
     navigate('/products/edit', {state:{productId:productId,dealerId:retailerInfo?.retailerId,dealerName:retailerInfo?.name} })
    }
    const columns =[
      {
        Header: "Image",
        accessor: "imageUrl",
        Cell: (row) => <img style={{cursor:'pointer'}} onClick={()=>editProduct(row.row.original.id)} src={row.value} width={50}/>
      },
      {
        Header: "Product Name",
        accessor: "name",
        minWidth: 600,
        Cell: (row) => <span style={{cursor:'pointer'}} onClick={()=>editProduct(row.row.original.id)}>{row.value}</span>
      },
      {
        Header: "M.R.P", 
        accessor: "price",
        Cell: row => <span style={{cursor:'pointer'}}  onClick={()=>editProduct(row.row.original.id)}><FontAwesomeIcon icon={solid('indian-rupee-sign')} /> 
        {/* {parseFloat(row.value).toFixed((2))} */}
        {row.value}
         </span>,
        minWidth:200
      },
      {
        Header: "Suggested Selling Price",
        accessor: "onSalePrice",
        Cell: row => <><FontAwesomeIcon icon={solid('indian-rupee-sign')} /> 
        {/* {parseFloat(row.value).toFixed((2))} */}
        {row.value}
        </>,
        minWidth:200
      }
    ]
    
    // We add a few extra action stuff (buttons and icons) at the end of our regular columns.
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id:"edit-product",
        Header: "Filter",
        Cell: ({row}) => (
          <Button onClick={() => editProduct(row.original.id)}>
            Edit
          </Button>
        )
      },
      {
        id: "manage-dealers",
        Cell: ({row}) => (
          <FontAwesomeIcon style={{cursor:'pointer'}}  color='red' icon={regular('trash-can')} 
          onClick={()=>handleDeleteButton(row.original.id)}
          />
         )
      }
    ])
  }
    
    return (
        <div  className="Products"style={{padding:"0px 4.4rem",margin:"auto"}}>
            <HEADER breadCrumb={retailerInfo?.retailerId ? '>' + retailerInfo.name :''} title='Products'/>
            <div className='container'>
            <div className='columns'>
            <div className='column is-9'>
            <SEARCHBOX placeholder={"Search Products"} />
            </div>
            <div className='column'>
            <ADDPRODANDDEALER dealerId={retailerInfo?.retailerId || null} dropDownItems={dropDownItems} buttonTxt='Add Products'/>
            </div>
            </div>
            {searchtoken!==undefined?
            <h1 style={classes.searchTxt}>{`Results matching '${searchtoken}'`}</h1>:null
            }
        {/* <PRODUCTLIST dealerId={location?.state} searchtoken={searchtoken}  /> */}
        
            <DataTable 
              columnsData={columns}
                fetcher={funcMap}
                hooks={tableHooks}
                onEdit={editProduct}
                onDelete={deleteProductYes}
                key={funcMap.key}
                />
                </div>

            {DeleteProduct.length>0
            ? <Dialog message={DeleteProduct} 
            title="Delete Product"
                  yesBtn='Yes'
                  noBtn='No'
                  onConfirm={deleteProductYes}
                  onReject={deleteProductNo}   
                  visible={true}
                  setVisible={()=>setDeleteProduct("")}
              />
            : null}

            
        </div>
        
    )
}

export default PRODUCTS


