import React from 'react';
import HEADER from '../../header'
import FileUploader from '../../../../../src/utils/file-uploader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'

type style = {
  heading: React.CSSProperties
  seperator: React.CSSProperties
  btn: React.CSSProperties
  para: React.CSSProperties
}

function BulkUploaddealers() {
  const isMobile = window.innerWidth <= 769;
  const classes: style = {
    heading: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "19px",
      letterSpacing: "0.06em",
      marginTop:isMobile?"10px": "48px"
    },
    seperator: {
      background: "#C4C4C4",
      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
      padding: "2px"
    },
    btn: {
      width: 135,
      height: 30,
      background: "#DD242E",
      borderRadius: 5,
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: 14,
      textAlign: "center",
      letterSpacing: "0.06em",
      color: "#FFFFFF",
      marginTop:37
    },
    para: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "20px",
      lineHeight: "23px",
      letterSpacing: "0.06em",
marginTop:"54px"
    }
  }
  return (
    <div style={{ padding: "0px 4rem" }}>
      <HEADER breadCrumb=" > Bulk upload" title="Retailers" />
      <div className='container'>
        <div style={{display:'flex'}}>
                         <button className='button ' style={{marginRight:'3%',marginTop:'5%'}}>
                                <span className="icon">
                                    <Link to='/Retailers'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{fontSize:"15px",color:'black'}}/>
                                    </Link>
                                </span>
                            </button>
                            <h1 style={classes.heading}>Add Retailers bulk upload</h1>
        </div>
        
        <hr></hr>
        {/* <h1 style={classes.para}>Please fill up the Retailer details on downloaded sheet and upload it by clicking “upload” button to add Retailers </h1>
        <h1 style={{...classes.para,marginTop:"37px"}}>{"Please dowload Bulk upload sheet from "}
          <a style={{textDecoration:"underline",color: "blue"}}>here</a>
        </h1>
        <button style={classes.btn} className='button'>Upload</button> */}
        <h1 style={classes.para}>Bulk upload requires the Retailer data file in a specific format. The format is specified in the bulk upload sheet provided 
        <a>   </a>     
        {/* <h1 style={{...classes.para,marginTop:"37px"}}>{"Please dowload Bulk upload sheet from "} */}
          <a href={"./excelSheet/retailers-upload-sample.xlsx"} download="retailers-upload-sample.xlsx" style={{textDecoration:"underline",color: "blue"}}>here</a>
        </h1>
        <h1 style={classes.para}>Fill up the Retailer data file and save it for uploading.</h1>
        <h1 style={classes.para}>Upload:</h1>
        <FileUploader type="retailer" />
        <a href={`/Retailers`}  style={{textDecoration:"underline",color: "blue", font:"roboto",fontSize:"12px"}}>Click here to go to Retailer's page</a>
      </div>
    </div>
  )
}

export default BulkUploaddealers;
