import React, {useState}from "react";
import INPUT from "./forminput/input";
import { Formik, Form, FieldArray, Field, replace } from "formik";
import * as Yup from "yup";
import TEXTAREA from "./forminput/textarea";
import { useQuery } from "react-query";
import {
  getProductDetails,
  updateProductDetails,
  collateralDataUpload,
} from "../../lib/nearshop-api";
import { useNavigate } from "react-router-dom";
import { deleteSupplierProduct } from "../../lib/nearshop-api";
import { useSelector } from "react-redux";
import { faLeftRight } from "@fortawesome/free-solid-svg-icons";
import { toast } from "bulma-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Dialog from '../dialogs/dialog';
import { Link } from "react-router-dom";

function PRODUCTDETAILFORM({ productId, dealerId, dealerName }) {
  const isMobile = window.innerWidth <= 769;
  const classes = {
    heading: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.06em",
      textDecorationLine: "underline",
      marginTop: 32,
    },
    input: {
      width:isMobile?"100%":"45rem",
      fontSize: 14,
      //minWidth: "35.9rem",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 300,
      marginLeft:isMobile?'0rem':"3rem",
    },
    inputName: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.06em",
      marginLeft:isMobile?'0rem':"3rem",
      color: "#000000",
    },
    button: {
      width:isMobile?'125px':"135px",
      height: "30px",
      background: "#4D9D27",
      borderRadius: "5px",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "14px",
      textAlign: "center",
      letterSpacing: "0.06em",
      color: "#FFFFFF",
      border: "none",
      marginTop: 51,
      cursor:'pointer',
      marginLeft:'4%'
    },
    dialogTxt: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 24,
      textAlign: "center",
      letterSpacing: "0.06em",
      color: "#000000",
    },
    addSelectedBtn: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "16px",
      textAlign: "center",
      background: "#409D3E",
      borderRadius: 5,
      justifyContent: "center",
      alignContent: "center",
      margin: "auto",
      color: "#FFFFFF",
      width: 130,
      height: 28,
      //marginTop:22
    },
    buttonMeta: {
      width: "135px",
      height: "30px",
      background: "#4D9D27",
      borderRadius: "5px",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "14px",
      textAlign: "center",
      letterSpacing: "0.06em",
      color: "#FFFFFF",
      border: "none",
      marginTop: "60px",
      marginLeft:isMobile?'4%': "25px",
      cursor: "pointer",
    },
    editImage: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "15px",
      lineHeight: "18px",
      letterSpacing: "0.06em",
      color: "#DD242E",
    },
    imageBox: {
      border: "1px solid #E7E7E7",
      boxSizing: "border-box",
      width:isMobile?"98%" :"27.1rem",
      height: "37.1rem",
      margin:'1%'
    },
    imageBoxCollateral: {
      border: "1px solid #E7E7E7",
      boxSizing: "border-box",
      width: "35.1rem",
      height: "5.1rem",
      marginBottom: "30px",
      marginLeft: "44px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: "40px",
    },
    img: {
      height: "37.1rem",
      width: "37.1",
      objectFit: "contain",
      marginLeft: "auto",
      marginRight: "auto",
      width: isMobile?"100%":"90%",
    },
    imageBoxMeta: {
      border: "1px solid #E7E7E7",
      boxSizing: "border-box",
      width: "148px",
      height: "145px",
      background: "rgba(196, 196, 196, 0.4)",
      margin: "auto",
    },
    imgMeta: {
      height: "140px",
      width: "148px",
      objectFit: "contain",
    },
    editImageMeta: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "15px",
      lineHeight: "18px",
      letterSpacing: "0.06em",
      color: "#DD242E",
      marginLeft: "140px",
    },

    textStyle: {
      fontFamily: "Roboto",
      width: "190px",
      height: "18px",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "15px",
      letterSpacing: "0.06em",
      marginLeft: "5px",
      marginTop: "13px",
      //marginRight: "10.7rem",

      color: "#000000",
    },

    buttonStyle: {
      backgroundColor: "white",

      width: "35.9rem",
      height: "42px",
      marginLeft: "3rem",
      marginBottom: "30px",

      border: "1px solid #C7C7C7",
      boxSizing: "border-box",
      justifyContent: "center",
      alignItems: "center",
    },
    dialogHeaderTxt: {
      width: "314px",
      height: "21px",

      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "16px",
      letterSpacing: "0.06em",
      color: "#000000",
      marginLeft:isMobile?'20px':"32px",
      marginTop: "31px",
    },
    dialogHeader: {
      margin: "auto",
      position: "absolute",

      width:isMobile?'100%': "900px",
      height:isMobile?'600px': "500px",
      top: "100px",
      background: "#FFFFFF",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      boxSizing: "border-box",
    },
    inputMeta: {
      width: "15.9rem",
      fontSize: 14,
      minWidth: "15.9rem",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 300,
      height: "42px",
      caretColor: "transparent",
      marginLeft: "-5px",
      marginRight: "10px",
      border: "1px solid #C7C7C7",
      boxSizing: "border-box",
    },
  };

  const validate = Yup.object({
    productName: Yup.string().required("required"),
    // description: Yup.string()
    //     .required('required'),
    mrp: Yup.number().required("required"),
    // sellingPrice: Yup.number()
    //     .required('required'),
    // productBrand: Yup.string()
    //     .required('required'),
    // countryOfOrigin: Yup.string()
    //     .required('required'),
    // barcode: Yup.number()
    //     .required('required')
    // ,
  });

  const { isLoading, data, error } = useQuery(
    ["productDetail", productId],
    getProductDetails
  );
  //debugger
  //let storeID = data?.product.store.id
  const [fileBase64String, setFileBase64String] = React.useState("");
  const [ispng, setIsPng] = React.useState(false);
  const [uploadedImage, setUploadedImage] = React.useState("");
  const [uploadedMetaImage, setMetaUploadedImage] = React.useState(
    "./icons/addimageicon.svg"
  );

  //    META
  const [metafileBase64String, setMetaFileBase64String] = React.useState("");
  const collateralArray = data?.product?.collateralDocs;

  const [show, setShow] = React.useState(false);
  const navigate = useNavigate();

  const addMetaData = () => {
  
    setShow(!show);
  };
  const AddMeta = () => {
    if (uploadedMetaImage === "./icons/addimageicon.svg") {
      toast({
        message: "Added Sucessfully",
        type: "is-primary",
        position: "bottom-center",
        extraClasses: "tostmsg",
      });
    } else {
      setShow(false);
      setMetaFileBase64String("./icons/addimageicon.svg");
      setMetaUploadedImage("./icons/addimageicon.svg");
    }
  };
  const CancelMetaForm = () => {
    setMetaFileBase64String("./icons/addimageicon.svg");
    setMetaUploadedImage("./icons/addimageicon.svg");
    setShow(!show);
  };

  const handleDeleteClick = () => {

    setDeleteProduct(ASSIGN_CONFIRM_TEXT)




    // const result = await deleteSupplierProduct(productId, supplierId);
    // if (result.ok) {
    //   navigate("/Products", { replace: true });
    // }
  };



  const [DeleteProduct, setDeleteProduct] = useState("")

    const ASSIGN_CONFIRM_TEXT = `Do you want to delete this Product?`

    const deleteProductYes = async () => {

        const result = await deleteSupplierProduct(productId, supplierId);
    if (result.ok) {
      navigate("/Products", { replace: true });
    }
        
        // deleteDealer(dealerId,supplierId)
        // navigate('/Retailers')
        // window.location.reload()


    }

    const deleteProductNo = () => {
        setDeleteProduct("")
        
        


    }

  const encodeFileBase64 = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file[0]);
      reader.onload = () => {
        var base64 = reader.result;
        setFileBase64String(base64);
        const type = base64.split(";")[0].split("/")[1];
        if (type === "png") {
          setIsPng(true);
        }
        
      };
    }
  };
  encodeFileBase64(uploadedImage);

  //----------------------------------------------------------------------------------------
  const encodeMetaFileBase64 = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file[0]);
      reader.onload = () => {
        var base64 = reader.result;
        setMetaFileBase64String(base64);
        //values.metaDescription.file(base64)
      };
    }
  };

  if (uploadedMetaImage !== "./icons/addimageicon.svg") {
    encodeMetaFileBase64(uploadedMetaImage);
  }

  //----------------------------------------------------------------------------------------------

  const supplierId = useSelector((state) => state.reducer.supplierId);
  const [showDialog, setShowDialog] = React.useState(false);
  
  return isLoading ? null : (
    <div>
      {isMobile && <style>
        {`
          .file{
            margin-left: 0 !important
          }
        `}
      </style>
      }
      <Formik
        initialValues={{
          productName: "" || data?.product?.productName,
          description: "" || data?.product?.description,
          mrp: "" || data?.product?.price,
          sellingPrice: "" || data?.product?.onSalePrice,
          productBrand: "" || data?.product?.brand,
          countryOfOrigin: "" || data?.product?.originCountry,
          barcode: "" || data?.product?.scannedBarCode,
          image: "" || fileBase64String,
          // metaDescription: [{
          //     description: "",
          //     producturl: "",
          //     //file: null
          // }],
          metaDescription: [
            {
              name: "",
              description: "",

              //file: null
            },
          ],
          fileArray: [
            {
              file: null,
            },
          ],
        }}
        onSubmit={(values) => {
          //let collateralData = values.metaDescription.map()

          // const collateralData = new Map(
          //     values.metaDescription.map(object => {
          //       return [JSON.stringify(object.description, object.producturl)];
          //     }),
          //   );

          //let tempcollateralData = values.metaDescription.map(item=>[{"description" :item.description, "name" :item.producturl}])
          //let finaldata = tempcollateralData.map()

          //let  finaldata = tempcollateralData.map(({description, name}) => `${description},${name}`).join(',')
          //let data = tempcollateralData.join(item => JSON.stringify(item))
          //let joineddata = tempcollateralData.join(",")
          //let data1 = JSON.stringify(tempcollateralData)
          //const arr =  values.metaDescription.filter(item => item.file != item.file)
          //let newarray = values.metaDescription.
          //const fileArray = values.fileArray.map((item,index)=>[{'collateralDoc' : item.file(0), "collateralDoc1" :item.file(1)}])

          // let fileName= "collateralDoc"
          //let length = values.fileArray.length

          //   const fileArray = values.fileArray.map((item,index)=>[
          //     {'collateralDoc' : item.file}
          // ])
          // let metafiles = JSON.stringify(values.fileArray)

          

          //let metaClone = values.fileArray

          //const metaaa = metafiles1.splice();
          //const metaaa2 = metafiles1.sort();
          //let length=metafiles1.length

          // for(var i=0;i<=length;i++){
          //     if(metafiles1[i].file === null){
          //          metaClone.pop(i);

          //     }

          // }

          //const metaaa3 = metafiles1.pop(0);
          //const metaaa4 = metafiles1.values(!null);
          //let metadesc = JSON.stringify(values.metaDescription)

          let metafiles1 = values.fileArray;
          const metaFilesFiltered = metafiles1.filter((e) => e != null);
          const metadescArray = values.metaDescription.filter((e) => e);
          let metaDescription = JSON.stringify(metadescArray);
          

          let payload = {
            sellingPrice: values.sellingPrice || "",
            price: values.mrp,
            name: values.productName,
            description: values.description || "",
            image: ispng
              ? fileBase64String.replace("data:image/png;base64,", "")
              : fileBase64String.replace("data:image/jpeg;base64,", ""),
            originCountry: values.countryOfOrigin || "",
            scannedBarCode: values.barcode || "",
            brand: values.productBrand || "",
            //metaInfo: values.metaDescription,
          };
        
          //updateProductDetails(data.product.id, payload, supplierId)
          if (metaFilesFiltered[0].file != null) {
            let storeID = data?.product.store.id || null;
            collateralDataUpload(
              data.product.id,
              storeID,
              metadescArray,
              metaFilesFiltered
            );
          }
          const result = updateProductDetails(
            data.product.id,
            payload,
            supplierId
          );
          if (result) {
            setShowDialog(true);
          }

          // window.history.back({replace:false})

          //navigate.goBack(-2,{replace:false})
          //window.location.reload()

          // navigate('/Products',{replace:false})
          //debugger
          // navigate('/Products/retailer', {DealerId:dealerId})
          // componentWillUnmount ()
          //navigate('/Products/retailer', {state: {retailerId:dealerId, name:dealerName}})
        }}
        validationSchema={validate}
      >
        {(formProps) => (
          <div className="card is-fullwidth">
             <div className='column is-1' style={{marginLeft:'1%',marginBottom:isMobile?'2%':'0'}}>
                  <button className='button is-light'>
                    <span className="icon">
                      <Link to='/Products'>
                      <FontAwesomeIcon icon={faArrowLeft} style={{fontSize:"15px",color:'black'}} />
                      </Link>
                    </span>
                  </button>
             
             </div>
          <Form >
            <div className="columns">
          
               <div className="column">
                  <h1
                      style={{
                        ...classes.inputName,
                        marginBottom:"2.4rem",
                        marginTop:isMobile?"2 rem":"4.1rem",
                      }}
                    >
                      Product Name
                    </h1>
               
                <div style={{ paddingBottom:isMobile?5: 26 }}>
                  <INPUT
                    style={{ ...classes.input, height: "5.5rem" }}
                    name="productName"
                    className="input"
                    type="text"
                  />
                </div>
                <h1 style={{ ...classes.inputName, marginBottom: "2.4rem" }}>
                  Description
                </h1>
                <div style={{ paddingBottom:isMobile?5: 26 }}>
                  <TEXTAREA
                    style={{ ...classes.input, height: "12.3rem",minWidth:'30rem' }}
                    name="description"
                    className="textarea has-fixed-size"
                  />
                </div>
                <h1 style={{ ...classes.inputName, marginBottom: "2.4rem",marginTop:"2.4rem" }}>
                  M.R.P.
                </h1>
                <div style={{ paddingBottom:isMobile?5: 26 }}>
                  <INPUT
                    style={{ ...classes.input, height: "5.5rem" }}
                    name="mrp"
                    className="input"
                    type="text"
                  />
                </div>
                <h1 style={{ ...classes.inputName, marginBottom: "2.4rem" }}>
                  Selling Price
                </h1>
                <div style={{ paddingBottom:isMobile?5: 26 }}>
                  <INPUT
                    style={{ ...classes.input, height: "5.5rem" }}
                    name="sellingPrice"
                    className="input"
                    type="text"
                  />
                </div>
              </div>
              <div className="column">
                <h1
                  style={{
                    ...classes.inputName,
                    marginBottom: "2.4rem",
                    marginTop:isMobile?"0" :"4.0rem",
                  }}
                >
                  Product Brand
                </h1>
                <div style={{ paddingBottom:isMobile?5: 26 }}>
                  <INPUT
                    style={{ ...classes.input, height: "5.5rem" }}
                    name="productBrand"
                    className="input"
                    type="text"
                  />
                </div>
                <h1
                  style={{
                    ...classes.inputName,
                    marginBottom: "2.4rem",
                    //marginTop: "0.6rem",
                  }}
                >
                  Country of Origin
                </h1>
                <div style={{ paddingBottom:isMobile?5: 26 }}>
                  <INPUT
                    style={{ ...classes.input, height: "5.5rem" }}
                    name="countryOfOrigin"
                    className="input"
                    type="text"
                  />
                </div>
                <h1
                  style={{
                    ...classes.inputName,
                    marginBottom: "2.4rem",
                    //marginTop: "7.0rem",
                  }}
                >
                  Barcode
                </h1>
                <INPUT
                  style={{ ...classes.input, height: "5.5rem" }}
                  name="barcode"
                  className="input"
                />
              </div>
              <div style={{ marginTop:isMobile?"2.4":"4.0rem", }} className="column">
                <div className="file">
                  <label className="file-label">
                    <div>
                      <input
                        className="file-input"
                        type="file"
                        name="image"
                        accept="image/*"
                        onChange={(event) =>
                          setUploadedImage(event.target.files)
                        }
                      />
                    </div>
                    <a style={classes.editImage}>Edit/Change Image</a>
                  </label>
                </div>
                <div style={classes.imageBox}>
                  {fileBase64String === "" ? (
                    <img style={classes.img} src={data.product.imageUrl} />
                  ) : (
                    <img style={classes.img} src={fileBase64String} />
                  )}
                </div>
                <button
                  style={{ ...classes.button, marginRight:'7%' }}
                  type="submit"
                >
                  Update
                </button>
                <button
                  onClick={handleDeleteClick}
                  style={{ ...classes.button, background: "#DD242E" }}
                  type="button"
                >
                  Delete Product
                </button>
              </div>
            </div>

            <h1
              style={{
                fontFamily: "roboto",
                fontSize:isMobile? 15:18,
                marginLeft: "30px",
                marginBottom: "1.4rem",
                marginTop: "4.1rem",
              }}
            >
              Add products collaterals or details
            </h1>
            <div style={classes.imageBoxCollateral}>
              <h1 style={{ fontFamily: "roboto", fontSize:isMobile?10: 16 }}>
                Please click add button
              </h1>
              {/* <FontAwesomeIcon icon="fa-solid fa-plus" /> */}
              <img
                onClick={() => setShow(!show)}
                style={{ height: "3.0rem", width: "3.0rem", cursor: "pointer" }}
                src={"./icons/addicon.svg"}
              />
            </div>
            <br></br>
            {collateralArray?.length > 0 && (
              <div>
                {/* <Typography className={classes.header}>Documents</Typography> */}
                <h1
                  style={{
                    padding: 10,
                    marginLeft: 15,
                    fontFamily: "roboto",
                    fontSize: 18,
                  }}
                >
                  Collateral Documents
                </h1>
                <div>
                  <ul
                    //className={classes.sidebarlist}
                    //style={{ display:"grid", gap:10, listStyleType:'none',padding:10 }}
                    style={{
                      //display:'flex',flexDirection:'row'
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                      listStyleType: "none",
                      //padding:10,
                      gap: 10,
                    }}
                  >
                    {collateralArray.map((val, key) => {
                      //debugger;
                      let filename = val.url;
                      var fileExt = filename.split(".").pop();
                      return (
                        <div>
                          <li
                            //class='grid-item'
                            //className={classes.row}
                            //style={{justifyContent:'center',
                            //alignItems:'center' }}
                            key={key}
                            //listStyle:none
                            // href={val.url}
                            // download
                            //style={{display:'flex' , flexDirection:'row'}}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                margin: "auto",
                                justifyContent: "center",
                                alignItems: "center",
                                //width:40,
                                //height:60
                              }}
                            >
                              {/* <img style={{ height: "40px", width: "40px", objectFit: "contain", marginRight: "10px", marginBottom: "-20px" }} src={"/assets/pdf-icon.svg"} /> */}
                              {fileExt === "pdf" ? (
                                //  <PictureAsPdfIcon color={'action'} style={{width:40,height:40 }}/>

                                <img
                                  style={{ width: 50, height: 50 }}
                                  src={"./icons/pdf-icon.svg"}
                                />
                              ) : (
                                //  <ImageIcon color={'action'} style={{width:40,height:40,color:'secondary' }}/>
                                <img
                                  style={{ width: 50, height: 50 }}
                                  src={"./icons/picture.svg"}
                                />
                              )}

                              <a
                                style={{
                                  fontSize: 14,
                                  fontFamily: "roboto",
                                  cursor: "pointer",
                                }}
                                href={val.url}
                                target="_blank"
                                download
                              >
                                View
                              </a>
                            </div>
                          </li>
                        </div>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}

            {/* <button onClick={addMetaData} type='button' style={classes.buttonStyle}>
                                <div style={{ display: 'flex', flexDirection: "row"}}>
                                    <h4 style={classes.textStyle}>Please click add button</h4>
                                    <img style={{ margin: 'auto', height: "2.5rem", width: "4.5rem" }} src={"./icons/addicon.svg"} />
                                </div>
                            </button> */}
            <div
              style={{
                ...classes.inputName,
                marginBottom: "1.4rem",
                marginTop: "4.1rem",
              }}
            >
              <FieldArray style={classes.inputMeta} name="metaDescription">
                {(fieldArrayProps) => {
                  console.log("fieldArrayProps", fieldArrayProps);
                  const { push, remove, form } = fieldArrayProps;
                  const { values } = form;
                  const { metaDescription } = values;

                  return (
                    <div>
                      {metaDescription.map((metaDescription, index) => (
                        <div key={index}>
                          {metaDescription.description && (
                            <div className="column">
                              <Field
                                style={classes.inputMeta}
                                name={`metaDescription[${index}].description`}
                                readOnly={true}
                              />
                              <a> </a>
                              {/* <Field style={classes.inputMeta} name={`metaDescription[${index}].producturl`} readOnly={true} /> */}
                              <Field
                                style={classes.inputMeta}
                                name={`metaDescription[${index}].name`}
                                readOnly={true}
                              />

                              <a> </a>
                              <img
                                style={{
                                  height: "40px",
                                  width: "48px",
                                  objectFit: "contain",
                                  marginRight: "10px",
                                  marginBottom: "-20px",
                                }}
                                src={"./icons/pdf-icon1.svg"}
                              />
                              {/* <a onClick={() => remove(index) && formProps.setFieldValue(`fileArray[${index}].file`,pop(index) ) } style={{ textDecoration: "underline", color: "black", fontSize: "10px" }}>Delete</a> */}
                              <a
                                onClick={() =>
                                  remove(index) &&
                                  formProps.setFieldValue(
                                    `fileArray[${index}]`,
                                    null
                                  )
                                }
                                style={{
                                  textDecoration: "underline",
                                  color: "black",
                                  fontSize: "10px",
                                }}
                              >
                                Delete
                              </a>
                            </div>
                          )}

                          {/* MODAL */}

                          <div className={`modal ${show ? "is-active" : ""}`}>
                            <div className="modal-background"></div>
                            <div
                              style={classes.dialogHeader}
                              className="modal-content has-background-white py-5 px-5"
                            >
                              <h3
                                style={classes.dialogHeaderTxt}
                                className="title mb-6"
                              >
                                {" "}
                                Add products collaterals or details
                              </h3>
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div className="column">
                                    <h1
                                      style={{
                                        ...classes.inputName,
                                        marginBottom: "2.4rem",
                                      }}
                                    ></h1>
                                    <div style={{ paddingBottom: 26 }}>
                                      <TEXTAREA
                                        style={{
                                          ...classes.input,
                                          height: "12.3rem",
                                        }}
                                        name={`metaDescription[${index}].description`}
                                        className="textarea has-fixed-size"
                                        type="text"
                                        placeholder="Product detail/information"
                                      />
                                    </div>
                                    <h1
                                      style={{
                                        ...classes.inputName,
                                        marginBottom: "2.4rem",
                                      }}
                                    ></h1>
                                    <div style={{ paddingBottom: 26 }}>
                                      <Field
                                        style={{
                                          ...classes.input,
                                          height: "4.2rem",
                                        }}
                                        name={`metaDescription[${index}].name`}
                                        className="input"
                                        type="text"
                                        placeholder="Website URL"
                                      />
                                    </div>
                                    {isMobile && <div
                                    style={{ marginTop: "2.0rem" }}
                                    className="column"
                                  >
                                    <div className="file">
                                      <label className="file-label">
                                        <div>
                                          <input
                                            className="file-input"
                                            type="file"
                                            name={`fileArray[${index}].file`}
                                            accept="image/*,application/pdf"
                                            onChange={(event) =>
                                              formProps.setFieldValue(
                                                `fileArray[${index}].file`,
                                                event.target.files[0]
                                              ) &&
                                              setMetaUploadedImage(
                                                event.target.files
                                              )
                                            }
                                          />
                                        </div>
                                        <a style={classes.editImageMeta}>
                                          Upload:
                                        </a>
                                      </label>
                                    </div>
                                    <div style={classes.imageBoxMeta}>
                                      {metafileBase64String === "" ? (
                                        <img
                                          style={classes.imgMeta}
                                          src={uploadedMetaImage}
                                        />
                                      ) : (
                                        <img
                                          style={{
                                            height: "140px",
                                            width: "148px",
                                            objectFit: "contain",
                                          }}
                                          src={metafileBase64String}
                                          alt={"PDF"}
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src =
                                              "./icons/pdf-icon.svg";
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>}

                                    <button
                                      type="button"
                                      style={{
                                        ...classes.buttonMeta,
                                        marginRight:isMobile? "4%":'10px',
                                      }}
                                      onClick={() => {
                                        uploadedMetaImage ===
                                        "./icons/addimageicon.svg"
                                          ? toast({
                                              message:
                                                "File/Image not Selected!",
                                              type: "is-dark",
                                              position: "bottom-center",
                                              extraClasses: "tostmsg",
                                            })
                                          : push("");
                                        uploadedMetaImage ===
                                        "./icons/addimageicon.svg"
                                          ? setShow(true)
                                          : setShow(false);
                                      }}
                                    >
                                      Add{" "}
                                    </button>
                                    <span onClick={CancelMetaForm}>
                                      <button
                                        type="button"
                                        //onClick={CancelMetaForm}
                                        onClick={() => {
                                          formProps.setFieldValue(
                                            `fileArray[${index}]`,
                                            null
                                          );
                                          formProps.setFieldValue(
                                            `metaDescription[${index}]`,
                                            ""
                                          );
                                        }}
                                        style={{
                                          ...classes.buttonMeta,
                                          background: "#DD242E",
                                        }}
                                      >
                                        Cancel
                                      </button>
                                    </span>
                                  </div>
                                  {/* META FILE UPLOAD */}

                                  {!isMobile && <div
                                    style={{ marginTop: "2.0rem" }}
                                    className="column"
                                  >
                                    <div className="file">
                                      <label className="file-label">
                                        <div>
                                          <input
                                            className="file-input"
                                            type="file"
                                            name={`fileArray[${index}].file`}
                                            accept="image/*,application/pdf"
                                            onChange={(event) =>
                                              formProps.setFieldValue(
                                                `fileArray[${index}].file`,
                                                event.target.files[0]
                                              ) &&
                                              setMetaUploadedImage(
                                                event.target.files
                                              )
                                            }
                                          />
                                        </div>
                                        <a style={classes.editImageMeta}>
                                          Upload:
                                        </a>
                                      </label>
                                    </div>
                                    <div style={classes.imageBoxMeta}>
                                      {metafileBase64String === "" ? (
                                        <img
                                          style={classes.imgMeta}
                                          src={uploadedMetaImage}
                                        />
                                      ) : (
                                        <img
                                          style={{
                                            height: "140px",
                                            width: "148px",
                                            objectFit: "contain",
                                          }}
                                          src={metafileBase64String}
                                          alt={"PDF"}
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src =
                                              "./icons/pdf-icon.svg";
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  );
                }}
              </FieldArray>
            </div>

            {/* </div> */}
          </Form>
          </div>
        )}
      </Formik>
      <div className={`modal ${showDialog ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div
          style={{
            height: 365,
            width: 600,
            border: "1px solid #000000",
            borderRadius: "30px",
          }}
          className="modal-card"
        >
          <section className="modal-card-body">
            <img
              style={{
                height: 63,
                width: isMobile?"100%":"90%",
                margin: "40px auto 49px auto",
              }}
              src="./icons/successicon.svg"
            />
            <h1 style={classes.dialogTxt}>Product Updated Successfully </h1>
            <div style={{ display: "flex", margin: "40px 140px " }}>
              <button
                className="button"
                style={{ ...classes.addSelectedBtn }}
                onClick={() =>
                  //navigate("/Products/retailer", {
                    navigate("/Products", {
                    state: { retailerId: dealerId, name: dealerName },
                  })
                }
              >
                OK
              </button>
              {/* <button className='button' 
              //style={{...classes.addSelectedBtn,background: "#DD242E",marginLeft:32}} 
              onClick={()=>setShowDialog(false)}>
              Add more products
      </button> */}
            </div>
          </section>
        </div>
      </div>
      <div>
                {DeleteProduct.length>0
            ? <Dialog message={DeleteProduct} 
            title="Delete Product"
                  yesBtn='Yes'
                  noBtn='No'
                  onConfirm={deleteProductYes}
                  onReject={deleteProductNo}   
                  visible={true}
                  setVisible={()=>setDeleteProduct("")}
              />
            : null}
                </div>
    </div>
  );
}

export default PRODUCTDETAILFORM;
