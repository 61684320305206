import React from 'react'

export default function ADDDEALERSBUTTON() {
    const classes={
        addBox:{
            width: "109px",
height: "27px",
border: "1px dashed #A7A7A7",
boxSizing: "border-box",
marginLeft:32,marginBottom:31
        },
        addDealersTxt  :{
            
height: "14px",fontFamily: "Roboto",
fontStyle: "normal",
fontWeight: "normal",
fontSize: "12px",
letterSpacing: "0.06em",
color: "#545454",padding:0,margin:0,marginTop:6
        }
    }
    return (
        <div style={classes.addBox}>
            <div style={{display:'flex' ,flexDirection:"row"}}>
            <img  style={{margin:"5px 5px 7px 5px"}}height={13} width={13} src={"./icons/addicon.svg"} />
            <h4 style={classes.addDealersTxt}>Add Retailers</h4>
            </div>
        </div>
    )
}
