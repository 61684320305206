import React from 'react';
import HEADER from '../../../header'
import SEARCHBOX from '../../../../searchbox/searchbox';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getSubcategories } from '../../../../../lib/nearshop-api';
import { Link } from 'react-router-dom';


type style={
    searchTxt:React.CSSProperties
    subCategoryName:React.CSSProperties
    seperator:React.CSSProperties
  }

function SubCategories() {
    const classes:style={
        searchTxt:{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "2rem",
                  lineHeight: "1.9rem",
                  letterSpacing: "0.06em",
                  color: "#000000",marginTop:"12px"
        },
        subCategoryName:{
          fontFamily: "Roboto",
          fontStyle: "normal",
          fontWeight: 300,
          fontSize: "1.8rem",
          lineHeight: "19px",
          letterSpacing: "0.06em",
          textAlign: "center",
          marginTop:24,
          marginBottom:24,
          color: "#000000"
        },
        seperator: {
          border: "1.5px solid #E7E7E7",
          width:"100%"
         }
      }

    const {catId}=useParams()
    const {isLoading,data,error}=useQuery(["subCategory",catId],getSubcategories)
  return (
        isLoading?null:
        <div style={{padding:"0px 44px"}}>
        <HEADER breadCrumb={`ㅤ> Add products from databaseㅤ> ${data.category.name}`} title={"Products "}/>
        <div className='container'>
        {/* <SEARCHBOX placeholder='Search products here'/> */}
        <h1 className='mobile-breadCrumb' style={{fontSize:"12px"}}>{`Productsㅤ> Add products from databaseㅤ> ${data.category.name}`}</h1>
        {/* <h1 style={classes.searchTxt}>Search from category</h1> */}
      <h1 style={{...classes.searchTxt,fontSize:"2rem",fontWeight:"normal",marginTop:14}}>
        {data.category.noOfProducts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") } products available
      </h1>
      <hr style={classes.seperator}></hr>
      { data.subcategories.map((data:{id:string,noOfProducts:number,name:string})=>{
         return (
             <div key={data.id}>
           <Link to={`${data.id}/products`}>
          <h1 style={classes.subCategoryName}>{data.name}</h1>
          </Link>
          <hr></hr>
          </div>
         )
       })
      
      }
        </div>
        </div>
    )
}

export default SubCategories;
