import React, { useEffect, useRef } from 'react';
import HEADER from '../../../header'
import SEARCHBOX from '../../../../searchbox/searchbox';
import AddProductCard from '../addproductcard';
import { useQuery } from 'react-query';
import {  useNavigate, useParams } from 'react-router-dom';
import { getCatAndsubCatProd, addProducFromDB} from '../../../../../lib/nearshop-api';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TailSpin } from  'react-loader-spinner'
import { useSelector ,RootStateOrAny} from 'react-redux';
import { toast } from 'bulma-toast'




// type style = {
//     heading: React.CSSProperties
//     seperator: React.CSSProperties
//     addSelectedBtn: React.CSSProperties
// }

function AddProductfromDB() {
    const classes= {
        heading: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "2rem",
            lineHeight: "1.9rem",
            letterSpacing: "0.06em",
            color: "#000000", marginTop: 12

        },
        seperator: {
            border: "1.5px solid #E7E7E7",
            width: "100%"
        },
        addSelectedBtn: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "16px",
            textAlign: "center",
            background: "#409D3E",
            borderRadius: 5,
            color: "#FFFFFF",
            width: 130,
            height: 28,
            marginTop: 22
        },
    }
    const { catId, subCatId } = useParams()
    const [pageNumber, setPageNumber] = React.useState(1)
    const [productsArray, setProductsArray] = React.useState([])
    const myRef = useRef(null)

    const { isLoading, data, error } = useQuery(["catAndsubCatProd", catId, subCatId, 10, pageNumber], getCatAndsubCatProd)
    const [divHeight, setDivHeight] = React.useState(200)
    const [selectedProducts,setSelectedProducts]=React.useState([])
    React.useEffect(() => {
        if (isLoading === false) {
            let newProducts = productsArray.concat(data?.data?.products)
            setProductsArray(newProducts)
        }

    }, [data, isLoading]);

    React.useEffect(() => {
      if (myRef.current) {
        
        //divHeight = myRef.current.offsetHeight;
        setDivHeight(myRef.current.offsetHeight)
    }
  },[myRef])
  React.useEffect(()=>{
    console.log("array updated immideatly",selectedProducts)

},[selectedProducts])

    const fetchData = () => {
        setPageNumber(pageNumber + 1)
    }
    
    const addSelectedItem=(selectedProduct)=>{
        const newProducts=selectedProducts.concat(selectedProduct)
        setSelectedProducts(newProducts)
       
        //setSelectedProducts({...selectedProducts,[selectedProduct.id]:selectedProduct})
        console.log("innner ", selectedProducts)
    }
    
    const removeUnselectedItem =(unselectedProductId)=>{
        // debugger
       let newObject= JSON.parse(JSON.stringify(selectedProducts))
    //    let keys=Object.keys(newObject)
    //    let matchingKey=keys.filter(k=>k===unselectedProductId)
        delete newObject[unselectedProductId]
       
        setSelectedProducts(newObject)
        
    }

    const updateSelectedProductmrp=(product)=>{
        
        let storedproduct = selectedProducts
        let product1 = product[0]
        let productid = product.id

        for( var i=0;i<selectedProducts.length;i++){
          if(storedproduct[i].id === product.id){

            let index = selectedProducts.indexOf(product.id)

          storedproduct[i].price = product.price
          setSelectedProducts(storedproduct)

          }
        }

       

      }

      const updateSelectedProductsp=(product)=>{
    
        let storedproduct = selectedProducts
        let product1 = product[0]
        let productid = product.id

        for( var i=0;i<selectedProducts.length;i++){
          if(storedproduct[i].id === product.id){

            let index = selectedProducts.indexOf(product.id)

          storedproduct[i].sellingPrice = product.sellingPrice
          setSelectedProducts(storedproduct)

          }
        }

      }
    
//     const updateSelectedProductmrp=(selectedProduct)=>{
//         debugger

//         let storedproduct = selectedProducts
//         let product1 = selectedProduct[0]
//         let productid = selectedProduct.id

//         for( var i=0;i<selectedProducts.length;i++){
//           if(storedproduct[i].id === selectedProduct.id){

//             let index = selectedProducts.indexOf(selectedProduct.id)

//           storedproduct[i].sellingPrice = selectedProduct.sellingPrice
//           setSelectedProducts(storedproduct)

//           }
//         }
        
        
//                 //const returnedTarget = Object.assign(selectedProducts, selectedProduct);
//                 //setSelectedProducts(returnedTarget)
            
          
//  console.log("updatedSelectedProducts",selectedProducts)
// }
    
    

    const transformToArray=(obj)=>{
          let keys=Object.keys(obj)
         let newArray=keys.map((id)=>{
            return obj[id]
          })
        //   debugger
          return newArray
    }

    const supplierId= useSelector((state=>state.reducer.supplierId))
   const navigate=useNavigate()
// console.log(selectedProductArray)
    const handleSubmit=async ()=>{
      
        //const payload=transformToArray(selectedProducts)
        const payload = selectedProducts


        if(payload){
            let isError = ''
            
            
      
            for(var i=0 ; i<payload.length ; i++){
              if (payload[i].price === ''){
                //setIsErrorMrp(true)              
                isError = 'true'              
              } 
              else{
                isError = 'false'
                //setIsErrorMrp(false)    
              }   
            }
             
          console.log(payload)
          if(!payload.length){
            toast({
              message: "Product Not Selected!",
              type: 'is-black',
              position:"bottom-center",
              extraClasses:"tostmsg"
            })
          }
          else if(isError === 'true'){
            toast({
              message: "Mrp Is Mandatory!",
              type: 'is-black',
              position:"bottom-center",
              extraClasses:"tostmsg"
            })
            
          }
          else{        
        const result=await addProducFromDB({data:payload},supplierId)
        if(result.ok){
            toast({
                message: "Added Sucessfully",
                type: 'is-primary',
                position:"bottom-center",
                extraClasses:"tostmsg"
              }) 
        navigate('/Products')
        }
        else{
            toast({
                message: "error",
                type: 'is-danger',
                position:"bottom-center",
                extraClasses:"tostmsg"
              }) 
        }
    }
}
    }
    const handleBack=()=>{       
        window.history.go(-1)
    }
    return (
        <div ref={myRef} style={{ padding: "0px 44px", height:'100%' }}>
            <HEADER breadCrumb={`ㅤ> Add products from databaseㅤ> `} title={"Products "} />
            {/* <SEARCHBOX placeholder='Search products here' /> */}
            <h1 style={classes.heading}>Products</h1>
            {data?.data?.totalProductsCount===undefined?null:
            <h1 style={{ ...classes.heading, fontSize: "1.7rem", fontWeight: "normal", marginTop: 14 }}>
                {`${data?.data?.totalProductsCount} products available`}
            </h1>}
            <hr style={classes.seperator}></hr>
            <InfiniteScroll
                dataLength={productsArray.length} //This is important field to render the next data
                next={fetchData}
                //height={500}
                height={divHeight}
                hasMore={productsArray.length !== data?.data?.totalProductsCount}
                loader={<div style={{display:'flex',justifyContent:"center"}}> 
                <TailSpin color ='#FF5733' height= {100}width= {110}/></div>}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>Yay! You have seen it all</b>
                    </p>
                }
            >
                {productsArray.map((product,index) => {
                    return (
                        <AddProductCard updateSelectedProductsp={updateSelectedProductsp} updateSelectedProductmrp={updateSelectedProductmrp} removeUnselectedItem={removeUnselectedItem}product={product} key={index} addSelectedItem={addSelectedItem} addButton={false} selectAll={false} handleChange={() => { }} />
                    )
                })
                }
            </InfiniteScroll>
            <div style={{ display: "flex", flexDirection: "row-reverse", marginBottom: "40px" }}>
                <button onClick={handleBack} style={{ ...classes.addSelectedBtn, background: "#DD242E" }} className='button '>Cancel</button>
                <button onClick={handleSubmit}   style={{ ...classes.addSelectedBtn, marginRight: "20px" }} className='button'>Add selected</button>
            </div>
        </div>
    )
}

export default AddProductfromDB;
