import { Modal, Content, Button,Dropdown } from "react-bulma-components"
import {useCallback, useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isBefore, isAfter, isEqual } from 'date-fns';
import { Input, Typography } from "@mui/material";
//import 'bulma/css/bulma.min.css';

type callbackFn = () => void;

type DialogProps = {
  title?: string;
  message: string;
  yesBtn?: string;
  noBtn?: string;
  onConfirm?: callbackFn;
  onReject?: callbackFn;
  visible: boolean;
  setVisible: callbackFn;
  isLoading:boolean;
  setSelectedOption?: string;
  selectedOption?: string;
  selectedStartDate:Date;
  selectedEndDate:Date;
  setSelectedStartDate: Function;
  setSelectedEndDate: Function;
  
}

const Dialog = ({
  setSelectedStartDate,
  setSelectedEndDate,
  selectedEndDate,
  selectedStartDate,
  ...props
}: DialogProps) => {

    const [success,setSuccess] = useState (false)

    const handleStartDateChange = (date: Date | null) => {
      if (date === null) {
        setSelectedStartDate(null);
        alert('Please enter the Start date')
        return;
      }
  
    
      if (
        selectedEndDate === null ||
        isBefore(date, selectedEndDate) ||
        isEqual(date, selectedEndDate)
      ) {
        setSelectedStartDate(date);
       } else {
        
        alert('Invalid start date. Must be before or equal to end date.');
      }
    };
    
    const handleEndDateChange = (date: Date | null) => {
      if (date === null) {
        setSelectedEndDate(null);
        alert('Please fill the end date')
        return;
      }
  
      
      if (
        selectedStartDate === null ||
        isAfter(date, selectedStartDate) ||
        isEqual(date, selectedStartDate)
      ) {
        setSelectedEndDate(date);
             
      } else {
        
        alert('Invalid end date. Must be after or equal to start date.');
      }
    };

    const handleSubmit = function(event: React.FormEvent)  {
      event.preventDefault();
      console.log("Inside HandleSubmit",event)
     
     
      if (selectedStartDate === null) {
                 alert('Please fill the start date')
            return;
          }
      else if (selectedEndDate === null) {
                 alert('Please fill the end date')
                 return;
          }
      else if (props.onConfirm) {
         //alert('Order List is as below')
         props.onConfirm()
      }
     };
  
    const isMobile = window.innerWidth <= 769;
    // const modalCardBodyStyle: React.CSSProperties = {
    //   WebkitOverflowScrolling: 'touch',
    //   backgroundColor: 'white',
    //   flexGrow: 1,
    //   overflow: ' !important', 
    //   flexShrink: 1,
    //   padding: 20,
    //   height:'200px'
    // };
  
  return  (
    <div>
    {isMobile? <style>
      {`
        .modal-card-body {
          WebkitOverflowScrolling: 'touch';
          backgroundColor: 'white';
          flexGrow: 1;
          overflow: unset !important;
          flexShrink: 1;
          padding: 20px;
          width: 100%;
          height:500px
        }
        .modal-card{
          overflow: visible !important;
        }
         .date-picker-wrapper {
          display: block;
          width: 100% !important;
        }
        .react-datepicker__header{
          width: 100% !important;
          
        }
        .date-picker-calendar {
          font-size: 3rem !important;
          width: 100% !important; 
        }
        .react-datepicker{
          width : 100% !important;
        }
        .react-datepicker__current-month{
          margin: 1.166rem !important ;
          font-size: 4rem  !important;
          font-weight: 800 !important; 
        }
        .react-datepicker-time__header, .react-datepicker-year-header{
          margin: 2.166rem !important ;
          width : 3rem !important;
          font-size: 2rem  !important;
          font-weight: 800 !important; 
        } 
        
        .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
            margin: 2.166rem !important;
            width : 3rem !important;
            font-size: 2.944rem !important;
        }
        .react-datepicker__navigation-icon::before{
          top: 17px !important;
        }
        body{
          line-height:1.2 !important
        }
          
      `}
     </style>
     :
     <style>
      {`
        .modal-card-body {
          WebkitOverflowScrolling: 'touch';
          backgroundColor: 'white';
          flexGrow: 1;
          overflow: unset !important;
          flexShrink: 1;
          padding: 20px;
          width: 100%;
          height:400px
        }
        .modal-card{
          overflow: visible !important;
        }
         .date-picker-wrapper {
          display: block;
          width: 100% !important;
        }
        .react-datepicker__header{
          width: 100% !important;
          
        }
        .date-picker-calendar {
          font-size: 2rem !important;
          width: 100% !important; 
        }
        .react-datepicker{
          width : 100% !important;
        }
        .react-datepicker__current-month{
          margin: 1.5rem !important ;
          font-size: 2rem  !important;
          font-weight: 800 !important; 
        }
        .react-datepicker-time__header, .react-datepicker-year-header{
          margin: 1.5rem !important ;
          width : 2rem !important;
          font-size: 2rem  !important;
          font-weight: 800 !important; 
        } 
        
        .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
            margin: 1.5rem !important;
            width : 1.5rem !important;
            font-size: 2rem !important;
        }
        // .react-datepicker__navigation-icon::before{
        //   top: 5px !important;
        // }
        body{
          line-height:1 !important
        } 
          
      `}
     </style>
     } 
    <Modal show={props.visible} onClose={props.setVisible}>
  <Modal.Card>
    <Modal.Card.Header>
      <Modal.Card.Title className='is-size-1' style={{fontWeight:'1000'}}>{props.title || 'Custom Date'}</Modal.Card.Title>
    </Modal.Card.Header>
    <Modal.Card.Body style={{ padding: '10px' }}>
      <Content className='is-size-2'>
        <div style={{ paddingBottom: '20px' }}>
          {props.isLoading ? <p>Loading...</p> : null}
        </div>
        <div className="container">
          <div className="control">
            <label className={isMobile?"label is-size-2":'label is-size-5'}>Select a Start Date</label>
            <DatePicker
              selected={selectedStartDate}
              onChange={(date) => handleStartDateChange(date)}
              wrapperClassName="date-picker-wrapper" // Custom class for wrapper styling
              calendarClassName="react-datepicker__navigation-icon::before,react-datepicker__day-name,react-datepicker__day,react-datepicker__time-name,date-picker-calendar,react-datepicker__current-month,react-datepicker-time__header,react-datepicker-year-header"
              dateFormat="MMMM d, yyyy"
              className={isMobile?"input is-size-3":'input is-size-5'}
              placeholderText="MM DD YYYY"
            />
          </div>
          <div className="control">
            <label className={isMobile?"label is-size-2":'label is-size-5'}>Select an End Date</label>
            <DatePicker
              selected={selectedEndDate}
              onChange={(date) => handleEndDateChange(date)}
              wrapperClassName="date-picker-wrapper" // Custom class for wrapper styling
              calendarClassName="react-datepicker__navigation-icon::before,date-picker-calendar,.react-datepicker__day-name,.react-datepicker__day,react-datepicker__time-name,react-datepicker__current-month, react-datepicker-time__header,react-datepicker-year-header"
              dateFormat="MMMM d, yyyy"
              className={isMobile?"input is-size-3":'input is-size-5'}
              placeholderText="MM DD YYYY"
            />
          </div>
        </div>
      </Content>
    </Modal.Card.Body>
    <Modal.Card.Footer>
      <div className="buttons is-right">
        {props.yesBtn ? <Button className='is-medium is-pulled-right' style={{ width: 70, height: 30, fontSize: 14 }} color="success" onClick={handleSubmit}>{props.yesBtn}</Button> : null}
        {props.noBtn ? <Button className='is-medium' color="danger" style={{ width: 70, height: 30, fontSize: 14 }} onClick={props.onReject}>{props.noBtn}</Button> : null}
      </div>
    </Modal.Card.Footer>
  </Modal.Card>
</Modal>
   </div>
    
  )
}


export default Dialog

