import React from 'react'
import { Link } from 'react-router-dom'
import {useRef,useEffect} from 'react'
import { useQuery } from 'react-query'
import { getProductListForAdditionToDealer,addSelectedProductToDealer } from '../../lib/nearshop-api'
import PRODUCTCARD from '../dashboardComponent/product/productcard'
import InfiniteScroll from 'react-infinite-scroll-component'
//import { TailSpin } from  'react-loader-spinner'
import { toast } from 'bulma-toast'
import { useSelector ,RootStateOrAny} from 'react-redux';
import Dialog from '../dialogs/dialog'
import SEARCHBOX from '../searchbox/searchbox'
import {Button} from 'react-bulma-components'


// type style={
//  box:React.CSSProperties
//  addTxt:React.CSSProperties
//  dialogHeader:React.CSSProperties
//  dialogHeaderTxt:React.CSSProperties
//  dialogbtn:React.CSSProperties
// }

// type propType={
//     buttonTxt:string
//     dropDownItems:Array<any>
//     dealerId:any
// }

function ADDPRODANDDEALER({buttonTxt,dropDownItems,dealerId}) {
  const isMobile = window.innerWidth <= 769;
    const classes={
        box:{
            width: "22.3rem",
            height: 43,
            background: "#FFFFFF",
            border: "1px solid #E5E5E5",
            boxSizing: "border-box",
            borderRadius: 5,
            marginLeft:isMobile?30:30
        },
        addTxt  :{
            height: "1.4rem",fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "1.5rem",
            letterSpacing: "0.06em",
            color: "#A7A7A7",padding:0,margin:0,marginTop:"1.2rem",width:"12.8rem",textAlign:"center"
                    },
                    dialogHeader:{
                        width: "1205px",
                  height: 40,
                  background: "#DD242E",
                  borderRadius: "5px 5px 0px 0px",
                  color: "#FFFFFF",
                      },
                      selectAllBtn:{
                        width: 66,height: 30,
                        border: "2px solid #000000",
                        boxSizing: "border-box",
                        borderRadius: 5,
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: 10,
                        lineHeight: "12px",
                        letterSpacing: "0.06em",
                        color: "#000000",
                        marginTop:2,
                        marginRight:2,
                        marginLeft:-15
                    },
                      dialogHeaderTxt:{
                        fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "14px",
                  lineHeight: "16px",
                  letterSpacing: "0.06em",
                  color: "#FFFFFF",
                  
                      },dialogbtn:{
                        left:419,
                        
                        //marginLeft:"400px",
                        fontSize: "12px",
                        width: "13.5rem",
                  height: 30,
                  background: "#4D9D27",
                  borderRadius: 5,
                  color: "#FFFFFF"
                      }
    }
    const [pageNumber, setPageNumber] = React.useState(1)

    const supplierId= useSelector((state=>state.reducer.supplierId))


    //const {isLoading,data,error}=(dealerId===undefined)?null:useQuery(['productsNotAdded',dealerId,pageNumber,5],getProductListForAdditionToDealer)
    const {status, isLoading,data,error}=useQuery(dealerId?['productsNotAdded', dealerId, pageNumber, 10,supplierId]: null, getProductListForAdditionToDealer)
    
  //  const ref=useRef()  as React.MutableRefObject<HTMLInputElement>;
  const ref=useRef() 
    const [show,setShow]=React.useState(false)
    const toggleDropDown=()=>{
        setShow(!show)
    }
    
    useEffect(() => {
      const chechIfClickedOutside=(e)=>{
        if(show && ref.current && !ref.current.contains(e.target))
        setShow(false)
      }
      document.addEventListener('click',chechIfClickedOutside)
    
      return () => {
        document.removeEventListener('click',chechIfClickedOutside)
      }
    }, [show]);

    const [productsList,setProductsList]=React.useState([])


    const [selectedProducts,setSelectedProducts]=React.useState({})
    const [count,setcount]=React.useState(0)
    const [selectAll,setSelectAll]=React.useState(false) 
    const [addProduct, setAddProduct] = React.useState("")
    const [isChecked,setIsChecked]=React.useState(false)

    const handleAdd=()=>{
      setAddProduct(ASSIGN_CONFIRM_TEXT)
    }

    const addProductNo = () => {
      setAddProduct("")
    }
    
    const addSelectedItem=(selectedProduct)=>{
      
  
       
        setSelectedProducts({...selectedProducts,[selectedProduct]:selectedProduct})
        setcount(count + 1)
        console.log("innner ",selectedProducts)
    }
    const ASSIGN_CONFIRM_TEXT = `Do you want to Add the selected Products?  ${count} / ${data?.totalProducts}`
     

    const addAllProducts=(e)=>{
   
      setIsChecked( e.target.checked)


      if(e.target.checked===true){
    let tempProductlist = productsList.map(item=>item.id)
    setSelectedProducts(tempProductlist)
    setcount(tempProductlist.length)
    }  
    if(e.target.checked===false){
      setSelectedProducts("")
      setcount(0)
    }
  }  

    const removeUnselectedItem =(unselectedProductId)=>{
      setcount(count - 1)

      
     let newObject= JSON.parse(JSON.stringify(selectedProducts))
  //    let keys=Object.keys(newObject)
  //    let matchingKey=keys.filter(k=>k===unselectedProductId)
      delete newObject[unselectedProductId]
     
      setSelectedProducts(newObject)
      
  }
  const transformToArray=(obj)=>{
    let keys=Object.keys(obj)
   let newArray=keys.map((id)=>{
      return obj[id]
    })
    
    return newArray
}


    useEffect(()=>{
       if(isLoading===false)
       {
        if (pageNumber > 1) {
        let newProducts = productsList.concat(data?.products)
         setProductsList(newProducts)
        }
        else{
          if (data?.products) setProductsList(data?.products)
        }
       }
    },[data,isLoading])

    const fetchData = () => {
      setPageNumber(pageNumber + 1)
  }
  const handleSubmit=async ()=>{
    const payload=transformToArray(selectedProducts)

    const result=await addSelectedProductToDealer(dealerId,{products:payload},supplierId)
    console.log(payload)
    if(result.ok){
      setShow(false)
      toast({
        message: "Added Sucessfully",
        type: 'is-primary',
        position:"bottom-center",
        extraClasses:"tostmsg"
      })      
      setAddProduct("")
      window.location.reload()
    }
  }
    return (
      
        <div  ref={ref} className={`dropdown ${show?"is-active is-right":""} `} style={{caretColor: "transparent"}} >
  <div className="dropdown-trigger">
   <Button onClick={toggleDropDown}  style={classes.box} aria-haspopup="true" aria-controls="dropdown-menu3">
    
             <div style={{display:'flex' ,flexDirection:"row"}}>
            <img  style={{margin:"0.9rem 0rem 0.9rem 1.9rem",height:"2.5rem",width:"2.5rem"}} src={"./icons/addicon.svg"} />
            <h4 style={classes.addTxt}>{buttonTxt}</h4>
            <img src="./icons/dropdownarrow.svg"/>
            </div>
        
      </Button>
  </div>
  {dealerId===null?
  <div className="dropdown-menu" id="dropdown-menu3" role="menu">
    <div className="dropdown-content" style={{marginLeft:"100px",caretColor: "transparent"}}>
     {dropDownItems.map((item,index)=>{
         return (
          <Link style={{fontSize:14}} key={index} to={item.link} className="dropdown-item">
               {item.text}
          </Link>
         )
     })
     }
    </div>
  </div>
  :
  <div  className={`modal ${show?"is-active":""}`}>
      <div className="modal-background"></div>
      <div style={{width:1183}} className="modal-card">
         
        <header style={classes.dialogHeader} className="modal-card-head">
        <input type={'checkbox'} checked={isChecked} onChange={addAllProducts} onClick={  ()=>  setSelectAll(!selectAll) }/>
          <h1 style={{...classes.dialogHeaderTxt,marginLeft:15,width: 43}}>Image</h1>
          <h1 style={{...classes.dialogHeaderTxt,marginLeft:80,width: 130}}>Product Name</h1>
          <h1 style={{...classes.dialogHeaderTxt,marginLeft:240,width: 41}}>M.R.P</h1>
          <h1 style={{...classes.dialogHeaderTxt,marginLeft:130,width: 166}}>Suggested Selling Price</h1>
          <button onClick={()=>setShow(false)} className="delete" aria-label="close" style={{cursor:'pointer',  left:200}}></button>
        </header>
        
        
        <section style={{padding:0}} className="modal-card-body">
        {/* <SEARCHBOX placeholder='Search Products for Retailer'/>    */}
        
        
        {productsList.length===0?null
                :
                <InfiniteScroll
                    dataLength={productsList.length} //This is important field to render the next data
                    next={fetchData}
                    hasMore={productsList.length !== data?.totalProducts}
                    //loader={<TailSpin color ='#FF5733' height= {100}width= {110}/>}
                    loader = "Loading..."
                    height={500}
                    style={{ width: "1183px" }}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                >
                    {
                        productsList.map((product) => {
                            return (
                                <PRODUCTCARD
                                    deleteProduct={null}
                                    key={product.id}
                                    selectAll={selectAll}
                                    productId={product.id}
                                    product={product}
                                    editButton={false}
                                    deleteIcon={false}
                                    checkBox={true} 
                                    addSelectedItem={addSelectedItem}
                                    removeUnselectedItem={removeUnselectedItem}
                                    setSelectAll={setSelectAll}/>
                                    
                            )
                        })
                    }
                </InfiniteScroll>
             }
          
          
        </section>
        <footer className="modal-card-foot">
          <div style={{justifyContent: "center"}}>
          <button style={{...classes.dialogbtn}} className="button" onClick={handleAdd}>Add Selected</button>
          <button  style={{...classes.dialogbtn,background: "#DD242E"}} onClick={()=>setShow(false)}className="button">Cancel</button>
          </div>
        </footer>
        {addProduct.length>0
            ? <Dialog message={addProduct}  
            title="Add Products"
                  yesBtn='Yes'
                  noBtn='No'
                  onConfirm={handleSubmit}
                  onReject={addProductNo}   
                  visible={true}
                  setVisible={()=>setAddProduct("")}
              />
            : null}
      </div>
    </div>}
</div>
    )

}

export default ADDPRODANDDEALER
