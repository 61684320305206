import React from 'react'
import {MapContainer,Marker,Popup,TileLayer} from 'react-leaflet'
import Map from './map-provider'
import {useRef} from 'react'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet'

// type propType={
//     dealers:{
//         name:string
//         geoLat:number
//         geoLong:number
//     }
// }


type style={
    outerBox:React.CSSProperties
    titleBox:React.CSSProperties
    title:React.CSSProperties
    popUpTxt:React.CSSProperties
}

export default function MAP({dealers}:any) {
    const isMobile = window.innerWidth <= 769; 
    const classes:style={
        outerBox:{
                width:"100%",
                height: "50rem",
                background: "#FFFFFF",
                border: "1px solid #DFDFDF",
                boxSizing: "border-box",
                borderRadius: "5px",
                marginTop:isMobile?20:119,
                        },
        titleBox:{
                            
                height: "61px",
                width:"100%",
                left: "218px",
                top: "508px",
                background: "#F7F7F7",
                border: "1px solid #DFDFDF",
                boxSizing: "border-box",
                borderRadius: "5px"
             },
        title:{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "1.8rem",
            lineHeight: "21px",
            letterSpacing: "0.06em",
            color: "#797979",
            marginTop:"2.1rem",
            marginLeft:"2.7rem",
            
        },
popUpTxt:{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: 12,
            letterSpacing: "0.06em",
            marginLeft:10,
            color: "#424242"
        }
    }

    const [center, setCenter] = React.useState({ lat: 12.9716, lng: 77.5946 });
    const ZOOM_LEVEL = 10;
    const mapRef=useRef()
    const markerIcon=new L.Icon({
       iconUrl:require("./markericon.png"),
       iconSize:[25,25]
    })
    
    return (
        <div  className='mapBox' style={classes.outerBox}>
            <div className='mapTitleBox' style={classes.titleBox}>
             <h2 style={classes.title}>Map View</h2>
           </div>
        <div>  
        <MapContainer
        style={{zIndex:'0'}}
        center={center}
        zoom={ZOOM_LEVEL} refs={mapRef} >
        <TileLayer url={Map.maptiler.url}  />
        {dealers?.map((dealer:{name:string,geoLat:number,geoLong:number},index:number)=>{
            return (
                dealer.geoLat===0 && dealer.geoLong===0?null:
                <Marker key={index} position={[dealer.geoLat,dealer.geoLong]} icon={markerIcon} >
                    <Popup>
                        <div style={{display:"flex"}}>
                        <img height={33} width={33} style={{borderRadius: "50%"}}src={'./icons/delaerimg.svg'}/>
                        <h1 style={classes.popUpTxt}>{dealer.name}</h1>
                        </div>
                    </Popup>
                </Marker>
        
            )
})
}
       </MapContainer>
       </div>
        </div>
    )
}
