import React from 'react';
import { useLocation } from 'react-router-dom';
import DEALERINFOFORM from '../../../forms/dealer-info-form';
import HEADER from '../../header'
import FOOTER from '../../footer';

function ManageDealer() {
    const location=useLocation()
  return (
  <div>
    <div style={{padding:"0 3rem"}}>
    <HEADER breadCrumb='' title={"Retailer"}/>
    <div className='container'>
    <DEALERINFOFORM dealerId={location.state} />
    </div>
    </div>
    <div>
       <FOOTER/>
    </div>
  </div>  
  )
}

export default ManageDealer;
