import { addSyntheticTrailingComment } from "typescript";


const initialState = {
    showMenu:false,
    isLoggedIn:false,
    supplierId:""
}

const reducer = (state=initialState, action:any) => {
    switch (action.type) {
        case "hideMenu": return{...state,showMenu:false}

        case "showMenu": return {...state,showMenu:true}

        case "setLoginStatus": debugger; return {...state,isLoggedIn:action.isLoggedIn}

        case 'setSupplierId': debugger; return {...state,supplierId:action.supplierId}
        default:
          return state
    }
}

export default reducer;
