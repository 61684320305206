import React,{ useRef, useEffect }  from 'react'
//import ADDDEALERSBUTTON from '../../utils/add-Dealer-Button'
import ADDDEALERSBUTTON from '../../../src/utils/add-Dealer-Button'
import {NavLink, useNavigate} from 'react-router-dom'
import { BrowserRouter as Router, Routes, Route,Outlet } from 'react-router-dom';
import { useSelector ,RootStateOrAny, useDispatch} from 'react-redux';
import {dashboard} from '../../lib/nearshop-api'
import { useQuery} from 'react-query'



type style = {
    dashboardPanel: React.CSSProperties
    footLogo: React.CSSProperties
    adminTxt: React.CSSProperties
    dashboardPanelTxt: React.CSSProperties
    dealersName: React.CSSProperties
}



const classes: style = {
    dashboardPanel: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", width: 174,height:"100vh",position:"fixed"
    },
    footLogo: {
        marginLeft: 13, marginTop: 20
    },
    adminTxt: {
        fontFamily: "Roboto", fontStyle: "normal", fontWeight: "500", fontSize: "14px", lineHeight: "16px",
        letterSpacing: "0.06em", marginTop: 30, paddingLeft: 10,color: "#000000"
    },
    dashboardPanelTxt: {
        fontFamily: "Roboto", fontStyle: "normal", fontWeight: "normal", fontSize: "12px", lineHeight: "14px",
        letterSpacing: "0.06em", color: "#000000", marginTop: 6 , 
    }, dealersName: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "300",
        fontSize: "10px",
        lineHeight: "12px",
        letterSpacing: "0.06em",
        color: "#000000",
        marginLeft: 9,marginTop:8,
        width: "112px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
    },
}



function MENU() {

    const items = [
        {
            text: "Dashboard",
            icon: "dashboardicon",
            selected: true,
            route: "/",
            style: classes.dashboardPanelTxt
        },
        {
            text: "Products",
            icon: "producticon",
            route: "Products",
            style: classes.dashboardPanelTxt
        },
        {
            text: "Retailers",
            icon: "dealersicon",
            route: "Retailers",
            style: classes.dashboardPanelTxt
        },
    ]
    const showMenu=useSelector((state:RootStateOrAny)=> state.reducer.showMenu)
    const supplierId= useSelector((state:RootStateOrAny)=>state?.reducer?.supplierId)
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const {isLoading,data,error}=useQuery(['DashboardContent',supplierId],dashboard)

    const ref= useRef() as React.MutableRefObject<HTMLInputElement>;

    // HIDE MENU WHEN CLICKED OUTSIDE
    useEffect(() => {
        const chechIfClickedOutside=(e:any)=>{
          if( showMenu && ref.current && !ref.current.contains(e.target))
          dispatch({type:"hideMenu"})
        }
        document.addEventListener('click',chechIfClickedOutside)
      
        return () => {
          document.removeEventListener('click',chechIfClickedOutside)
        }
      }, [showMenu]);
      
    return (
        <div className='Box'>
            <div className='sidemenu' >
        <aside ref={ref} style={classes.dashboardPanel} className={` ${showMenu?"isactive-menu":"not-active-menu"} `}>
            <div>
                <div style={{ display: "flex", flexDirection: "row", }}>
                <img height={36} width={36}  style={classes.footLogo} src={"./icons/footlogo.svg"} />
                <h2 style={classes.adminTxt}>Admin Panel</h2>
                <div className='crossIcon'>
                <img style={{marginBottom:20,marginLeft:2}} src={'./icons/cross.svg'} onClick={()=>{dispatch({type:"hideMenu"})}}/>
                </div>
                </div>
                <img style={{ margin: "6px 0px 16px 27px" }} src={"./icons/horizontalline.svg"} />
            </div>
            <ul className="menu-list">
            {items.map((items,index) => {
                return (
                    <li key={index} style={{marginBottom:42}}>
                    <NavLink style={({isActive})=>{
                      return{
                    borderLeft: isActive?"4px solid #DD242E":"4px solid white"
                    }
                    }} to={items.route}>
                        <div style={{ display: "flex", flexDirection: "row" }} >
                        <img height={18} width={18} style={{paddingLeft:4}} src={`./icons/${items.icon}.svg`} />
                        <h1 style={{ ...classes.dashboardPanelTxt, marginLeft: 9 }}>{items.text}</h1>
                        </div>
                    </NavLink></li>)
                })}
            </ul>
            <img style={{ margin: "0px 45px 16px 27px" }} src={"./icons/horizontalline.svg"} />
            <NavLink to='/Retailers/AddRetailer'>
            <ADDDEALERSBUTTON/>
            </NavLink>
            { isLoading?null:
            data?.supplier?.dealers.map((dealer:{id:string,images:string,name:string})=>{
                return (
                    
                    <div onClick={()=>{navigate("/Retailers/manage",{state:dealer.id})}} key ={dealer.id} style={{display:"flex",marginBottom:20,cursor:"pointer"}}>
                        <img onError={(event:any) => event.target.src = ''} style={{height:30,width:30,borderRadius:18,marginLeft:19}} src={dealer?.images[0]} />
                        <h1 style={classes.dealersName}>{dealer.name}</h1>
                    </div>
                )
            })}
        </aside>
        </div>
        <div  >
        <Outlet  />
        </div>
        </div>

    )
}

export default MENU
