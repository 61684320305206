import React from 'react'
import {ErrorMessage,useField} from 'formik'

function TEXTAREA({...props}:any) {
    const [field,meta]= useField(props)
    return (
        <div>
            <textarea className={`input ${meta.touched && meta.error  && "is-danger"}`} style={props.style} {...props} {...field}/>
            <ErrorMessage component='div' className='error' name={field.name}/>
        </div>
        
    )
}

export default TEXTAREA
