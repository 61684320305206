import React, { useEffect, useState,useMemo } from 'react'
import {useQuery} from 'react-query'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {Button} from 'react-bulma-components'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'
import ADDPRODANDDEALER from '../../addprodNdealer/addProdAndDealer'
import PRODUCTDETAILFORM from '../../forms/product-detail-form'
import SEARCHBOX from '../../searchbox/searchbox'
import HEADER from '../header' 
//import PRODUCTLIST from './product-list'
import DataTable from '../../utils/data-table'
import { product,deleteSupplierProduct, getDealerProductsV2 } from '../../../lib/nearshop-api'
import { useSelector , RootStateOrAny} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import OrderDialog from '../../dialogs/orderDialog'
import { supplierProductsSearch } from '../../../lib/search-api'
//import PRODUCTADDEDCARD from './productAddedCard';
import {dashboard} from '../../../lib/nearshop-api'
import {useTable, useBlockLayout} from 'react-table'
import ORDERLISTCARD from './orderListCard';
import environment from '../../../env.js'
import SuccessDialog from '../../dialogs/successDialog';
import FOOTER from '../footer';


var SERVER_BASE_URL = environment.SERVER_BASE_URL;

type style={ searchTxt:React.CSSProperties }
const ASSIGN_CONFIRM_TEXT = `Do you want to delete this Product?`

function ORDERDETAILSPAGE() {
  
    const classes:style={
        searchTxt:{
         fontFamily: "Roboto",
         fontStyle: "normal",
         fontWeight: "300",
         fontSize: "10px",
         lineHeight: "1.6rem",
         letterSpacing: "0.06em"
        }
    }
    const navigate = useNavigate();
    // const dropDownItems = [
    //     {
    //         text: "Add from Product Database",
    //         link:"/Products/addProduct/Categories"
    //     },
    //     {
    //         text: "Search product barcode",
    //         link:"/Products/addProduct/scanBarcode"
    //     },
    //     {
    //         text: "Add manually",
    //         link:"/Products/addProduct/manually"
      
    //     },
    //     {
    //         text:"Bulk upload of products",
    //         link:"/Products/addProduct/bulkUpload"
    //     }
    // ]
    const [orderDetails,setOrderDetails]= React.useState([])
    const location=useLocation()
    
    let orderInfo:any = location.state
    // console.log("Info",orderInfo)
    const orderId = orderInfo.details.id ;
    
    const fetchData = async () => {
      try {
        let results = await fetch(`${SERVER_BASE_URL}/v2/order?orderid=${orderId}`);
        const data = await results.json();
        setOrderDetails(data)
        // console.log("Data",data)
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    }

 useEffect(() => {
   fetchData()
  }, []);


const url = `${SERVER_BASE_URL}/v2/order/status/${orderId}`;
const [statusData , setStatusData]=React.useState([])
const [buttonStatus , setButtonStatus]=React.useState('');
const [buttonChange , setButtonChange]=React.useState(false);
const [dialogueShow , setDialogueShow]=React.useState(false);
const [selectedOption, setSelectedOption] = useState('');
const [successDialogue, setSuccessDialogue] = useState(false);
//onClick function for change status button
const handleClick=()=>{
  //enable dialogue box
  setDialogueShow(true)
}
//enable dialogue box
//set cancellation reason
//onClick function for yes button
//use post api
//check button text and accordingly pass the required status tobe passed in the api as well as cancellation reason 
const orderYes = () => {
  setButtonChange(true)
  handleDialogueYesButton()
}
const orderNo = () => {
  setDialogueShow(false)
}

const orderSuccessYes = () => {
  setDialogueShow(false);
  setSuccessDialogue(false)
}

const [isLoading, setIsLoading] = useState(false);
const handleDialogueYesButton = () => {
    const data = {
      id: orderId,
      status: "Merchant_Cancelled",
      remark: selectedOption,
     };
    if(selectedOption)
    {
    setIsLoading(true);  
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setIsLoading(false)
        setStatusData(data);
        setSuccessDialogue(true);
        
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        setIsLoading(false)
      });
      
    }
 } 
 const [orderRemarks,setOrderRemarks]= useState("")
 const [orderCancellationDate,setOrderCancellationDate]= useState("")
  useEffect(() => {
    //console.log("Status",orderDetails?.data?.status)
   if (orderDetails?.data?.status ==="Confirmed")
    {
        setButtonStatus("Packaging Done")
    }
    else if (orderDetails?.data?.status==="Packed")
    {
        setButtonStatus("Order Picked Up")
    } 
    else if (orderDetails?.data?.status==="Order_Picked_Up")
    {
        setButtonStatus("Out For Delivery")
    }
    else if (orderDetails?.data?.status==="Out_For_Delivery")
    {
        setButtonStatus("Delivered")
    }
    else if (orderDetails?.data?.status==="Customer_Cancelled")
    {
        setButtonStatus("Returned")//disable the button
    }
    else if (orderDetails?.data?.status==="Merchant_Cancelled")
    {
        setButtonStatus("Returned")//disable the button
    }
    else 
    {
        setButtonStatus("Packaging Done")
    }
      
      orderDetails?.data?.orderTrack?.map((item) =>
  item?.status?.includes("Cancelled") ? (
    (setOrderRemarks(item.remark), handleCancelledDate(item.createdAt.formatted))
  ) : null
  );
  }, [orderDetails]);
  // console.log("Remark",orderDetails?.data?.orderTrack)
  const handleCancelledDate = (date:any) => {
        const orderCancelledparts = date.split('T')[0].split('-');
        const orderCancelledyear =orderCancelledparts && orderCancelledparts[0];
        const orderCancelledmonth =orderCancelledparts && orderCancelledparts[1];
        const orderCancelledday = orderCancelledparts && orderCancelledparts[2];
        const orderCancelledformattedDate = `${orderCancelledday}/${orderCancelledmonth}/${orderCancelledyear}`;
        setOrderCancellationDate(orderCancelledformattedDate)
  }
  const parts = orderDetails?.data?.createdAt.formatted.split('T')[0].split('-');
    const year =parts && parts[0];
    const month =parts && parts[1];
    const day = parts && parts[2];
    const formattedDate = `${day}/${month}/${year}`; 
  const address =  orderDetails?.data?.deliveryAddress && JSON.parse( orderDetails?.data?.deliveryAddress)
  const formattedAddress = `${address?.addrLines?.filter(line => line !== '').join(', ')} - ${address?.city}, ${address?.state}, ${address?.country} - ${address?.pincode}`;
  // console.log("Addees",formattedAddress)
  console.log("DeliveryFee",orderDetails?.data?.status)
  const isMobile = window.innerWidth <= 769;
  return (
        <div style={{paddingLeft:"15px",paddingRight:'15px',width:'98%'}}>
            <HEADER title='Order Details'/>
            <div className="card is-fullwidth"  style={{padding:'2rem', marginBottom:'10px',backgroundColor:'white',display:'flex'}}>
                <div className='column is-1-mobile'>
                    <button className='button is-light'>
                        <span className="icon">
                        <Link to='/OrderList'>
                        <FontAwesomeIcon icon={faArrowLeft} style={{fontSize:"15px",color:'black'}} />
                        </Link>
                        </span>
                    </button>
                </div>
                <div className='column is-6-mobile'>
                <h1>Order Details</h1>
                </div>
            </div>
        
          <div className="columns" style={{display:'list-item'}}>
               <div className='column'>
                    <div className="card is-fullwidth"  style={{padding:'2rem', marginBottom: '10px',backgroundColor:'white'}}>
                        <div className='column'>
                        <h1>Order Status</h1>
                        </div>
                        <div className='column is-6-mobile'>
                        <h1>{orderDetails?.data?.status}</h1>
                        </div>
                    </div>
                </div>  
                <div className='column'>
                      <div className="card is-fullwidth"  style={{padding:'2rem', marginBottom: '10px',backgroundColor:'white'}}>
                        <div className='column'>
                            <h1>Order Details</h1>
                        </div>
                        <div className='column is-12-mobile'>
                           <h1>NAME : {orderDetails?.data?.customerName}</h1>
                        </div>
                        <div className='column is-12-mobile'>
                           <h1>ORDER ID : {orderDetails?.data?.id}</h1>
                        </div>
                        <div className='column is-12-mobile'>
                           <h1>ORDER DATE : {formattedDate}</h1>
                        </div>
                        <div className='column is-12-mobile'>
                           <h1>PRODUCT TOTAL : RS {orderDetails?.data?.sumTotal}</h1>
                        </div>
                        <div className='column is-12-mobile'>
                           <h1>DELIVERY FEE : Rs {orderDetails?.data?.shippingFee}</h1>
                        </div>
                     </div>
                </div> 
                <div className='column'>
                    <div className="card is-fullwidth"  style={{ padding:'2rem',marginBottom: '10px',backgroundColor:'white'}}>
                        <div className='column'>
                        <h1>Ordered Items</h1>
                        </div>
                                                  
                        {orderDetails?.data?.items.map((item)=>(
                           <div className='column is-12-mobile ' style={{display:"flex"}}> 
                            <img className={isMobile?'column is-2-mobile':'column is-3'} style={{marginRight:"12%",boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",width:'30px',height:'25px'}} src={item?.product?.imageUrl} alt={item?.product?.name} />
                            <h1 className='column is-6-mobile'>{item?.product?.name?item?.product?.name:"-"}</h1>
                            <h1 className='column is-2-mobile'>Qty : {item?.quantity?item?.quantity:'-'}</h1>
                            <h1 className='column is-2-mobile'>Rs {item?.subtotal?item?.subtotal:'-'}</h1>
                           </div>
                        ))}   
                              
                    </div>
                </div>
                <div className='column'>
                    <div className="card is-fullwidth"  style={{padding:'2rem', marginBottom: '10px',backgroundColor:'white'}}>
                        <div className='column'>
                        <h1>Delivery Address</h1>
                        </div>
                        <div className='column is-12-mobile' style={{display:"flex", marginBottom: '10px'}}>
                            <h1>{formattedAddress}</h1>
                        </div>
                        {orderDetails?.data?.status === 'Merchant_Cancelled' || orderDetails?.data?.status === 'Customer_Cancelled' &&
                         (
                                <div className='column is-12-mobile' style={{ marginBottom: '10px'}}>
                                  <h1>Reason for cancellation : {orderRemarks?orderRemarks:"\u00A0\u00A0\u00A0-"}</h1>
                                  <h1>Date of cancellation : {orderCancellationDate?orderCancellationDate:"\u00A0\u00A0\u00A0-"}</h1>
                                </div>
                            )} 
                        { orderDetails?.data?.status === 'Delivered' &&
                                  (<div></div>)
                        }
                        {(orderDetails?.data?.status === 'Confirmed' || orderDetails?.data?.status === 'Out_For_Delivery'|| orderDetails?.data?.status === 'Order_Picked_Up'|| orderDetails?.data?.status ==='Packed') && 
                            (<div className='columns' style={{ display: "flex", alignItems: 'center' }}>
                                    <div className='column is-6-mobile'>
                                        <Button fullwidth  style={{ backgroundColor: 'lightblue' }} onClick={() => setButtonChange(true)}>
                                            {buttonStatus}
                                        </Button>
                                    </div>
                                    <div className='column is-6-mobile'>
                                        <Button fullwidth style={{ backgroundColor: 'lightblue' }} onClick={() => handleClick()}>
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
                  
          </div>
          {dialogueShow
            ? <OrderDialog message={"Select the reason for Cancellation"} 
            title="Order Cancellation"
                  yesBtn='Yes'
                  noBtn='No'
                  onConfirm={orderYes}
                  onReject={orderNo}   
                  visible={true}
                  setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption}
                  setVisible={setDialogueShow}
                  isLoading={isLoading}
               />
            : null}
            {successDialogue
            ? <SuccessDialog message={"Your Order is cancelled successfully"} 
            title="Order Cancellation"
                  yesBtn='Okay'
                  onConfirm={orderSuccessYes}
                  visible={setSuccessDialogue}
                  
                              
              />
            : null}
          <FOOTER/>  
        </div>
        
    )
}
export default ORDERDETAILSPAGE

