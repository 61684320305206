import * as React from 'react';
import {Formik, Form} from 'formik' 
import INPUT from './forminput/input';
import * as Yup from 'yup'
import {mobileRegex} from '../../utils/regex'
import mailContent from '../utils/mail'
import { signUp } from '../../lib/nearshop-api';
import { toast } from 'bulma-toast'

type style={
  termsAndCond:React.CSSProperties
tAndCTxt: React.CSSProperties
button:React.CSSProperties
input:React.CSSProperties

}

const SIGNUPFORM = () => {
  const classes : style={
    termsAndCond:{
        color:"red",textDecoration:"none"
    },
    tAndCTxt:{
        fontFamily: "Roboto",fontStyle: "normal",fontWeight: "normal",fontSize: "1.2rem",
        letterSpacing: "0.06em",marginLeft:"2.8rem",marginBottom:"2.4rem",textAlign:'initial'
    },
    button:{
        width: "46.3rem",height: "5.0rem",background: "#DD242E",color:"#FFFFFF",border:"none",borderRadius: "0.5rem",
        cursor: "pointer"
    },
    input:{
      height: "4.5rem",width: "46.3rem",fontSize: "2.0rem",
      boxSizing: "border-box",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "1.8rem",
      letterSpacing: "0.06em",
      
      color: "#000000"
  }
  }

  const validate=Yup.object({
    mobile:Yup.string()
    .matches(mobileRegex,'Phone number is not valid')
    .required('Required'),
    email:Yup.string()
    .email("Enter valid email")
    .required('Required'),
    enterpriseName:Yup.string()
    .required('Required'),
    fullName:Yup.string()
    .required('Required')
  })
  return (
    <Formik 
      initialValues={{
        mobile:'',
        email:'',
        enterpriseName:"",
        fullName:"",
      }}
      onSubmit={(values,{resetForm})=>{
        const content=mailContent(values.mobile,values.email,values.enterpriseName,values.fullName)
        const payload={
          firstName: values.fullName, 
          email: values.email,
          mobile: values.email,
          subject: "Supplier SignUp Request" ,
          content: content
        }
        const getResult = async () => {
          const result = await signUp(payload)
          if(result.ok){
            resetForm({values:{mobile:'',
            email:'',
            enterpriseName:"",
            fullName:""}})
            toast({
              message: 'Signup Request Sent Sucessfully',
              type: 'is-success',
              position:"bottom-center",
              extraClasses:"tostmsg"
            })
          }
        }
        getResult()
      }}
      enableReinitialize
      validationSchema={validate}>
        {formik=>(
          <div style={{textAlign: "center"}}>
            <Form>
              <INPUT name="mobile" type="tel" placeholder="+91 - Enter Your Mobile No." value={"+91"}keyboardtype = 'numeric' style={classes.input} />
              <INPUT name="email" type="email" placeholder="Enter Your Email Address" style={classes.input}/>
              <INPUT name="enterpriseName" type="text" placeholder="Enter Your Enterprise Full Name" style={classes.input}/>
              <INPUT name="fullName" type="text" placeholder="Enter Your Full Name" style={classes.input}/> 
              <h1 style={classes.tAndCTxt}>By filling this form, I agree to <a href="#" style={classes.termsAndCond}> Terms of Use</a></h1>
            <button style={classes.button} type='submit'>Submit</button>
            </Form>
            
          </div>
        )}
    </Formik>
      
        
  )
   {/*  */}
        
      {/* <input style={classes.input} className="input" type="tel" placeholder="+91 - Enter Your Mobile No."/>
      <input style={classes.input} className="input" type="email" placeholder="Enter Your Email Address"/>
      <input style={classes.input} className="input" type="text" placeholder="Enter Your Enterprise Full Name"/>
      <input style={classes.input} className="input" type="text" placeholder="Enter Your Full Name"/> */}
      // 
      
    
  
};

export default SIGNUPFORM
