import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, useLocation, useNavigate,} from "react-router-dom"
import SIGNUPSCREEN from './signup-screen'
import VerifyToken from "./verifyToken"


const ProtectedRoutes =()=>{

  const isLoggedIn=useSelector(state=>state.reducer.isLoggedIn)
  const userAuth=()=>{
    const user={loggedIn:isLoggedIn}
    
    return user && user.loggedIn
  }

  console.log("verify token ... executing...")
    const location =useLocation()
    let token=""
    const isTokenUrl=location.pathname.includes("token")
    if(isTokenUrl){
       token= location.pathname.toString().replace("/token/","")
    }
    
    const isAuth=userAuth()
    const navigate=useNavigate()
    const dispatch=useDispatch()
    
  useEffect(()=>{
    dispatch({type:"hideMenu"})
  },[location])

  return  (
    <div>
    {isTokenUrl?<VerifyToken token={token}/>:null}
    {isAuth && !isTokenUrl?<Outlet/>:null}
    {!isAuth &&!isTokenUrl?<SIGNUPSCREEN/>:null}
    </div>

//<div>
//{isTokenUrl?<VerifyToken token={token}/>:null}
//{!isTokenUrl?<Outlet/>:null}
//{!isTokenUrl?<SIGNUPSCREEN/>:null}
//</div>
  )
}

export default ProtectedRoutes
