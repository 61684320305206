import React,{useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Dialog from '../../dialogs/dialog'
import FOOTER from '../footer'

const ASSIGN_CONFIRM_TEXT = `Do you want to delete this Product?`

type propType={
 editButton:boolean
 deleteIcon:boolean
 selectAll:boolean
 checkBox:boolean

 productId:string
 handleProductId:any
 deleteProduct:any
 product:{
     name:string
     imageUrl:string
     price:string
     onSalePrice:string   
 },
 addSelectedItem:any
 removeUnselectedItem:any
 setSelectAll:any
}

type style ={
    editButton:React.CSSProperties
    box:React.CSSProperties
    prdName:React.CSSProperties
    price:React.CSSProperties
}

export default function PRODUCTADDEDCARD(props:propType) {

   const {selectAll,productId,product,editButton,deleteIcon,checkBox,deleteProduct,addSelectedItem,removeUnselectedItem,setSelectAll}=props
    const classes:style={
        editButton:{
            width: "80px",
height: "31px",
border: "1px solid #DD242E",
boxSizing: "border-box",
borderRadius: "5px",marginTop:32,fontSize: "1.5rem"
        },
        box:{
            width: "1150px",height:124,
border: "1px solid #E7E7E7",display:"flex",flexDirection:"row"
        },
        prdName:{
            width: "390px",
height: "32px",marginLeft:"74px",marginTop:39,

fontFamily: "Roboto",
fontStyle: "normal",
fontWeight: "300",
fontSize: "1.4rem",
lineHeight: "1.6rem",
letterSpacing: "0.06em",
        },
        price:{
            fontFamily: "Roboto",
fontStyle: "normal",
fontWeight: "300",
fontSize: "1.4rem",
lineHeight: "1.6rem",
letterSpacing: "0.06em",
marginTop:39,width:"207px"
        }
    }
    const navigate=useNavigate()
    const [isChecked,setIsChecked]=React.useState(false)

    React.useEffect(()=>{
        setIsChecked(selectAll)
        

    },[selectAll])

    
    const deleteProductNo = () => {
        setDeleteProduct("")
      }
    
      const deleteProductYes = () => {
        deleteProduct(productId)
      }

      const [DeleteProduct, setDeleteProduct] = useState("")

    const handlecheckbox=(e:any)=>{
      
        setIsChecked( e.target.checked)
        
       if(e.target.checked===false){
           console.log("hereeeeeeeeeeeeeeeeeeeeeeeeee")
    
        }
     
        if(e.target.checked===true){

            addSelectedItem(
                
                productId,
                
            )
            // addSelectedItem(
            //     productId,
            // )
        }

        
        else{
            
            removeUnselectedItem(productId)
        }
    }
   const  handleDeleteButton=()=>{
        setDeleteProduct(ASSIGN_CONFIRM_TEXT)
        //deleteProduct(productId)
    }
    const isMobile = window.innerWidth <= 769;
    
    
    
    var imgSrc = product?.imageUrl
    if (imgSrc) imgSrc = imgSrc.replace('/nearshop_live/', '/nslive/')
    console .log("IMG",imgSrc)
    return (
      <div>
       <div className="card is-fullwidth" style={{ marginBottom: '10px',backgroundColor:'#dcdcdc'}}>
        <div className="card-content">
          <div className="columns" style={{display:'flex'}}>
            <div className={isMobile?"column is-3-mobile":"column is-3"} >
              {/* <figure className="image is-32x32"> */}
                <img style={{height:60,width:60}}  src={imgSrc} />
              {/* </figure> */}
            </div>
            <div className={isMobile?"column is-9-mobile":"column is-8"}>
              {/* <h1 className="title is-3">
                {product.name}
              </h1> */}
              <h1 className="title is-3">{product?.name}</h1>

              {/* <h1 className="subtitle is-4" style={{paddingTop:'3%'}}>Address: {decodeURIComponent(retailer.district)}</h1> */}
            <div className='columns' style={{display:'flex',marginTop:'3%'}}> 
              <div className='column' >
              <h1 >Product Price : {`₹${product?.price}`}</h1>
            </div>
              <div className='column'>
              <h1  >On Sale Price : {`₹${product?.onSalePrice}`}</h1>

              </div>
            </div>  
            </div>
          </div>
        </div>
        <FOOTER/>
      </div>
    </div>
    )
}
