import React from 'react'
import {ErrorMessage,useField} from 'formik'



function INPUT({...props}:any) {
    
    const [field,meta]= useField(props)
    return (
        <div style={{paddingBottom:"2.8rem"}}>
            <input className={`input ${meta.touched && meta.error  && "is-danger"}`} style={props.style}  {...props} {...field}/>
            <ErrorMessage component='div' className='error' name={field.name}/>
        </div>
    )
}

export default INPUT
