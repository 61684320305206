import { Table } from "react-bulma-components";

interface SummaryProps {
  status: string;
  duplicateCount?: number;
  failedCount?: number;
  successCount?: number;
  totalCount?: number;
  [x: string | number | symbol]: unknown;
  
}
type style = {
  box: React.CSSProperties
}



export default function ProductsUploadSummary(props:any) {
  

  const classes: style ={
    box:{
        width: "60.3rem",
        background: "#FFFFFF",
        border: "2px solid #E5E5E5",
        boxSizing: "border-box",
        borderRadius: 5,
        caretColor:"transparent",
        
        
    }
  };

  const summary:SummaryProps = props.summary
  
  return (
    <section className="section-1">
      <h1 className="title">Upload Summary :</h1>
      <div>
      
      <Table style={classes.box}  > 
        <tbody> 
          <tr> 
            <td> Status</td> <td>{summary.status}</td>
          </tr>
          <tr>
            <td> Total Records</td> <td>{summary.totalCount}</td>
          </tr>
          <tr>
            <td> Duplicates </td> <td>{summary.duplicateCount}</td>
          </tr>
          <tr>
            <td> Newly Added </td> <td>{summary.successCount}</td>
          </tr>
          <tr>
            <td> Failed  </td> <td>{summary.failedCount}</td>
          </tr> 
        </tbody>
      </Table>
      </div>
    </section>
  )

}
