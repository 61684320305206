import React from 'react'
import NEWORDERSCARD from '../dashboard/card'
import PENDINGORDERSCARD from "../dashboard/card"
import CANCELLEDORDERSCARD from "../dashboard/card"
import RETURNEDORDERSCARD from "../dashboard/card"
import COMPLETEDORDERSCARD from "../dashboard/card"
import DASHBOARDHEADER from '../header'
import {dashboard} from '../../../lib/nearshop-api'
import { useQuery} from 'react-query'
import { useLocation } from 'react-router-dom'
import { toast } from 'bulma-toast'
import{useSelector, RootStateOrAny} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'
import FOOTER from '../footer'
import { Button } from 'react-bulma-components'

function ORDERCARD() {
  const location=useLocation()
  let state:any=location.state
  const [filteredData,setFilteredData]=React.useState(state?.filteredData)
  const [filterShow, setFilterShow] = React.useState(state?.filterShow);
  const [seperatedData, setSeperatedData] = React.useState<any[]>([]);
  const [cancelledData,setCancelledData] = React.useState();
  const [pendingData,setPendingData] = React.useState();
  const [cancelledArray,setCancelledArray] = React.useState([]);
  const [returnedArray,setReturnedArray] = React.useState([]);
  const [deliveredArray,setDeliveredArray] = React.useState([]);
  const [confirmedArray,setConfirmedArray] = React.useState([]);
  const [pendingArray,setPendingArray] = React.useState([]);
  React.useEffect(() => {
   
    const zeroFilter =sessionStorage.getItem("ShowFilter")
    if(zeroFilter)
    {
       setFilterShow(zeroFilter)
    }
    const stringData = sessionStorage.getItem("DashboardFilteredData")  
    if (stringData) {
      try {
        const parsedData = JSON.parse(stringData);
        if (Array.isArray(parsedData)) {
          setFilteredData(parsedData);
        }
        else{
          setFilteredData(stringData)
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
    
  }, []);
  //console.log("OrderCard",filteredData)
    const classes={
        DistributorName:{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "800",
            fontSize: "15px",
            lineHeight: "29.3px",
            letterSpacing: "0.06em",width: "max-content",
        }
        }

      const supplierId= useSelector((state:RootStateOrAny)=>state.reducer.supplierId)
        

      const {isLoading,data,error}=useQuery(['DashboardContent',supplierId],dashboard)
      //console.log("Data",data)
      React.useEffect(() => {
        const groupOrdersByStatus = (orders) => {
          const groupedOrders = {};
      
          orders?.forEach((order) => {
            const status = order.status;
      
            if (!groupedOrders[status]) {
              // If the status key doesn't exist, create an array for it
              groupedOrders[status] = [];
            }
      
            // Push the order to the corresponding status array
            groupedOrders[status].push(order);
          });
      
          return groupedOrders;
        };
      
        if (filteredData && Object.keys(filteredData).length > 0) {
          // Check if filteredData is not an empty object
          const groupedData = groupOrdersByStatus(filteredData);
          setSeperatedData(groupedData);
         // console.log("selected", groupedData);
        } else {
          // Handle the case where filteredData is an empty object
          setSeperatedData({});
          console.log("filteredData is empty");
        }
      }, [filteredData]);
        console.log("seperated",seperatedData)
        console.log("Cancelled",seperatedData?.Merchant_Cancelled && !seperatedData?.Customer_Cancelled) 
      React.useEffect(() => {
        if(Object.keys(seperatedData).length > 0)
        {
            console.log("Inside if condition")
            if (seperatedData?.Confirmed){
              setConfirmedArray(seperatedData.Confirmed)
          
            } 

             if (seperatedData?.Returned){
              setReturnedArray(seperatedData.Returned)
        
            } 

            if (seperatedData?.Delivered){
              setDeliveredArray(seperatedData.Delivered)
              
            } 
           
            if (seperatedData.Merchant_Cancelled && seperatedData.Customer_Cancelled)
            {
              
              console.log(seperatedData?.Merchant_Cancelled.length+seperatedData?.Customer_Cancelled.length)
              setCancelledData(seperatedData.Merchant_Cancelled.length+seperatedData.Customer_Cancelled.length)
              const merchantCancelledArray = seperatedData?.Merchant_Cancelled
              const customerCancelledArray = seperatedData?.Customer_Cancelled
              setCancelledArray(merchantCancelledArray.concat(customerCancelledArray))
          

            }
            if (!seperatedData?.Merchant_Cancelled && seperatedData?.Customer_Cancelled)
            {
              console.log(seperatedData?.Customer_Cancelled.length)
              setCancelledData(seperatedData?.Customer_Cancelled.length)
              const customerCancelledArray = seperatedData?.Customer_Cancelled
              setCancelledArray(customerCancelledArray)
            
            }
            if (seperatedData?.Merchant_Cancelled && !seperatedData?.Customer_Cancelled){
              console.log("Inside if else")
              console.log(seperatedData?.Merchant_Cancelled)
              setCancelledData(seperatedData?.Merchant_Cancelled.length)
              const merchantCancelledArray = seperatedData?.Merchant_Cancelled
              setCancelledArray(merchantCancelledArray)
            
            }
            if (seperatedData.Confirmed && seperatedData.Out_For_Delivery && seperatedData.Order_Picked_Up)
            {
              setPendingData(seperatedData.Order_Picked_Up.length+seperatedData.Out_For_Delivery.length+seperatedData.Confirmed.length)
              const confirmedArray = seperatedData?.Confirmed;
              const outForDeliveryArray = seperatedData?.Out_For_Delivery;
              const orderPickedUpArray = seperatedData?.Order_Picked_Up;
              const combinedArray = confirmedArray.concat(outForDeliveryArray, orderPickedUpArray);
              setPendingArray(combinedArray)
            }
            if (!seperatedData.Confirmed && seperatedData.Out_For_Delivery && seperatedData.Order_Picked_Up)
            {
              setPendingData(seperatedData.Order_Picked_Up.length+seperatedData.Out_For_Delivery.length)
              const outForDeliveryArray = seperatedData?.Out_For_Delivery;
              const orderPickedUpArray = seperatedData?.Order_Picked_Up;
              const combinedArray = outForDeliveryArray.concat( orderPickedUpArray);
              setPendingArray(combinedArray)
            }
            if (seperatedData.Confirmed && !seperatedData.Out_For_Delivery && seperatedData.Order_Picked_Up)
            {
              setPendingData(seperatedData.Order_Picked_Up.length+seperatedData.Confirmed.length)
              const confirmedArray = seperatedData?.Confirmed;
              const orderPickedUpArray = seperatedData?.Order_Picked_Up;
              const combinedArray = confirmedArray.concat(orderPickedUpArray);
              setPendingArray(combinedArray)
            }
            if (!seperatedData.Confirmed && !seperatedData.Out_For_Delivery && seperatedData.Order_Picked_Up)
            {
              setPendingData(seperatedData.Order_Picked_Up.length)
              const orderPickedUpArray = seperatedData?.Order_Picked_Up;
              setPendingArray(orderPickedUpArray)
            }
            if (!seperatedData.Confirmed && seperatedData.Out_For_Delivery && !seperatedData.Order_Picked_Up)
            {
              setPendingData(seperatedData.Out_For_Delivery.length)
              const outForDeliveryArray = seperatedData?.Out_For_Delivery;
              setPendingArray(outForDeliveryArray)
            }
            if (seperatedData.Confirmed && !seperatedData.Out_For_Delivery && !seperatedData.Order_Picked_Up)
            {
              setPendingData(seperatedData.Confirmed.length)
              const confirmedArray = seperatedData?.Confirmed;
              setPendingArray(confirmedArray)
            }
            
        }
       //console.log(cancelledData)
      },[seperatedData])

      React.useEffect(() => {
          if(location.state==="login sucessfull"){
              toast({
                  message: location.state,
                  type: 'is-primary',
                  position:"bottom-center",
                  extraClasses:"tostmsg"
                })
          }
      }, [location])

      var imgSrc = data?.supplier?.images?.[0]
      if (imgSrc) 
      imgSrc = imgSrc.replace(/^/,'https://storage.googleapis.com/nearshop_live/')
      var imgSrc = data?.supplier?.images?.[0]
      if (imgSrc) 
      imgSrc = imgSrc.replace(/^/,'https://storage.googleapis.com/nearshop_live/')
      if (imgSrc) 
      imgSrc = imgSrc.replace('/nearshop_live/', '/nslive/')
     
      console.log("selected",cancelledArray,deliveredArray);
      const isMobile = window.innerWidth <= 769;
      return (
        
          isLoading?null:
          <div>
          <div style={{padding:"0px 3rem"}} >
            <DASHBOARDHEADER breadCrumb='' title={"Dashboard"}/>
            <div className='container'>
              <div className='columns' style={{ display: "flex" }}>
                <div className={isMobile?'column is-1-mobile':'column is-1'}>
                  <Button  onClick={()=>sessionStorage.setItem("Status"," ")}>
                    <span className="icon">
                      <Link to='/'>
                      <FontAwesomeIcon icon={faArrowLeft} style={{fontSize:"15px",color:'black'}} />
                      </Link>
                    </span>
                  </Button>
                </div>
                <img style={{ marginRight: '2%', boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)", borderRadius: "5px", boxSizing: "border-box", background: "#F6F6F6" }} height={50} width={50} src={imgSrc} />
                <h4 style={classes.DistributorName}>{data?.supplier?.name}</h4>
              </div>
              <div className='columns' style={{ display: "flex" }}>
                <div className='column is-3-widescreen'>
                  <NEWORDERSCARD content={"New Orders"} link={"/OrderList"} title={filterShow == "1"?(confirmedArray && Object.keys(confirmedArray).length > 0?(confirmedArray? confirmedArray.length:0):0):data?.orderStatusCounts?.newOrders} orderStatus={'Confirmed'} bgColor={"green"} filteredData={confirmedArray} filterShow={filterShow}/>
                </div>
                <div className='column'>
                  <PENDINGORDERSCARD content={"Pending Orders"} link={'/OrderList'} title={filterShow == "1"?( pendingArray && Object.keys(pendingArray).length > 0?(pendingData?pendingData:0):0):data?.orderStatusCounts?.pending} orderStatus={"Pending"} bgColor={"blue"} filteredData={pendingArray} filterShow={filterShow}/>
                </div>
              </div>
              <div className='columns' style={{ display: "flex" }}>
                <div className='column is-3-widescreen'>
                  <CANCELLEDORDERSCARD content={"Cancelled Orders"} link={"/OrderList"} title={filterShow == "1"?(cancelledArray && Object.keys(cancelledArray).length > 0?(cancelledData?cancelledData:0):0):data?.orderStatusCounts?.cancelled} orderStatus={"Cancelled"} bgColor={"yellow"}  filteredData={cancelledArray} filterShow={filterShow} />
                </div>
                <div className='column'>
                  <RETURNEDORDERSCARD content={"Returned Orders"} link={"/OrderList"} title={filterShow == "1"?(returnedArray && Object.keys(returnedArray).length > 0?(returnedArray?returnedArray.length:0):0):data?.orderStatusCounts?.returned} orderStatus={"Returned"} bgColor={"red"}  filteredData={returnedArray} filterShow={filterShow}/>
                </div>
               </div>
               <div className='columns' style={{ display: "flex"}}>
               <div className='column' is-3-widescreen>
                  <COMPLETEDORDERSCARD content={"Delivered Orders"} link={"/OrderList"} title={filterShow == "1"?(deliveredArray && Object.keys(deliveredArray).length > 0?(deliveredArray? deliveredArray.length:0):0):(data?.orderStatusCounts?.delivered)} orderStatus={"Delivered"} bgColor={"lightBlue"} filteredData={deliveredArray} filterShow={filterShow} />
                </div>
               </div>
            </div>
          </div>
          <div>
            <FOOTER/>
          </div>
        </div>  
      )
}

export default ORDERCARD
