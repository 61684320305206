import React from 'react'
import Dialog from '../components/dialogs/dialog'
import { useTable } from 'react-table'
import { sendInvitation ,associateSupplier} from '../lib/nearshop-api'
import { useSelector,RootStateOrAny } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import { toast } from 'bulma-toast'


const AddRetailerCases = ({ submitResponse, setVisible }: any) => {
  const supplierId= useSelector((state:RootStateOrAny)=>state.reducer.supplierId)
  let navigate = useNavigate()
  
  const sendInvite = () => {
    //debugger
  
    const result =sendInvitation(submitResponse.store.id,supplierId)
    navigate('/')
  }


  const associateRetailer =async () => {
    const result = await associateSupplier(submitResponse.store.id,supplierId)
    if (result.ok){
      console.log("========================Retailer Added and Associated to Supplier===================")
      toast({
          message: 'Retailer is Associated',
          type: 'is-primary',
          position:"bottom-center",
          extraClasses:"tostmsg",
          duration: 3000,
        })
      }
      const resultInvite =sendInvitation(submitResponse.store.id,supplierId)
    navigate('/')
  }

  

  const linked = submitResponse.store.linked
  const verified = submitResponse.store.verified
  //debugger
  return (<>
    {(verified === true && linked === true) ?

      <Dialog
        message={<RetailerInfo retailerInfo={submitResponse.store} errorMsg={"The retailer already exists in the system."} />}
        title='Error while adding retailer...'
        yesBtn='Ok'
        onConfirm={setVisible}
        visible={true}
        setVisible={setVisible}
      />
      : null
    }



    {(verified === true && linked === false) ?

      <Dialog
      message={
      <RetailerInfo retailerInfo={submitResponse.store} 
      errorMsg={"The retailer already exists in the system and is active .Do you want to associate ?"} />}
        title='Error while adding retailer...'
        yesBtn='Yes'
        noBtn='No'
        onConfirm={associateRetailer}
        onReject={setVisible}
        visible={true}
        setVisible={setVisible}
      />
      : null
    }

    {(verified === false && linked === true) ?
      <Dialog
        message={
        <RetailerInfo retailerInfo={submitResponse.store} 
        errorMsg={"The retailer already exists in the system and is Not active. Do you want to send invitation?"}/>}
        yesBtn='Yes'
        noBtn='No'
        onConfirm={sendInvite}
        onReject={setVisible}
        visible={true}
        setVisible={setVisible}
      />
      : null
    }


    {(verified === false && linked === false) ?
      <Dialog
        message={
          <RetailerInfo retailerInfo={submitResponse.store} 
          errorMsg={"The retailer already exists in the system But not linked with You. Do you want to associate?"}/>}
        yesBtn='Yes'
        noBtn='No'
        onConfirm={associateRetailer}
        onReject={setVisible}
        visible={true}
        setVisible={setVisible}
      />
      : null
    }
  </>

  )




}

export default AddRetailerCases

//  Shows a given retailer's data in a tabular form.
function RetailerInfo({ retailerInfo, errorMsg }: { retailerInfo: any, errorMsg: string }) {


  // iterate through the object's keys
  // and populate them in a table.
  const data = React.useMemo(() => {
    const keys = Object.keys(retailerInfo)
    let rows = keys.map(key => {
      return { field: key, value: retailerInfo[key] }
    })
    // filter out the createdAt field, as it is not a plain string, 
    return rows.filter(row => row.field !== 'createdAt')
  }, [])

  // Our table's columns
  const columns = React.useMemo(() => [ 
    {
      Header: 'Field',
      accessor: 'field'
    },
    {
      Header: 'Value',
      accessor: 'value'
    }
  ], [])

  return (
    <>
      <h4> {errorMsg} </h4>
      <Table columns={columns} data={data} />
    </>
  )
}


function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data, })

  //debugger;
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}> {cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>

  )
}
