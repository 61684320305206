import { useState } from "react";
import { useQuery } from "react-query";
import { Button } from "react-bulma-components";
import URLMap from "../lib/url-map";
import { toast } from "bulma-toast";
import { useSelector } from "react-redux";
import timedFetch from "../lib/fetch-with-timeout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { uploadTrack } from "../lib/nearshop-api";
import UploadSummary from "../components/dashboardComponent/product/addproducts/bulkupload/products-upload-summary";
import { Navigate, useNavigate } from "react-router-dom";

const TARGET_URL = URLMap("fileUploadAPI");

export default function FileUploader(props) {
  const isMobile = window.innerWidth <= 769;
  const [fileInfo, setFileInfo] = useState({
    selectedFile: undefined,
    uploadProgress: false,
  });

  const btnText = "Choose a file...";
  const fileText = fileInfo.selectedFile?.name || "No file chosen.";
  const navigateSummary = useNavigate();

  const onFileChange = (selectorFiles) => {
    setFileInfo({ ...fileInfo, selectedFile: selectorFiles[0] });
  };
  const supplierId = useSelector((state) => state.reducer.supplierId);

  const uploadFile = () => {
    let data = new FormData();
    // Add form fields - file, userId, supplier id
    data.append("file", fileInfo.selectedFile);
    data.append("userid", supplierId);
    data.append("id", supplierId);
    if (props?.type === "retailer") data.append("type", "RETAILER");
    // if (props.type === 'HOPCOMS')
    // data.append("type", "HOPCOMS_STORE_SYNC")
    if (props.type !== "retailer") data.append("type", props.type);
    timedFetch(TARGET_URL, {
      timeout: 5000,
      method: "POST",
      body: data,
    })
      .then(async (res) => {
        if (res.ok) {
          res = await res.json();
          showProgress(res);
        } else {
          toast({
            message: "Some failure while uploading",
            type: "is-warning",
            position: "bottom-center",
          });
        }
      })
      .catch((e) => {
        console.error("error", e);
        toast({
          message: "Server error. Check the server URL or try again later.",
          type: "is-warning",
          position: "bottom-center",
        });
      });
  };

  const showProgress = (res) => {
    setFileInfo({
      ...fileInfo,
      uploadProgress: true,
      jobId: res?.files?.[0]?.id,
    });
  };

  const { status, data, error } = useQuery(
    ["/api/progress", supplierId, fileInfo.jobId],
    uploadTrack,
    {
      enabled: fileInfo.uploadProgress, // Start executing query only when uploadProgress is true.
      refetchInterval: 1000, // milliseconds.
    }
  );
  if (status === "error") {
    setFileInfo({ ...fileInfo, uploadProgress: false, error: error.message });
  } else if (status === "success") {
    if (!fileInfo.success) {
      // check the progress. If completed, show the summary info.
      if (data.status === "COMPLETED") {
        setFileInfo({
          ...fileInfo,
          uploadProgress: false,
          success: true,
          summary: data,
        });
        //navigateSummary(`/Summary`,{...fileInfo})
      }
    }
  }

  const progressInfo =
    fileInfo.uploadProgress === true ? (
      <span className="icon is-small mr-3">
        <FontAwesomeIcon icon={solid("spinner")} spin />
      </span>
    ) : null;

  const uploadBtnText =
    fileInfo.uploadProgress === true
      ? "Upload complete. Waiting for reply..."
      : "Upload";

  return (
    <>
      <style>
        {`
          .file-cta, .file-name {
            font-size:1.5em !important;
          }
          .file{
            margin-left:-3% !important;
          }
         `}
      </style>
      <div className="file is-medium has-name my-6">
        <label className="file-label">
          <input
            className="file-input"
            type="file"
            accept=".xlsx, .xls"
            disabled={fileInfo.uploadProgress === true || fileInfo.success}
            name="datafile"
            onChange={(e) => onFileChange(e.target.files)}
          />
          <span className="file-cta">
            <span className="file-icon">
              <i className="fas fa-upload"></i>
            </span>
            <span className="file-label">{btnText}</span>
          </span>
          <span className="file-name">{fileText}</span>
        </label>
        <Button
          className={isMobile?'button is-large is-danger mx-2':"button is-medium is-danger mx-2"}
          onClick={uploadFile}
          disabled={props.type === "" || fileInfo?.selectedFile == undefined} //It is mandatory for the user to select file type in bulk upload
        >
          <span className="icon is-small mr-3">
            <FontAwesomeIcon icon={solid("arrow-up-from-bracket")} />
          </span>
          {uploadBtnText}
          {progressInfo}
        </Button>
      </div>

      {fileInfo.summary && fileInfo.success && (
        <UploadSummary summary={fileInfo.summary} />
      )}
    </>
  );
}
