import React, {useState} from 'react';
import AddProductForm from '../../../../forms/addproductfrom';
import HEADER from '../../../header';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'


function AddProductManually() {
  const classes= {
    heading: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.06em",
      textDecorationLine: "underline", marginTop: 32,
      color:"#000000"
  },           
    }
return (
  <div style={{ padding: "0 4rem " }}>
    <HEADER breadCrumb='>Add Manually' title={"Products"} />
    <div className='container'>
    <button className='button ' style={{marginRight:'2%',marginTop:'2%'}}>
                                <span className="icon">
                                    <Link to='/Products'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{fontSize:"15px",color:'black'}}/>
                                    </Link>
                                </span>
      </button>
      <h1 style={classes.heading}>Please enter product details:</h1>
      <hr></hr>
      <AddProductForm  /> 
    </div>
  </div>
)
}

export default AddProductManually;
