import React from 'react';
import { useDispatch } from 'react-redux';

export default function FOOTER() {
    
    return (
        <div style={{ position: 'fixed', bottom: "1px", width: '100%', background: 'white' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <div >
                    <img style={{ width: '100%', maxWidth: '100px', height: 'auto', paddingRight: 12 }} src={'./images/Integra_%20logo.png'} alt="Menu Icon" />
                </div>
            </div>
        </div>
    );
}
