import React, { useEffect } from 'react'
import {  Navigate, useNavigate } from 'react-router-dom'
//import { TailSpin } from  'react-loader-spinner'
import { css} from '@emotion/react'
import ClipLoader from 'react-spinners/ClipLoader'
import { useDispatch } from 'react-redux'
import jwt_decode from "jwt-decode";


const override = css`
  display:block;
  margin: 0 auto'
`
function VerifyToken({token}) {
     
    const dispatch=useDispatch()
    const navigate=useNavigate()
    
  useEffect(()=>{
    setTimeout(() => {
      console.log("Verifying token:", token, "...")
      var decoded = jwt_decode(token)
      if(decoded){
        //dispatch({type:"setSupplierId", supplierId:"cc9cfc30-5c01-11ec-bf63-0242ac130002"})
        dispatch({type:"setSupplierId", supplierId:decoded.id})
        dispatch({type:"setLoginStatus",isLoggedIn:true})
        console.log("Successfully decoded token:", decoded)
        navigate('/',{state:"login sucessfull"})
      } else {
      console.error("Error while decoding token..")
      }
    },3000)
  },[])
  
      
  return (
    <>
    <div className='container is-flex is-justify-content-center mt-6'>
      <ClipLoader color='green' css={override} loading='true' size={150} style={{margin:'0 auto'}}/>
    </div>
    <h1 className="has-text-centered is-size-3"> Verifying token. Please wait... </h1>
    </>
    
  )
}

export default VerifyToken
