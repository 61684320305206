import * as React from 'react';
import { BrowserRouter as Router, Routes, Route,Outlet } from 'react-router-dom';
import DASHBOARD from '../components/dashboardComponent/dashboard/dasboard'
import MENU from '../components/menu/menu';
import PRODUCTS from '../components/dashboardComponent/product/products';
import PRODUCTSADDED from '../components/dashboardComponent/product/productsAdded'
import DEALERS from '../components/dashboardComponent/dealer/dealers';
//import ADDDEALER from '../components/dashboardComponent/adddealer/add-retailer';
import AddRetailer from '../components/dashboardComponent/adddealer/add-retailer';
import Searchbarcode from '../components/dashboardComponent/product/addproducts/scanbarcode/searchbarcode';
import EditProduct from '../components/dashboardComponent/product/editProduct/editProduct'
import ManageDealer from '../components/dashboardComponent/dealer/managedealer/manageDealer';
import Categories from '../components/dashboardComponent/product/addproducts/addproductfromDB/categories';
import SubCategories from '../components/dashboardComponent/product/addproducts/addproductfromDB/subcategories';
import BulkUploadProduct from '../components/dashboardComponent/product/addproducts/bulkupload/bulkuploadproduct';
import BulkUploaddealers from '../components/dashboardComponent/dealer/bulkupload/bulkuploaddealers';
import AddProductfromDB from '../components/dashboardComponent/product/addproducts/addproductfromDB/addProductfromDB';
import AddProductManually from '../components/dashboardComponent/product/addproducts/manually/addproductmanually';
import ProtectedRoutes from './protected-routes'
import PageNotFound from './page-not-found'
import ORDERCARD from '../components/dashboardComponent/order/ordercards';
import ORDERLISTPAGE from '../components/dashboardComponent/order/orderListPage';
import ORDERDETAILSPAGE from '../components/dashboardComponent/order/orderDetailsPage';

export default function DASHBOARDSCREEN (){

  


  return (
        
      <Router>
      {/* <MENU /> */}
      
        <Routes>
        
          <Route  element={<ProtectedRoutes/>}>
          <Route  element={<MENU  />}>
          <Route  path="/" element={<DASHBOARD />} />
          <Route  path="Product" element={<PRODUCTS />} /> 
          <Route  path="Products" element={<PRODUCTSADDED />}/>  
          <Route  path="OrdersCard" element={<ORDERCARD />}/> 
          <Route  path='OrderList' element={<ORDERLISTPAGE/>}/> 
          <Route  path='OrderList/details' element={<ORDERDETAILSPAGE/>}/>
          <Route  path='Products/search/:searchtoken' element={<PRODUCTS/>}/>       
          <Route  path='Products/retailer' element={<PRODUCTS />}/>
          <Route  path="Products/addProduct/scanBarcode" element={<Searchbarcode/>} />
          <Route  path="Products/addProduct/bulkUpload" element={<BulkUploadProduct/>} />
          <Route  path="Products/edit" element={<EditProduct/>} />
          <Route  path="Products/addProduct/Categories" element={<Categories/>} />
          <Route  path="Products/addProduct/Categories/:catId/subCategories" element={<SubCategories/>} />
          <Route  path="Products/addProduct/Categories/:catId/subCategories/:subCatId/products" element={<AddProductfromDB/>} />
          <Route  path="Products/addProduct/manually" element={<AddProductManually/>} />
          <Route  path="Retailers" element={<DEALERS />} />
          <Route  path="Retailers/search/:searchtoken" element={<DEALERS />} />
          <Route  path="Retailers/manage" element={<ManageDealer/>}/>
          <Route  path="Retailers/AddRetailer/bulkUpload" element={<BulkUploaddealers/>} />
          <Route  path="Retailers/AddRetailer" element={<AddRetailer />} />
          <Route  path='*' element={<PageNotFound/>}/>
          </Route>
          </Route>  
        </Routes>
        
        </Router>


    )
}
