import URLMap from './url-map'


//export async function supplierProductsSearch({queryKey}: {queryKey:string[]}) {
export async function supplierProductsSearch(opts:any) {

    //const token = queryKey[1]
    //const tokenitemsPerPage=queryKey[2]
    //const tokenpageNum=queryKey[3]
    //let supplierId = queryKey[4]

    const supplierId = opts.supplierId
    const token = opts.searchToken
    const pageSize = opts.pageSize || 10
    const pageIndex = opts.pageIndex || 1

    //let tempSupplierId="cc9cfc30-5c01-11ec-bf63-0242ac130002"

    let supplierProductsSearchURL=URLMap('supplierProductsSearch')
    supplierProductsSearchURL=supplierProductsSearchURL.replace('{supplierId}',supplierId)
    supplierProductsSearchURL=`${supplierProductsSearchURL}?name=${token}&itemsPerPage=${pageSize}&pageNum=${pageIndex}`
    console.log(supplierProductsSearchURL)
    let result = await fetch(supplierProductsSearchURL).then(res => res.json())
    return result;
}

export async function supplierDealersSearch({queryKey}: {queryKey:string[]}) {
    const tokenname= queryKey[1]
    const tokenitemsPerPage=queryKey[2]
    const tokenpageNum= queryKey[3]
    let supplierid=queryKey[4]


    //let tempSupplierId="cc9cfc30-5c01-11ec-bf63-0242ac130002"

    let supplierDealersSearchURL=URLMap('supplierDealersSearch')
    supplierDealersSearchURL=supplierDealersSearchURL.replace('{supplierId}',supplierid)
    supplierDealersSearchURL=`${supplierDealersSearchURL}?name=${tokenname}&itemsPerPage=${tokenitemsPerPage}&pageNum=${tokenpageNum}`
    console.log(supplierDealersSearchURL)
    let result = await fetch(supplierDealersSearchURL).then(res => res.json())
    return result;
}

export async function searchBarcode(token:any){
    let searchBarcodeURL=URLMap("searchBarcode")
    if(token.queryKey[1]!== null){
    searchBarcodeURL=searchBarcodeURL.replace("{barcode}",token.queryKey[1])
    }
    
    let result = await fetch(searchBarcodeURL).then(res => res.json())
    return result
}


