import SEEPRODUCTSCARD from '../dashboardComponent/dashboard/card'
import AddDealerForm from './adddealer-form'
import { useQuery } from 'react-query'
import { getDealerDetails,sendInvitation } from '../../lib/nearshop-api'
import { useNavigate } from 'react-router-dom'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import Map from '../dashboardComponent/dashboard/map-provider'
import React, { useRef, useState } from 'react'
import { deleteDealer } from '../../lib/nearshop-api'
import L from 'leaflet'
import { useSelector ,RootStateOrAny} from 'react-redux';
import { toast } from 'bulma-toast'
import Dialog from '../dialogs/dialog'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'
// type propType={
//     dealerId:string
// }

// type style={
//     heading:React.CSSProperties
//     button:React.CSSProperties
//     input:React.CSSProperties
//     inputName:React.CSSProperties
//     seperator:React.CSSProperties
//     formHeading:React.CSSProperties
//     mapHeading:React.CSSProperties
//     btn:React.CSSProperties
//     date:React.CSSProperties
// }

export default function DEALERINFOFORM({ dealerId }) {
    const isMobile = window.innerWidth <= 769;
    const classes = {
        heading: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "15px",
            lineHeight: "29px",
            letterSpacing: "0.06em",
        },
        input: {
            marginBottom: 26,
        },
        inputName: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "16px",
            letterSpacing: "0.06em"

        },
        button: {
            width: "135px",
            height: "30px",
            background: "#4D9D27",
            borderRadius: "5px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "14px",
            textAlign: "center",
            letterSpacing: "0.06em",
            color: "#FFFFFF",
            marginBottom: 6
        },
        seperator: {

            border: "0.5px solid #E7E7E7"
        },
        formHeading: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "16px",
            letterSpacing: "0.06em",
            textDecorationLine: "underline", marginTop: 18,
        },
        mapHeading: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "16px",
            letterSpacing: "0.06em",
            marginTop: 28
        },
        btn: {
            width:isMobile?158: 135,
            height: 30,
            background: "#4D9D27",
            borderRadius: 5,
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: 12,
            lineHeight: 14,
            textAlign: "center",
            letterSpacing: "0.06em",
            color: "#FFFFFF"
        },
        date: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "14px",
            lineHeight: "16px",
            letterSpacing: "0.06em",
            color: "#000000"
        },
        popUpTxt: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: 12,
            letterSpacing: "0.06em",
            marginLeft: 10,
            color: "#424242"
        }
    }


    const { isLoading, data, error } = useQuery(['dealerdetails', dealerId], getDealerDetails)


    const [DeleteRetailer, setDeleteRetailer] = useState("")

    const ASSIGN_CONFIRM_TEXT = `Do you want to delete this Retailer?`

    const deleteRetailerYes = () => {
        
        deleteDealer(dealerId,supplierId)
        navigate('/Retailers')
        window.location.reload()


    }

    const deleteRetailerNo = () => {
        setDeleteRetailer("")
        
        


    }





    const ZOOM_LEVEL = 9;
    const mapRef = useRef()
    const markerIcon = new L.Icon({
        iconUrl: require("../dashboardComponent/dashboard/markericon.png"),
        iconSize: [25, 25]
    })


    const navigate = useNavigate()
    const supplierId= useSelector((state=>state.reducer.supplierId))
    const handleSubmit = () => {

    }
    const handleDeleteClick = () => {
        setDeleteRetailer(ASSIGN_CONFIRM_TEXT)



    }



    const WebsiteUrl = () => {
        if(data?.verified === true){
            // navigate(<a href={data.url} target="_blank" rel="noopener noreferrer" />)
            window.location.href = data?.url;
        

        }
        else{
            toast({
                message: 'Retailer is Not Active',
                type: 'is-danger',
                position:"bottom-center",
                extraClasses:"tostmsg",
                duration: 3000,
              })
        }
    
        
    }
    const sendInvite =async ()=>{
        
        const result = await sendInvitation(dealerId,supplierId)
        if(result.ok){
            toast({
                message: 'Invite Sent Successfully',
                type: 'is-primary',
                position:"bottom-center",
                extraClasses:"tostmsg",
                duration: 3000,
              })
            }
        }
  

    
    
    const getISTTime=(dt)=>{
    let date = new Date(dt)
    if (dt === null ){
        return ""
    }
    else{
    return date.getDate().toString()+'-'+ date.getMonth().toString()+"-" +date.getFullYear().toString()
    }
}

    return (
        isLoading ? null :
            <div >
                <div style={{ width: "100%", paddingLeft:isMobile?5: 44, display: "flex", flexDirection: "row" }}>
                    <div className='columns ' style={{ flexGrow: 1 }}>
                        <div className='column is-7 ' style={{display:'flex'}}>
                            <button className='button ' style={{marginRight:'2%',marginTop:'2%'}}>
                                <span className="icon">
                                    <Link to='/Retailers'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{fontSize:"15px",color:'black'}}/>
                                    </Link>
                                </span>
                            </button>
                            <h1 style={classes.heading}>{data?.name}</h1>
                        </div>
                        <div className='columns' style={{ display:'flex',paddingTop:10 }}>
                            <div className='column' >
                                <button style={{ ...classes.button, background: "#DD242E", }} type={'submit'} onClick={sendInvite}className="button">Invite</button>
                            </div>
                            <div className='column'>   
                                <button style={{ ...classes.button, background: "#DD242E", marginRight: "1.0rem" }}
                                    className="button"
                                    // onClick={() => { navigate(`/products/dealer/`, {state:dealerId}) }}>
                                    onClick={() => { navigate(`/Products`, {state: {retailerId:dealerId, name:data?.name} })}}>
                                    Manage Products
                                </button>
                                {/* <a href={data.url} target="_blank" rel="noopener noreferrer" > */}
                                    {/* <button style={{ ...classes.button, marginRight: "1.0rem" }} type={'submit'} onClick={WebsiteUrl} className="button" >
                                        Go to Retailer website
                                    </button> */}
                                {/* </a> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div style={{ width: "100%", paddingLeft: 22 }}>
                                        <SEEPRODUCTSCARD buttonText={"See Products"}
                     link={`/Products/retailer`} DealerId={dealerId} name={data.name} content={"Products Assigned"} title={data.numProducts} />
                </div> */}
                <div className="card is-fullwidth">
                <hr style={classes.seperator} />
                <h1 style={classes.formHeading}>Retailer Details</h1>
                <div className='columns '>
                <div className='column'>
                                <AddDealerForm handleSubmit={handleSubmit}
                                    storeName={data?.storeName || ""}
                                    address={decodeURIComponent(data?.address) || ""}
                                    storePinCode={data?.storePinCode || ""}
                                    storeState={data?.storeState || ""}
                                    storeContactName={data?.storeContactName || ""}
                                    storeContactNumber={data?.storeContactNumber || ""}
                                    contactEmail={data?.contactEmail || ''}
                                    storeDistrict={data?.storeDistrict || ""}
                                    storeWebsite={data?.webUrl || ""}
                                    dealerId={ dealerId }
                                    disabled={true}
                                />
                 </div>
                </div>
                </div>
                    <div className='card is-fullwidth'>
                        <h1 style={classes.mapHeading}>Google Location</h1>
                        <MapContainer
                             
                            center={{
                                lat: isLoading ? 0 : data?.storeLocation.location.lat,
                                lng: isLoading ? 0 : data?.storeLocation.location.lng
                            }}
                            zoom={ZOOM_LEVEL} ref={mapRef} style={{ height: 224}} >
                            <TileLayer url={Map.maptiler.url} />


                            <Marker position={[data?.storeLocation.location.lat, data?.storeLocation.location.lng]} icon={markerIcon} >
                                <Popup>
                                    <div style={{ display: "flex" }}>
                                        <img height={33} width={33} style={{ borderRadius: "50%" }} src={'./icons/delaerimg.svg'} />
                                        <h1 style={classes.popUpTxt}>{data?.storeName}</h1>
                                    </div>
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </div>
                
                <div className='columns' style={{marginTop:'2rem'}}>
                    <h1 style={classes.date}>{`Added on ${getISTTime(data?.createdAt?.formatted)}`}</h1>
                    <div className='column' style={{ display: "flex", flexDirection: "row-reverse", flexGrow: 1 }}>
                        <button onClick={handleDeleteClick} style={{ ...classes.btn, background: "#DD242E", marginLeft: 10 }} className='button'>Delete Retailer</button>
                        <button form='add-dealer-form' style={classes.btn} type='submit' className='button'>Update</button>
                    </div>
                </div>
                <div>
                {DeleteRetailer.length>0
            ? <Dialog message={DeleteRetailer} 
            title="Delete Retailer"
                  yesBtn='Yes'
                  noBtn='No'
                  onConfirm={deleteRetailerYes}
                  onReject={deleteRetailerNo}   
                  visible={true}
                  setVisible={()=>setDeleteRetailer("")}
              />
            : null}
                </div>
            </div>
           
    )
}
