import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import HEADER from '../../../header'
//import FileUploader from '../../../../../src/utils/file-uploader'
//import FileUploader from '../../../../../../src/utils/file-uploader'
import FileUploader from '../../../../../utils/file-uploader'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'

type style = {
  heading: React.CSSProperties
  seperator: React.CSSProperties
  btn: React.CSSProperties
  para: React.CSSProperties
}

function BulkUploadProduct() {
  const isMobile = window.innerWidth <= 769;
  const classes: style = {
    heading: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "19px",
      letterSpacing: "0.06em",
      marginTop: "48px"
    },
    seperator: {
      background: "#C4C4C4",
      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
      padding: "2px"
    },
    btn: {
      width: 135,
      height: 30,
      background: "#DD242E",
      borderRadius: 5,
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: 14,
      textAlign: "center",
      letterSpacing: "0.06em",
      color: "#FFFFFF",
      marginTop: 37
    },
    para: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: isMobile?'18px':"20px",
      lineHeight: "23px",
      letterSpacing: "0.06em",
      marginTop:isMobile?'20px': "54px"
    }
  }

  const [fileType, setFileType] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setFileType(event.target.value);
  };

  return (
    <div style={{ padding: "0px 44px" }}>
      <HEADER breadCrumb=" > Bulk upload" title="Products" />
      <div className='container'>
      <button className='button ' style={{marginRight:'2%',marginTop:'2%'}}>
                                <span className="icon">
                                    <Link to='/Products'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{fontSize:"15px",color:'black'}}/>
                                    </Link>
                                </span>
       </button>
        <h1 style={classes.heading}>Add Products through bulk upload</h1>
        <hr></hr>
        <h1 style={classes.para}>Bulk upload requires the product data file in a specific format. The format is specified in the bulk upload sheet provided
          <a>   </a>
          <a href={"./excelSheet/products-upload-sample.xlsx"} download={"products-upload-sample.xlsx"} style={{ textDecoration: "underline", color: "blue" }}>here</a>
        </h1>
        <h1 style={classes.para}>Fill up the product data file and save it for uploading.</h1>
        <h1 style={classes.para}>Enter the File type </h1>
        <div className="my-6">
          <FormControl required sx={{ m: 1, minWidth: 150 }} size="small">
            <InputLabel style={{ fontSize:isMobile?'18px':'13px' }}>File Type</InputLabel>
            <Select
              value={fileType}
              label="File Type *"
              onChange={handleChange}
              style={{ fontSize: '13px' }}
            >
              <MenuItem value={'HOPCOMS_STORE_SYNC'} style={{ fontSize: '13px' }}>Products stock sheet</MenuItem>
              <MenuItem value={'HOPCOMS_SUPPLIER_PRODUCTS'} style={{ fontSize: '13px' }}>Products sheet</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <h1 style={classes.para}>Upload:</h1>
          <FileUploader type={fileType} />
        </div>
        <a href={`/products`} style={{ textDecoration: "underline", color: "blue", font: "roboto", fontSize:isMobile?'18px': "12px" }}>Click here to go to Product's page</a>

      </div>
    </div>
  )
}

export default BulkUploadProduct;
