import React,{useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Dialog from '../../dialogs/dialog';
import moment from 'moment';

const ASSIGN_CONFIRM_TEXT = `Do you want to delete this Product?`

type propType={
 editButton:boolean
 deleteIcon:boolean
 selectAll:boolean
 checkBox:boolean

 productId:string
 handleProductId:any
 deleteProduct:any
 product:{
     name:string
     imageUrl:string
     price:string
     onSalePrice:string   
 },
 addSelectedItem:any
 removeUnselectedItem:any
 setSelectAll:any
}

type style ={
    editButton:React.CSSProperties
    box:React.CSSProperties
    prdName:React.CSSProperties
    price:React.CSSProperties
}

export default function ORDERLISTCARD(props:propType) {
   console.log("List",props)
   const {selectAll,productId,product,editButton,deleteIcon,checkBox,deleteProduct,addSelectedItem,removeUnselectedItem,setSelectAll}=props
    const classes:style={
      editButton:{
            width: "80px",
            height: "31px",
            border: "1px solid #DD242E",
            boxSizing: "border-box",
            borderRadius: "5px",marginTop:32,fontSize: "1.5rem"
          },
      box:{
            width: "1150px",
            height:124,
            border: "1px solid #E7E7E7",
            display:"flex",
            flexDirection:"row"
          },
      prdName:{
             width: "390px",
             height: "32px",
             marginLeft:"74px",
             marginTop:39,
             fontFamily: "Roboto",
             fontStyle: "normal",
             fontWeight: "300",
             fontSize: "1.4rem",
             lineHeight: "1.6rem",
             letterSpacing: "0.06em",
          },
      price:{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "300",
            fontSize: "1.4rem",
            lineHeight: "1.6rem",
            letterSpacing: "0.06em",
            marginTop:39,width:"207px"
          }
       }
    const navigate=useNavigate()
    const [isChecked,setIsChecked]=React.useState(false)

    React.useEffect(()=>{
             setIsChecked(selectAll)
          },[selectAll])
  
    const deleteProductNo = () => {
             setDeleteProduct("")
          }
    
    const deleteProductYes = () => {
        deleteProduct(productId)
          }

    const [DeleteProduct, setDeleteProduct] = useState("")

    const handlecheckbox=(e:any)=>{
          setIsChecked( e.target.checked)
        
          if(e.target.checked===false){
              console.log("hereeeeeeeeeeeeeeeeeeeeeeeeee")
            }
          if(e.target.checked===true){
            addSelectedItem(
                 productId,
             )
          }
          else{
            removeUnselectedItem(productId)
              }   
         }

   const  handleDeleteButton=()=>{
           setDeleteProduct(ASSIGN_CONFIRM_TEXT)
         }

    const isMobile = window.innerWidth <= 769;
    var imgSrc = product?.imageUrl
    if (imgSrc) imgSrc = imgSrc.replace('/nearshop_live/', '/nslive/')
    //console .log("IMG",imgSrc)  
  
    const parts = props?.order?.createdAt?.formatted.split('T')[0].split('-');
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];
    const formattedDate = `${day}/${month}/${year}`; 
   return (
   <div>
    <div className="card is-fullwidth" style={{ marginBottom: '10px',backgroundColor:'#dcdcdc'}}>
      <div className="card-content">
        <div className="columns" style={{display:'flex'}}>
            <div className={isMobile?"column is-8-mobile":"column is-8"} >
                <h1  className="title is-3">Order Status</h1>  
                <h1  className="title is-3">{props?.order?.status}</h1> 
            </div>
            <div className={isMobile?"column is-4-mobile":"column is-8"}>
                  <h1  className="title is-3">Order Total</h1>
                  <h1  className="title is-3">{props?.order.sumTotal}</h1>
            </div>
         </div>   
        <div className='columns' style={{marginTop:'3%'}}> 
            <div className='column' >
               <h1  className="title is-4">Store Name : {props?.order.store.name} </h1>
           </div>
        </div> 
         <div className='columns' style={{display:'flex',marginTop:'3%'}}>
                <div className={isMobile?'column is-8-mobile':'column is-8'} >
                    <h1  className="title is-4">Placed On : {formattedDate} </h1>
               </div>
               <div className={isMobile?'column is-4-mobile':'column is-4'} >
                   <button onClick={()=> navigate('/OrderList/details',{state:{details:props.order} } )} style={{border:"1px blue solid"}}>View Details</button>
               </div>
          </div>
        </div>  
       </div>
   </div>    
    
    )
}
