import React from 'react';
import { useQuery } from 'react-query';
import SEARCHBOX from '../../../../searchbox/searchbox';
import HEADER from '../../../header'
import {getCategories} from '../../../../../lib/nearshop-api'
import { Link } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


type style={
  searchTxt:React.CSSProperties
  categoryBox:React.CSSProperties
  categoryName:React.CSSProperties
  breadCrumb:React.CSSProperties
  seperator:React.CSSProperties
}

function Categories() {
  const classes:style={
    searchTxt:{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "2rem",
              lineHeight: "1.9rem",
              letterSpacing: "0.06em",
              color: "#000000",marginTop: 12

    },
    categoryBox:{
      border: "1px solid #E0E0E0",
      boxSizing: "border-box",
      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)"
    },
    categoryName:{
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "1.8rem",
      lineHeight: "19px",
      letterSpacing: "0.06em",
      textAlign: "center",
      height:40,
      marginTop:20,
      color: "#000000"
    },
     breadCrumb:{
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: 16,
      lineHeight: "16px",
      letterSpacing: "0.06em",
     },
     seperator: {
      border: "1.5px solid #E7E7E7",
      width:"100%"
     }
  }
 
  const {isLoading,data}=useQuery(['categories'],getCategories)
  let array:any=[]
  array = isLoading?null:data.map((data:{noOfProducts:number}) => {
    //array.push(data.noOfProducts)
    return data.noOfProducts
  });
  const sum = isLoading?0:array.reduce((partialSum:number, a:number) => partialSum + a, 0);
   
  
  return (
    isLoading?null:
  <div style={{padding:"0px 4rem"}}>
      <HEADER breadCrumb='ㅤ> Add products from database' title={"Products"}/>
      <div className='container'>
      {/* <SEARCHBOX placeholder='Search products here'/> */}
      <button className='button ' style={{marginRight:'4%'}}>
                                <span className="icon">
                                    <Link to='/Products'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{fontSize:"15px",color:'black'}}/>
                                    </Link>
                                </span>
      </button>
      <h1 className='mobile-breadCrumb' style={{fontSize:"12px"}}>{"Productsㅤ> Add products from database"}</h1>
      {/* <h1 style={classes.searchTxt}>Search whole catalog</h1> */}
      <h1 style={{...classes.searchTxt,fontSize:"2rem",fontWeight:"normal",marginTop:14}}>
        {sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") } products available
      </h1>
      <hr style={classes.seperator}></hr>
      <div className='grid-container'>
       { data.map((data:{id:string,noOfProducts:number,name:string,url:string})=>{
         return (
           <Link key={data.id} to={`${data.id}/subCategories`}>
          <div key={data.id} style={classes.categoryBox}>
          <div style={{textAlignLast:"center"}}>
          <img style={{height:"16.0rem",width:"16.0rem"}} src={data.url}/>
          </div>
          <h1 style={classes.categoryName}>{data.name}</h1>
         </div>
         </Link>
         )
       })
      
      }
      </div>
      </div>
  </div>
  )
}

export default Categories;
