import * as React from "react";
import SIGNUPFORM from '../components/forms/signup-form'
import urlMap from '../lib/url-map'

type style={
  formBox: React.CSSProperties
  logo:React.CSSProperties,
  text:React.CSSProperties,
  catalogTxt:React.CSSProperties,
  shopTxt:React.CSSProperties,
  fromTitle:React.CSSProperties,
  loginTxt:React.CSSProperties,
  manageTxt:React.CSSProperties
}

function SIGNUPSCREEN() {
  const classes:style={
    formBox:{ width: "54.1rem",height: "50.6rem",left: "6.5rem",top: "16.5rem",border: "1px solid #E4E4E4",
    boxSizing: "border-box",boxShadow: "0.4rem 0.4rem 0.4rem 0.1rem rgba(0, 0, 0, 0.25)",borderRadius: "0.5rem",
    marginTop:"7.6rem",marginLeft:"6.5rem",marginBottom:"5.0rem"
    },
    logo:{
      marginTop:"2.8rem",marginLeft:"3.8rem"
    },
    text:{
      fontFamily: "Roboto",fontStyle: "normal",fontWeight: "normal",fontSize: "1.8rem",lineHeight: "3.6rem"
    },
  catalogTxt:{
    fontFamily: "Roboto",fontStyle: "normal",fontWeight: "normal",fontSize: "1.8rem",lineHeight: "2.9rem",
    width:"13.2rem",marginTop:"1.0rem"
  },
  shopTxt:{
    fontFamily: "Roboto",fontStyle: "normal",fontWeight: "normal",fontSize: "1.8rem",lineHeight: "2.9rem",
    width:"13.2rem",marginTop:"2.0rem"
  },
  fromTitle:{
    fontFamily:"Roboto",fontStyle: "normal",fontWeight: 500,fontSize: "2.5rem",lineHeight: "2.9rem",
    letterSpacing: "0.06em",textAlign:"center",marginBottom:"4.0rem",marginTop:"3.2rem"
  },
  loginTxt:{
    fontFamily: "Roboto",fontStyle: "normal",fontWeight: "normal",fontSize: "1.8rem",lineHeight: "2.1rem",
    letterSpacing: "0.06em",marginTop:"10.0rem"
  },
  manageTxt:{
  fontFamily: "Roboto",fontStyle: "normal",fontWeight: "500",fontSize: "2.5rem",
    lineHeight: "3.6rem",letterSpacing: "0.06em"
  }
} 

  const loginURL = urlMap('loginPageURL')
  return (
    <div className="container">
      <img style={classes.logo} src={"./icons/logo.svg"} />
      <div className="columns">
        <div className="column is-3"style={classes.formBox}>
          <h1 style={classes.fromTitle}>Request for Business Account</h1>
          <SIGNUPFORM/>
        </div >
      
        <div className="column" style={{ marginTop: "6.0rem",marginLeft:"6.5rem",}}>
          <h2 style={classes.manageTxt}>Manage your all Retailers on
            <a style={{ color: "red", textDecoration: "none" }} href="#"> Nearshop</a>, right from your desktop </h2>
          <h2 style={classes.text}>All you need to manage your Retailers on Nearshop is</h2>
          <div style={{ display: "flex", marginTop: "5.1rem" }} >
            <div style={{ marginTop: "3.0rem" }}>
              <img src={"./icons/catalog.svg"} />
              <h2 style={classes.catalogTxt}>Your Products Catalog</h2>
            </div>
            <img src={"./icons/lineicon.svg"} />
            <div style={{ marginLeft: "4.2rem", marginTop: "3.0rem" }}>
              <img src={"./icons/shopicon.svg"} />
              <h2 style={classes.shopTxt}>Your Retailers Details</h2>
            </div>
          </div>
          <h4 style={classes.loginTxt}>Already have a Business Account?
            <a style={{ color: "red", textDecoration: "none" }} 
                href={loginURL} >
                      Login Here
            </a>
          </h4>
        </div>
      </div>
    </div>
  );
}

export default SIGNUPSCREEN;
