
import React from 'react';
import SEEPRODUCTSCARD from './card'
import SEEDEALERS from "./card"
import ORDERSCARD from "./card"
import ORDERAMOUNTCARD from "./card"
import DASHBOARDHEADER from '../header'
import MAP from './map'
import ACTIVEDEALERS from './activedealers'
import {dashboard} from '../../../lib/nearshop-api'
import { useQuery} from 'react-query'
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'bulma-toast'
import{useSelector, RootStateOrAny} from 'react-redux'
import { Button, Dropdown, Icon,Modal,Content } from 'react-bulma-components'
import {useRef,useEffect,useState} from 'react';
import DropdownDialog from '../../dialogs/dropdownDialog'
import FOOTER from '../footer';
import environment from '../../../env.js'
import SuccessDialog from '../../dialogs/successDialog'
import { Typography } from '@mui/material';
var SERVER_BASE_URL = environment.SERVER_BASE_URL;
function DASHBOARD() {
    const isMobile = window.innerWidth <= 769;
    const classes={
        DistributorName:{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "800",
            fontSize: isMobile?"15px":'25px',
            lineHeight: "29.3px",
            letterSpacing: "0.06em",width: "max-content",
        },
        box:{
                width: "22.3rem",
                height: 43,
                background: "#FFFFFF",
                border: "1px solid #E5E5E5",
                boxSizing: "border-box",
                borderRadius: 5,
                marginLeft:isMobile?30:30
            },
         addTxt  :{
                height: "1.4rem",fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "1.5rem",
                letterSpacing: "0.06em",
                color: "#A7A7A7",padding:0,margin:0,marginTop:"1.2rem",width:"12.8rem",textAlign:"center"
                        },
        
            }   
          
       

        const supplierId= useSelector((state:RootStateOrAny)=>state.reducer.supplierId)
        

    const {isLoading,data,error}=useQuery(['DashboardContent',supplierId],dashboard)
   // console.log("dashboardData",data)
    const ref=useRef() 
    const [customDialog,setCustomDialog]=React.useState(false)
    const [isOpen, setIsOpen] = React.useState(false);
    //  const handleToggleDropdown = () => {
    //    setIsOpen(!isOpen);
    //  };

    const location=useLocation()
    React.useEffect(() => {
        if(location.state==="login sucessfull"){
            toast({
                message: location.state,
                type: 'is-primary',
                position:"bottom-center",
                extraClasses:"tostmsg"
              })
        }
    }, [location])

    var imgSrc = data?.supplier?.images?.[0]
    if (imgSrc) imgSrc = imgSrc.replace(/^/,'https://storage.googleapis.com/nearshop_live/')
    
    
    var imgSrc = data?.supplier?.images?.[0]
    if (imgSrc) imgSrc = imgSrc.replace(/^/,'https://storage.googleapis.com/nearshop_live/')
    if (imgSrc) imgSrc = imgSrc.replace('/nearshop_live/', '/nslive/')
    //console .log("IMG",imgSrc)

    const dialogSuccessYes = () => {
        setCustomDialog(false);
     }
   
     const dialogSuccessNo = () => {
      setCustomDialog(false);
      setDropdownVisible(true);
   } 

   const handleClose = () => {
    sessionStorage.clear() 
    window.location.reload();
    setFilterShow("")
  };
  const handleCloseDialogue = () => {
    sessionStorage.clear() 
    setFilterShow("")
  };

    const [filterShow, setFilterShow] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [filteredData, setFilteredData] = useState({});
    const [combinedOrders, setCombinedOrders] = useState({});
    const [orderSum , setOrderSum] = useState ()
    const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
    const orderTypes = ['Confirmed', 'Out_For_Delivery', 'Order_Picked_Up','Customer_Cancelled','Merchant_Cancelled','Returned','Delivered']; // Replace with your actual order types
    const fromDate = '2024-01-20'; // Replace with the desired from date
    const toDate = '2024-01-25'; // Replace with the desired to date
    useEffect(() => {
      const zeroFilter =sessionStorage.getItem("ShowFilter")
      //console.log("Zero",zeroFilter)
      if(zeroFilter)
      {
         setFilterShow(zeroFilter)
      }
      const stringData = sessionStorage.getItem("DashboardFilteredData")  
      if (stringData) {
        try {
          const parsedData = JSON.parse(stringData);
          if (Array.isArray(parsedData)) {
            setFilteredData(parsedData);
          }
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      }
      
    }, []);
    const handleDropdownItemClick =async (value:any) => {
      //console.log('Selected:', value);
      setIsOpen(false);
      setSelectedStartDate(null)
      setSelectedEndDate(null)
      setFilterShow("1")
      sessionStorage.setItem("ShowFilter","1")
      if(value==='Orders in 3 Days')
      {
        const today:any = new Date();
        const threeDaysAgo = new Date(today);
        const PreviousDate:any=threeDaysAgo.setDate(today.getDate() - 3);
        setSelectedStartDate(PreviousDate)
        setSelectedEndDate(today)
        try {
          const allData = await Promise.all(orderTypes.map(orderType => fetchData(orderType)));
          //console.log(allData)
          const combinedData = allData.flatMap(item => item.allOrders);
          setCombinedOrders(combinedData)
         // console.log('Combined data:', combinedData);
        } catch (error: any) {
          console.error('Error fetching data:', error.message);
          // Handle errors as needed
        }
      }
      else if(value==='Orders in 7 Days')
      {
        const today:any = new Date();
        const threeDaysAgo = new Date(today);
        const PreviousDate:any=threeDaysAgo.setDate(today.getDate() - 7);
        setSelectedStartDate(PreviousDate)
        setSelectedEndDate(today)
        try {
          const allData = await Promise.all(orderTypes.map(orderType => fetchData(orderType)));
          //console.log(allData)
          const combinedData = allData.flatMap(item => item.allOrders);
          setCombinedOrders(combinedData)
         // console.log('Combined data:', combinedData);
        } catch (error: any) {
          console.error('Error fetching data:', error.message);
          // Handle errors as needed
        }
      }
      else if(value==='Orders in One Month')
      {
        const today = new Date();
        let year = today.getFullYear();
        let month = today.getMonth() + 1; 
        
        // Subtract one month
        if (month === 1) {
          month = 12;
          year -= 1;
        } else {
            month -= 1;
        }
        
        const previousMonthLastDay = new Date(year, month, 0).getDate(); 
        const previousMonthDay = Math.min(today.getDate(), previousMonthLastDay);
        const oneMonthAgo = new Date(year, month - 1, previousMonthDay);
        setSelectedStartDate(oneMonthAgo)
        setSelectedEndDate(today)
        try {
          const allData = await Promise.all(orderTypes.map(orderType => fetchData(orderType)));
          //console.log(allData)
          const combinedData = allData.flatMap(item => item.allOrders);
          setCombinedOrders(combinedData)
          //console.log('Combined data:', combinedData);
        } catch (error: any) {
          console.error('Error fetching data:', error.message);
          // Handle errors as needed
        }
      }
      else if(value==='Custom Date Range')
      {
        try {
          const allData = await Promise.all(orderTypes.map(orderType => fetchData(orderType)));
          //console.log(allData)
          const combinedData = allData.flatMap(item => item.allOrders);
          setCombinedOrders(combinedData)
          //console.log('Combined data:', combinedData);
        } catch (error: any) {
          console.error('Error fetching data:', error.message);
          // Handle errors as needed
        }
        setCustomDialog(true)
        setDropdownVisible(false)
      }
    };
    
    const fetchData = async (orderType:any) => {
      try {
        // Make the API request using fetch
        const response = await fetch(`${SERVER_BASE_URL}/v2/supplier/${supplierId}/getOrders/${orderType}`);
        
        if (!response.ok) {
          throw new Error(`Failed to fetch data for order type ${orderType}`);
        }
        
        // Parse the response JSON
        const data = await response.json();

        //console.log(`Data for order type ${orderType}:`, data);
        return data;
        
        
      } catch (error:any) {
        console.error(error.message);
        return {};
      }
    };

    useEffect(() => {
      if (combinedOrders && Array.isArray(combinedOrders) && combinedOrders.length > 0 && selectedEndDate && selectedStartDate) {
        const startDate = selectedStartDate;
        const endDate = selectedEndDate;
    
        const filteredTransactions = combinedOrders.filter(item => {
          if (item.createdAt && item.createdAt.formatted) {
            const transactionDate = new Date(item.createdAt.formatted);
            return transactionDate >= startDate && transactionDate <= endDate;
          }
          return false; // or true, depending on your desired behavior for items without a valid date
        });
        //console.log(filteredTransactions);
        setFilteredData(filteredTransactions)
        sessionStorage.setItem("DashboardFilteredData",JSON.stringify(filteredTransactions))
       }
    }, [combinedOrders,selectedEndDate,selectedStartDate]);
   
    useEffect(() => {  
    if (filteredData && Array.isArray(filteredData) && filteredData.length > 0 ) {
      
        const calculateSumTotal = (dataArray:[]) => {
          return filteredData.reduce((sum, currentItem) => {
              return sum + currentItem.sumTotal;
          }, 0);
        };
      

      const totalSum = calculateSumTotal(data);
      setOrderSum(totalSum)
      //console.log("Sum of 'sumTotal' values:", totalSum);
    }
},[filteredData])  
    //console.log("Filtered",filteredData)   
    return (
 
     isLoading?null:
     <div>  
        <div  style={{padding:"0px 4rem"}} >
            <style>
      {`
        .modal-card-body {
          WebkitOverflowScrolling: 'touch';
          backgroundColor: 'white';
          flexGrow: 1;
          overflow: unset !important;
          flexShrink: 1;
          padding: 20px;
          width: 100%;
          height:300px;
        }
        .is-size-1{
          font-size: 3.5rem !important;
          font-weight:600 !important
        }
        .is-size-3{
          font-size: 3rem !important
        }
        .css-ahj2mt-MuiTypography-root{
          font-weight:600 !important 
        }
        .is-size-4 {
          font-size: 3.5rem !important;
        }
        .button {
          background-color: none !important;
          border-color:none !important
        }
        .is-size-2{
          font-size: 3.5rem !important;
          font-weight:700 !important
        }
        .is-size-5{
          font-size: 2.2rem !important
        }
        
      `}
    </style>
          <DASHBOARDHEADER breadCrumb='' title={"Dashboard"}/>
           <div className='container'>
               <div className='columns' style={{display:"flex"}}>
                <div className='column is-2-mobile'>   
                    <img  style={ {marginRight:'2%',marginLeft:"2%", boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",borderRadius: "5px",boxSizing: "border-box",background:"#F6F6F6"}}height={50} width={50} src={imgSrc}  />
                </div> 
                <div className='column is-6-mobile'>   
                    <h4 style={classes.DistributorName}>{data?.supplier?.name}</h4>
               </div>
              <div className='column is-5-mobile' style={{ marginTop: '5px' }}>
                 <div>
                  <button
                    className="button is-size-5"
                    onClick={() => setDropdownVisible(true)}
                  >
                    <span className={isMobile?'is-size-2':'is-size-3'} style={{ padding: '3px' }} >Filter</span>
                 </button>
                 <button className="button is-size-5" onClick={() => handleClose()}>
                          <span className="icon is-medium" >
                            <i className="fas fa-angle-down is-size-3" aria-hidden="true">
                              <img src="./icons/cross.svg" alt="Dropdown Arrow" />
                            </i>
                          </span>
                  </button>

                  <Modal show={dropdownVisible}
                    onClose={() => setDropdownVisible(false)}
                  >
                    <Modal.Card >
                      <Modal.Card.Header >
                        <Modal.Card.Title className='is-size-1'> Select filters </Modal.Card.Title >
                        <button className={isMobile?'button is-size-4':'button is-size-5'} onClick={() =>{setDropdownVisible(false);handleCloseDialogue()}}>
                          <span className="icon is-medium" >
                            <i className="fas fa-angle-down is-size-1" aria-hidden="true">
                              <img src="./icons/cross.svg" alt="Dropdown Arrow" />
                            </i>
                          </span>
                        </button>
                      </Modal.Card.Header>
                      <Modal.Card.Body className='is-size-2'>
                        <Content >
                          <Typography className={isMobile?'is-size-3':'is-size-5'} onClick={() =>(setDropdownVisible(false), handleDropdownItemClick('Orders in 3 Days'))}>Orders in 3 Days</Typography>
                          <Typography className={isMobile?'is-size-3':'is-size-5'} onClick={() =>(setDropdownVisible(false), handleDropdownItemClick('Orders in 7 Days'))}>Orders in 7 Days</Typography>
                          <Typography className={isMobile?'is-size-3':'is-size-5'} onClick={() =>(setDropdownVisible(false), handleDropdownItemClick('Orders in One Month'))}>Orders in One Month</Typography>
                          <Typography className={isMobile?'is-size-3':'is-size-5'} onClick={() => handleDropdownItemClick('Custom Date Range')}>Custom Date Range (orders between Startdate and Enddate)</Typography>
                        </Content>
                      </Modal.Card.Body>
                    </Modal.Card>
                  </Modal>
                </div>
              </div>
             </div>
             </div> 
           {isMobile ? ( 
              <>    
              <div className='columns' style={{display:"flex"}}>
                <div className='column'> 
                      <ORDERSCARD buttonText={"See Products"} content={filterShow === "1"?(Array.isArray(filteredData) && filteredData.length>0? "Filtered Orders":'Filtered Orders'):"Orders"} link={"/OrdersCard"}  title={filterShow === "1"?(Array.isArray(filteredData) && filteredData.length>0?filteredData.length:0): data?.totalNumberOfOrders} bgColor={"yellow"} filteredData={filteredData.length>0?filteredData:0} filterShow={filterShow}/>
                </div>
                <div className='column'>
                    <ORDERAMOUNTCARD buttonText={"See Products"} content={filterShow === "1"?(Array.isArray(filteredData) && filteredData.length>0?"Filtered Orders Amount":"Filterd Orders Amount"):"Orders Amount"} link={"/"}  title={filterShow === "1"?(Array.isArray(filteredData) && filteredData.length>0?orderSum:0):data?.totalSumTotal} bgColor={"red"} filteredData={filteredData.length>0?filteredData:0} filterShow={filterShow}/>
                </div>
              </div>
              <div className='columns' style={{display:"flex"}}>
                <div className='column'>
                    <SEEPRODUCTSCARD buttonText={"See Products"} content={"Products Added"} link={"/Products"}  title={data?.totalProducts} bgColor={"green"} filteredData={filteredData}/>
                </div>
                <div  className='column'>
                    <SEEDEALERS buttonText={"See Retailers"}  content={"Retailers Added"} link={'/Retailers'} title={data?.totalDealers} bgColor={"blue"} filteredData={filteredData}/>
                </div>
              </div>
              </>):
              (
                <div className='columns' style={{display:"flex"}}>
                <div className='column '> 
                    <ORDERSCARD buttonText={"See Products"} content={filterShow === "1"?(Array.isArray(filteredData) && filteredData.length>0? "Filtered Orders":'Filtered Orders'):"Orders"} link={"/OrdersCard"}  title={filterShow === "1"?(Array.isArray(filteredData) && filteredData.length>0?filteredData.length:0): data?.totalNumberOfOrders} bgColor={"yellow"} filteredData={filteredData.length>0?filteredData:0} filterShow={filterShow}/>
                 </div>
                <div className='column'>
                     <ORDERAMOUNTCARD buttonText={"See Products"} content={filterShow === "1"?(Array.isArray(filteredData) && filteredData.length>0?"Filtered Orders Amount":"Filterd Orders Amount"):"Orders Amount"} link={"/"}  title={filterShow === "1"?(Array.isArray(filteredData) && filteredData.length>0?orderSum:0):data?.totalSumTotal} bgColor={"red"} filteredData={filteredData.length>0?filteredData:0} filterShow={filterShow}/>
                </div>
              
                <div className='column'>
                    <SEEPRODUCTSCARD buttonText={"See Products"} content={"Products Added"} link={"/Products"}  title={data?.totalProducts} bgColor={"green"} filteredData={filteredData}/>
                </div>
                <div  className='column'>
                    <SEEDEALERS buttonText={"See Retailers"}  content={"Retailers Added"} link={'/Retailers'} title={data?.totalDealers} bgColor={"blue"} filteredData={filteredData}/>
                </div>
              </div> 
              )}
              <div  className='columns  '>
               {isMobile? (
                <>
                <div className='column is-12' style={{marginTop:"12px"}}>
                    <ACTIVEDEALERS totalDealers={data?.totalDealers} activeDealers={data?.totalActiveDealers}/> 
                </div>
                <div className='column is-12'>
                   <MAP dealers={data?.supplier.dealers}/>  
                </div>
                </>):
                (
                <>   
                <div className='column is-6'>
                    <MAP dealers={data?.supplier.dealers}/>  
                 </div>
                <div className='column is-6'>
                    <ACTIVEDEALERS totalDealers={data?.totalDealers} activeDealers={data?.totalActiveDealers}/> 
                </div>
                </>
                 )
               }
             </div>
         {customDialog &&    
         <DropdownDialog message={"Enter Start date and End Date"} 
            title="Custom Filter"
                  yesBtn='Okay'
                  noBtn='Cancel'
                  onConfirm={dialogSuccessYes}
                  onReject={dialogSuccessNo}
                  visible={setCustomDialog}
                  setSelectedStartDate={setSelectedStartDate}
                  setSelectedEndDate={setSelectedEndDate}
                  selectedStartDate={selectedStartDate}
                  selectedEndDate={selectedEndDate}
               />
         }  
         
         </div>
          <div >
                    <div style={{ position: 'fixed', bottom: "6px",paddingBottom:'5px', width: '100%', background: 'white' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <div >
                                <img style={{ width: '100%', maxWidth: '100px', height: 'auto', paddingRight: 12 }} src={'./images/Integra_%20logo.png'} alt="Menu Icon" />
                            </div>
                        </div>
                    </div>
              
          </div>
       </div>   
     )
}

export default DASHBOARD
