export default async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 8000 } = options;
  let aborted = false

  const controller = new AbortController();
  //return new Promise(async (resolve, reject) => {
    const id = setTimeout(() => {
      console.log("Fetch timed out.")
      controller.abort()
      aborted = true
      return Promise.reject({name: 'Timedout', code:501})
    }, timeout)
    
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal
    })
    clearTimeout(id)
    //debugger
    if (!aborted)
      return response;
  //})

}
